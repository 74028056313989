import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

import { ROUTE_TOURNAMENT_CREATE } from '../../app/types'

const TournamentListButtons = () => (
  <li key="create-tournament-button" className="ml-3">
    <Button color="primary" tag={Link} to={ROUTE_TOURNAMENT_CREATE}>
      Create tournament
    </Button>
  </li>
)

export default TournamentListButtons
