import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ProfileButton from '../components/ProfileButton'
import { LoadingSpinner } from '../../common'

const ProfileButtonContainer = ({
  loading,
  loaded,
  profile,
}) => {
  return (
    <>
      {loading && <LoadingSpinner />}
      {loaded && <ProfileButton profile={profile} />}
    </>
  )
}

ProfileButtonContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  ...ProfileButton.propTypes,
}

const mapStateToProps = ({
  user: {
    profile: { loading, loaded, data },
  },
}) => ({
  loading,
  loaded,
  profile: data,
})

export default connect(mapStateToProps)(ProfileButtonContainer)
