import { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { listToDo, listInProgress, listDone } from '../actions'

const LoadIssuesData = ({ listToDoConnect, listInProgressConnect, listDoneConnect }) => {
  useEffect(() => {
    listToDoConnect()
    listInProgressConnect()
    listDoneConnect()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return null
}

LoadIssuesData.propTypes = {
  listToDoConnect: PropTypes.func.isRequired,
  listInProgressConnect: PropTypes.func.isRequired,
  listDoneConnect: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  listToDoConnect: listToDo,
  listInProgressConnect: listInProgress,
  listDoneConnect: listDone,
}

export default connect(null, mapDispatchToProps)(LoadIssuesData)
