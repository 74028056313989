import React from 'react'
import { Label } from 'reactstrap'
import PropTypes from 'prop-types'

import Required from './Required'

const LabelWrapper = ({ children, required, ...rest }) => {
  return (
    <Label {...rest}>
      <span className="mr-1">{children}</span>
      {required && <Required />}
    </Label>
  )
}

LabelWrapper.propTypes = {
  children: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

LabelWrapper.defaultProps = {
  required: false,
}

export default LabelWrapper
