import { LOCK_READY, RESET_LOCK } from './types'

export const initialState = {
  ready: false,
}

const lockReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCK_READY:
      return {
        ...state,
        ready: true,
      }
    case RESET_LOCK:
      return initialState
    default:
      return state
  }
}

export default lockReducer
