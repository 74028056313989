import { failureAction, successAction, startAction } from '../common'
import { GET_TOURNAMENTS } from './types'

export const initialState = {
  loading: false,
  loaded: false,
  error: {},
  data: { pagination: { page: 1, last: 1, count: 0 }, docs: [] },
}

const reducer = (state = initialState, action) => {
  // do not reset pagination so that it won't be refreshed on UI too often
  const { data: { pagination } } = state
  switch (action.type) {
    case startAction(GET_TOURNAMENTS):
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
        data: { pagination, docs: [] },
      }
    case successAction(GET_TOURNAMENTS):
      return {
        loading: false,
        loaded: true,
        error: {},
        data: action.payload,
      }
    case failureAction(GET_TOURNAMENTS):
      return {
        ...initialState,
        error: action.payload,
      }

    default:
      return state
  }
}

export default reducer
