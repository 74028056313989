import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { getMatch } from '../../navbar'

const Wrapper = ({
  getMatchConnect, component: Component, match, isPublic,
}) => {
  useEffect(() => {
    // always dispatch match b/c it can be reset in case of 401
    // if restricts dispatch on url changes, it never gets dispatched
    getMatchConnect({ ...match, isPublic })
  })

  return <Component />
}

Wrapper.propTypes = {
  getMatchConnect: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  isPublic: PropTypes.bool.isRequired,
}

const mapDispatchToProps = {
  getMatchConnect: getMatch,
}

export default connect(
  null,
  mapDispatchToProps,
)(Wrapper)
