import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentListButtons from '../components/TournamentListButtons'

const TournamentListButtonsContainer = ({ loggedIn }) => {
  return loggedIn ? <TournamentListButtons /> : <></>
}

TournamentListButtonsContainer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
}

const mapStateToProps = ({
  auth: { loggedIn },
}) => ({ loggedIn })


export default connect(mapStateToProps)(TournamentListButtonsContainer)
