export const GET_TOURNAMENT_PLAYERS = 'GET_TOURNAMENT_PLAYERS'
export const GET_TOURNAMENT_PLAYER = 'GET_TOURNAMENT_PLAYER'
export const ADD_TOURNAMENT_PLAYER = 'ADD_TOURNAMENT_PLAYER'
export const UPDATE_TOURNAMENT_PLAYER = 'UPDATE_TOURNAMENT_PLAYER'
export const DELETE_TOURNAMENT_PLAYER = 'DELETE_TOURNAMENT_PLAYER'
export const CANCEL_ADD_TOURNAMENT_PLAYER = 'CANCEL_ADD_TOURNAMENT_PLAYER'
export const RESET_ADD_TOURNAMENT_PLAYER = 'RESET_ADD_TOURNAMENT_PLAYER'
export const RESET_DELETE_TOURNAMENT_PLAYER = 'RESET_DELETE_TOURNAMENT_PLAYER'
export const WITHDRAW_TOURNAMENT_PLAYER = 'WITHDRAW_TOURNAMENT_PLAYER'
export const REENTER_TOURNAMENT_PLAYER = 'REENTER_TOURNAMENT_PLAYER'
export const RESET_WITHDRAW_TOURNAMENT_PLAYER = 'RESET_WITHDRAW_TOURNAMENT_PLAYER'
