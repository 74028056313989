import React from 'react'
import PropTypes from 'prop-types'
import { BreadcrumbItem } from 'reactstrap'
import { Link, generatePath } from 'react-router-dom'

import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'

const TournamentPairingListBreadcrumbTitle = ({ active, tournament: { id: tournamentId, name: tournamentName } }) => {
  const links = active
    ? {}
    : { tag: Link, to: generatePath(ROUTE_TOURNAMENT_VIEW, { id: tournamentId, tabId: 'pairings' }) }
  return (
    <BreadcrumbItem active={active} {...links}>
      {tournamentName}
    </BreadcrumbItem>
  )
}

TournamentPairingListBreadcrumbTitle.propTypes = {
  active: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

export default TournamentPairingListBreadcrumbTitle
