import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import TournamentPlayerMenu, { getTournamentPlayer, getShowMenu } from '../components/TournamentPlayerMenu'
import { LoadingSpinner } from '../../common'
import TournamentPlayerDeleteModal from './TournamentPlayerDeleteModalContainer'
import DeleteConfirmContext from '../../tournament/contexts/DeleteConfirmContext'
import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'

const TournamentPlayerMenuContainer = ({
  history,
  loading,
  loaded,
  deleted,
  tournament,
  tournamentPlayer,
  user: { id: userId = null },
}) => {
  const { id: tournamentId } = tournament
  useEffect(() => {
    if (deleted && tournamentId) {
      history.push(generatePath(ROUTE_TOURNAMENT_VIEW, { id: tournamentId, tabId: 'players' }))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [deleted])

  const {
    id,
    firstName,
    lastName,
    paired,
    isLastRound,
    withdrawn,
    isMyTournament,
    isMyPlayer,
    tournamentCompleted,
  } = getTournamentPlayer({ tournament, tournamentPlayer, userId })

  const showMenu = getShowMenu({
    isMyTournament, isMyPlayer, withdrawn, tournamentCompleted,
  })

  const [isOpen, setIsOpen] = useState(false)
  const [playerToDelete, setPlayerToDelete] = useState({ firstName: '', lastName: '' })

  return (
    <>
      {loading && (<LoadingSpinner />)}
      {loaded && showMenu && (
        <div className="ml-3">
          <DeleteConfirmContext.Provider value={{
            setIsOpen, setPlayerToDelete, userId,
          }}
          >
            <TournamentPlayerMenu
              tournamentPlayer={{
                id,
                firstName,
                lastName,
                paired,
                isLastRound,
                withdrawn,
                isMyTournament,
              }}
              color="primary"
            />
            <TournamentPlayerDeleteModal
              tournamentPlayer={playerToDelete}
              isOpen={isOpen}
              toggle={() => setIsOpen(false)}
            />
          </DeleteConfirmContext.Provider>
        </div>
      )}
    </>
  )
}

TournamentPlayerMenuContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  deleted: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  tournamentPlayer: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = (state) => {
  const {
    tournament: {
      summary: {
        loading: loadingTournament,
        loaded: tournamentLoaded,
        data: tournament,
      },
    },
    tournamentPlayer: {
      details: {
        loading: loadingTournamentPlayer,
        loaded: tournamentPlayerloaded,
        data: tournamentPlayer,
      },
      delete: { deleted },
    },
    user: {
      details: {
        data: user,
      },
    },
  } = state

  return {
    loading: loadingTournamentPlayer || loadingTournament,
    loaded: tournamentLoaded && tournamentPlayerloaded,
    deleted,
    tournament,
    tournamentPlayer,
    user,
  }
}

export default connect(
  mapStateToProps,
)(withRouter(TournamentPlayerMenuContainer))
