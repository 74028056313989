import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const TournamentDescriptionEditButtons = ({
  toggle,
  submitting,
}) => {
  return (
    <div className="d-flex flex-row">
      <div>
        <Button
          type="submit"
          color="primary"
          disabled={submitting}
        >
          Save
        </Button>
      </div>
      <div className="ml-2">
        <Button
          color="secondary"
          onClick={() => toggle(false)}
          disabled={submitting}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

TournamentDescriptionEditButtons.propTypes = {
  submitting: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default TournamentDescriptionEditButtons
