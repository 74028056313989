import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import PropTypes from 'prop-types'

import { Info } from '../../common'

const ForfeitTooltip = ({ target }) => {
  return (
    <div className="flex-grow-1 pr-4">
      <Info className="text-info" />
      <UncontrolledTooltip placement="left" target={target}>
      Commonly a player won by forfeit because his opponent did not show up
      </UncontrolledTooltip>
    </div>
  )
}

ForfeitTooltip.propTypes = {
  target: PropTypes.string.isRequired,
}

export default ForfeitTooltip
