import React from 'react'
import {
  Container, CardDeck,
} from 'reactstrap'
import PropTypes from 'prop-types'

import When from './tournament-summary/When'
import AdditionalInfo from './tournament-summary/AdditionalInfo'
import TournamentDescription from '../containers/TournamentDescriptionContainer'

const TournamentSummary = ({ tournament }) => {
  return (
    <Container>
      <CardDeck className="my-2">
        <When tournament={tournament} />
        <AdditionalInfo tournament={tournament} />
      </CardDeck>
      <div className="mt-4">
        <TournamentDescription />
      </div>
    </Container>
  )
}

TournamentSummary.propTypes = {
  tournament: PropTypes.shape({
    startOn: PropTypes.string,
    endOn: PropTypes.string,
    numRounds: PropTypes.number,
    timeControl: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
}

export default TournamentSummary
