import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export const resultMap = {
  '+': { result: '1F - 0F', desc: 'White won by forfeit' },
  '-': { result: '0F - 1F', desc: 'Black won by forfeit' },
  W: { result: '1 - 0', desc: 'The scheduled game lasted less than one move' },
  D: { result: '\u00bd; - \u00bd', desc: 'The scheduled game lasted less than one move' },
  L: { result: '0 - 1', desc: 'The scheduled game lasted less than one move' },
  1: { result: '1 - 0', desc: 'Win' },
  '=': { result: '\u00bd - \u00bd', desc: 'Draw' },
  0: { result: '0 - 1', desc: 'Loss' },
  H: { result: 'H', desc: 'Half point bye' },
  F: { result: 'F', desc: 'Full point bye' },
  Z: { result: 'Z', desc: 'Zero point bye' },
  U: { result: 'U', desc: 'Pairing allocated bye' },
}

const Result = ({ result }) => {
  const { [result]: resultUi } = resultMap

  return (
    <div className={classNames('d-inline-flex')}>
      <span className="mr-1 pt-1">{resultUi.result}</span>
    </div>
  )
}

Result.propTypes = {
  result: PropTypes.string.isRequired,
}

export default Result
