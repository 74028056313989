import React from 'react'
import PropTypes from 'prop-types'
import { Link, generatePath } from 'react-router-dom'

import { formatDateTime } from '../../../libs/formatter'
import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'
import TournamentStatus from './TournamentStatus'

const TournamentListRow = ({
  tournament,
}) => {
  const {
    id,
    name,
    startOn = null,
    endOn = null,
    numRounds,
    createdAt,
    creator: { name: creatorName },
  } = tournament

  return (
    <tr>
      <td>
        <Link to={generatePath(ROUTE_TOURNAMENT_VIEW, { id, tabId: 'summary' })}>
          {name}
        </Link>
      </td>
      <td>{formatDateTime(startOn)}</td>
      <td>{formatDateTime(endOn)}</td>
      <td>{numRounds}</td>
      <td>{formatDateTime(createdAt)}</td>
      <td>{creatorName}</td>
      <td>
        <TournamentStatus tournament={tournament} />
      </td>
    </tr>
  )
}

TournamentListRow.propTypes = {
  tournament: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startOn: PropTypes.string,
    endOn: PropTypes.string,
    numRounds: PropTypes.number.isRequired,
    completed: PropTypes.bool,
    createdAt: PropTypes.string.isRequired,
    creator: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}

export default TournamentListRow
