import { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

const LoadDataByArgs = ({
  accessToken,
  loadData,
  args,
  dispatch,
}) => {
  useEffect(() => {
    dispatch(loadData(accessToken, ...args))

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return null
}

LoadDataByArgs.propTypes = {
  accessToken: PropTypes.string,
  loadData: PropTypes.func.isRequired,
  args: PropTypes.arrayOf(PropTypes.any).isRequired,
}

LoadDataByArgs.defaultProps = {
  accessToken: null,
}

const mapStateToProps = ({
  auth: {
    data: { accessToken },
  },
}) => ({
  accessToken,
})

export default connect(
  mapStateToProps,
)(LoadDataByArgs)
