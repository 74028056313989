/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

import { NoResults } from '../../common'
import WallChartRow from './WallChartRow'

const WallChart = ({ tournament: { numRounds }, docs }) => {
  const numCols = numRounds + 3
  return (
    <Table hover className="mt-3">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Rating</th>
          {Array.from(Array(numRounds)).map((value, index) => {
            const roundNo = index + 1
            return <th key={`rd-${roundNo}`}>{`Rd ${roundNo}`}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {docs.length ? docs.map((doc) => {
          const { id } = doc
          return <WallChartRow key={id} doc={doc} numRounds={numRounds} />
        }) : (
          <NoResults colSpan={numCols} />
        )}
      </tbody>
    </Table>
  )
}

WallChart.propTypes = {
  tournament: PropTypes.shape({
    numRounds: PropTypes.number.isRequired,
  }).isRequired,
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default WallChart
