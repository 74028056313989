import React from 'react'
import {
  UncontrolledTooltip,
} from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Info } from '../../common'
import ReduxFormContext from '../../common/contexts/ReduxFormContext'

const ForbiddenListInfo = ({
  id, name, forbiddenIds, combinedForbiddenIds,
}) => {
  const infoId = `forbidden-list-info-${id}`
  return (
    <ReduxFormContext.Consumer>
      {({ lookups }) => {
        const myPlayers = forbiddenIds.map((forbiddenId) => {
          return lookups[forbiddenId]
        })
        const otherPlayers = combinedForbiddenIds.filter((forbiddenId) => {
          return !forbiddenIds.includes(forbiddenId)
        }).map((forbiddenId) => {
          return lookups[forbiddenId]
        })

        const verb = otherPlayers.length > 1 ? 'have' : 'has'
        const hasMyPlayers = myPlayers.length > 0
        const hasOtherPlayers = otherPlayers.length > 0
        return (
          <div>
            <Info id={infoId} className="text-info" />
            <UncontrolledTooltip placement="right" target={infoId}>
              {hasMyPlayers && (
                <p className={classNames('mt-0', { 'mb-0': !hasOtherPlayers })}>
                  {`${name} has requested not to pair with: ${myPlayers.join(', ')}.`}
                </p>
              )}
              {hasOtherPlayers && (
                <p className="mt-0 mb-0">
                  {`${otherPlayers.join(', ')} ${verb} requested not to pair with: ${name}.`}
                </p>
              )}
            </UncontrolledTooltip>
          </div>
        )
      }}
    </ReduxFormContext.Consumer>
  )
}

ForbiddenListInfo.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  forbiddenIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  combinedForbiddenIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ForbiddenListInfo
