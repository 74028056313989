import { failureAction, successAction, startAction } from '../../common'
import { UPDATE_RESULT, RESET_UPDATE_RESULT } from '../types'

export const initialState = {
  updating: false,
  updated: false,
  error: {},
}

const reducer = ({ result: state }, action) => {
  switch (action.type) {
    case startAction(UPDATE_RESULT):
      return {
        ...state,
        updating: action.payload,
        updated: false,
        error: {},
      }
    case successAction(UPDATE_RESULT):
      return {
        ...state,
        updating: false,
        updated: true,
        error: {},
      }
    case failureAction(UPDATE_RESULT):
      return {
        ...state,
        updating: false,
        updated: false,
        error: action.payload,
      }
    case RESET_UPDATE_RESULT:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
