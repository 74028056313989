import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { FaFly } from 'react-icons/fa'
import PropTypes from 'prop-types'

const Withdrawn = ({ target }) => {
  return (
    <>
      <FaFly className="text-primary" style={{ fontSize: '1.5rem' }} />
      <UncontrolledTooltip placement="right" target={target}>
        The player has withdrawn from the tournament.
      </UncontrolledTooltip>
    </>
  )
}

Withdrawn.propTypes = {
  target: PropTypes.string.isRequired,
}

export default Withdrawn
