import { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

const LoadDataByMatch = ({
  accessToken,
  match: {
    params: { id },
  },
  loadData,
  args,
  dispatch,
}) => {
  useEffect(() => {
    if (id) {
      dispatch(loadData(accessToken, id, ...args))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [id])

  return null
}

LoadDataByMatch.propTypes = {
  accessToken: PropTypes.string,
  match: ReactRouterPropTypes.match.isRequired,
  loadData: PropTypes.func.isRequired,
  args: PropTypes.arrayOf(PropTypes.any),
}

LoadDataByMatch.defaultProps = {
  accessToken: null,
  args: [],
}

const mapStateToProps = ({
  auth: {
    data: { accessToken },
  },
}) => ({
  accessToken,
})

export default connect(
  mapStateToProps,
)(withRouter(LoadDataByMatch))
