import {
  HTTP_UNAUTHORIZED,
  UNKNOWN_ERROR,
  DONE_UNKNOWN_ERROR,
  SILENT_LOGIN_ERROR,
  RESET_AUTH,
  GET_AUTH,
} from './types'

export const initialState = {
  loggedIn: false,
  unauthorized: false,
  unknownError: false,
  silentLoginError: false,
  data: {
    accessToken: null,
    idToken: null,
  },
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTH:
      return {
        ...state,
        loggedIn: true,
        data: action.payload,
      }
    case HTTP_UNAUTHORIZED:
      return {
        ...initialState,
        unauthorized: true,
      }
    case UNKNOWN_ERROR:
      return {
        ...initialState,
        unknownError: true,
      }
    case DONE_UNKNOWN_ERROR:
      return {
        ...state,
        unknownError: false,
      }
    case SILENT_LOGIN_ERROR:
      return {
        ...state,
        silentLoginError: true,
      }
    case RESET_AUTH:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export default authReducer
