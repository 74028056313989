import React from 'react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'

const TournamentDescriptionBody = ({ desc }) => {
  const Placeholder = () => {
    return <span className="text-muted">Oops... no more details available yet.</span>
  }

  return (
    <div>
      {desc === '' ? <Placeholder /> : parse(desc)}
    </div>
  )
}

TournamentDescriptionBody.propTypes = {
  desc: PropTypes.string.isRequired,
}

export default TournamentDescriptionBody
