import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { listTournamentPlayers } from '../../tournament-player/actions'
import { LoadingSpinner, LoadDataByArgs as TournamentPlayersData } from '../../common'
import WallChart from '../components/WallChart'
import { getWallChart } from '../actions'

const WallChartContainer = ({
  match: {
    params: { id },
  },
  loading,
  loaded,
  tournament,
  docs,
  getWallChartConnect,
}) => {
  useEffect(() => {
    if (id) {
      getWallChartConnect(null, id)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [id])

  return (
    <>
      <TournamentPlayersData loadData={listTournamentPlayers} args={[id]} />
      {loading && <LoadingSpinner />}
      {loaded && <WallChart tournament={tournament} docs={docs} />}
    </>
  )
}

WallChartContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({}).isRequired,
  docs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getWallChartConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  tournament: {
    summary: { loading: loadingTournament, loaded: tournamentLoaded, data: tournament },
  },
  tournamentWallChart: {
    loading: loadingWallChart,
    loaded: loadedWallChart,
    data: {
      docs = [],
    },
  },
}) => ({
  loading: loadingTournament || loadingWallChart,
  loaded: tournamentLoaded && loadedWallChart,
  tournament,
  docs,
})

const mapDispatchToProps = {
  getWallChartConnect: getWallChart,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(WallChartContainer))
