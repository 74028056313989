import Http from './http'

class Release extends Http {
  async listToDo() {
    const res = await this.request.get(
      '/issues',
      { params: { type: 'TO_DO' } },
    )
    return res.data
  }

  async listInProgress() {
    const res = await this.request.get(
      '/issues',
      { params: { type: 'IN_PROGRESS' } },
    )
    return res.data
  }

  async listDone() {
    const res = await this.request.get(
      '/issues',
      { params: { type: 'DONE' } },
    )
    return res.data
  }
}

export default Release
