import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { ROUTE_HOME } from '../../app/types'

const BreadcrumbComponent = ({ items: { items, buttons: Buttons } }) => {
  return (
    items.length > 0 && (
      <nav>
        <Breadcrumb>
          <BreadcrumbItem tag={Link} to={ROUTE_HOME}>
            Chess Tour
          </BreadcrumbItem>
          {items.map((item) => {
            const { component: Component, key, ...rest } = item
            return Component ? <Component key={key} {...rest} /> : <BreadcrumbItem key={key} {...rest} />
          })}
          {Buttons ? <Buttons /> : null}
        </Breadcrumb>
      </nav>
    )
  )
}

BreadcrumbComponent.propTypes = {
  items: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        ...BreadcrumbItem.propTypes,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      }),
    ),
    buttons: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }),
}

export default BreadcrumbComponent
