import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import WithdrawModal from '../components/WithdrawModal'
import { withdrawTournamentPlayer } from '../actions'

const WithdrawModalContainer = ({
  isOpen,
  toggle,
  accessToken,
  tournamentPlayer,
  withdrawTournamentPlayerConnect,
}) => {
  const { id } = tournamentPlayer
  return (
    <WithdrawModal
      isOpen={isOpen}
      toggle={toggle}
      tournamentPlayer={tournamentPlayer}
      confirmWithdraw={() => {
        return withdrawTournamentPlayerConnect(accessToken, id)
      }}
    />
  )
}

WithdrawModalContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
  tournamentPlayer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  withdrawTournamentPlayerConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: { data: { accessToken } },
}) => {
  return {
    accessToken,
  }
}


const mapDispatchToProps = {
  withdrawTournamentPlayerConnect: withdrawTournamentPlayer,
}

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawModalContainer)
