import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getFormValues } from 'redux-form'
import _ from 'lodash'
import ReactRouterPropTypes from 'react-router-prop-types'

import TournamentRegisterConfirm from '../components/TournamentRegisterConfirm'
import {
  addTournamentPlayer,
  resetEditTournamentPlayer,
  cancelAddTournamentPlayer,
  listTournamentPlayers,
} from '../../tournament-player/actions'
import {
  LoadDataByMatch as TournamentData,
  LoadDataByMatch as TournamentPlayersData,
  LoadingSpinner,
  AppAlert as Alert,
} from '../../common'

import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'
import { getTournament } from '../actions'
import { submit } from '../../tournament-player/tournament-player-edit'

const TournamentRegisterConfirmContainer = ({
  history,
  accessToken,
  loading,
  loaded,
  tournament: { id: tournamentId },
  tournamentPlayers,
  saved,
  cancelled,
  error: { message: errMsg = null },
  formValues,
  addTournamentPlayerConnect,
  resetEditTournamentPlayerConnect,
  cancelAddTournamentPlayerConnect,
}) => {
  useEffect(() => {
    if (saved) {
      history.push(generatePath(ROUTE_TOURNAMENT_VIEW, { id: tournamentId, tabId: 'players' }))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saved])

  useEffect(() => {
    if (cancelled) {
      history.go(-2)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [cancelled])

  const isFormEmpty = _.isEmpty(formValues)
  useEffect(() => {
    if (isFormEmpty) {
      history.goBack()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isFormEmpty])

  const onSubmit = (data) => {
    return submit(
      data,
      (tournamentPlayerData) => addTournamentPlayerConnect(accessToken, tournamentId, tournamentPlayerData),
    )
  }

  return (
    <>
      <TournamentData loadData={getTournament} />
      <TournamentPlayersData loadData={listTournamentPlayers} />
      {errMsg && <Alert text={errMsg} props={{ color: 'danger' }} />}
      <Alert
        text="Your registration is about to complete. Please verify your information and confirm."
        props={{ color: 'info' }}
      />
      {loading && <LoadingSpinner />}
      {loaded && (
        <TournamentRegisterConfirm
          {...{
            formValues,
            tournamentId,
            tournamentPlayers,
            doDestroy: saved || cancelled,
            onSubmit,
            cancelAddTournamentPlayerConnect,
            resetEditTournamentPlayerConnect,
          }}
        />
      )}
    </>
  )
}

TournamentRegisterConfirmContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  cancelled: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    numRounds: PropTypes.number,
  }).isRequired,
  tournamentPlayers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  })).isRequired,
  accessToken: PropTypes.string.isRequired,
  formValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    rating: PropTypes.string,
  }).isRequired,
  addTournamentPlayerConnect: PropTypes.func.isRequired,
  resetEditTournamentPlayerConnect: PropTypes.func.isRequired,
  cancelAddTournamentPlayerConnect: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {
    auth: {
      data: { accessToken },
    },
    tournament: {
      summary: {
        loading: loadingTournament,
        loaded: tournamentLoaded,
        data: tournament,
      },
      players: {
        loading: loadingTournamentPlayers,
        loaded: tournamentPlayersLoaded,
        data: tournamentPlayers,
      },
    },
    tournamentPlayer: {
      edit: { saved, cancelled, error },
    },
  } = state

  return {
    accessToken,
    loading: loadingTournament || loadingTournamentPlayers,
    loaded: tournamentLoaded && tournamentPlayersLoaded,
    tournament,
    tournamentPlayers,
    saved,
    cancelled,
    error,
    formValues: getFormValues('tournamentRegister')(state) || {},
  }
}

const mapDispatchToProps = {
  addTournamentPlayerConnect: addTournamentPlayer,
  resetEditTournamentPlayerConnect: resetEditTournamentPlayer,
  cancelAddTournamentPlayerConnect: cancelAddTournamentPlayer,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TournamentRegisterConfirmContainer))
