/**
 * Temperarily hold auth data for the sole use of user profile loading.
 * Once user profile is loaded, will call API to create user if the user does not yet exists,
 * After which will populate auth with auth data cached here.
 * By doing this, we can put other APIs on hold until we are sure auth user has already been created in DB.
 */
import { failureAction, successAction } from '../../common'
import { LOGIN } from '../../auth/types'

export const initialState = {
  loggedIn: false,
  error: {},
  data: {
    accessToken: null,
    idToken: null,
  },
}

const reducer = ({ auth: state }, action) => {
  switch (action.type) {
    case successAction(LOGIN):
      return {
        ...state,
        loggedIn: true,
        error: {},
        data: action.payload,
      }
    case failureAction(LOGIN):
      return {
        ...state,
        ready: false,
        loggedIn: false,
        error: action.payload,
        data: {},
      }

    default:
      return state
  }
}

export default reducer
