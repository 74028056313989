import React from 'react'
import { DropdownItem } from 'reactstrap'
import PropTypes from 'prop-types'

const ExportTournamentItem = ({ onClick }) => {
  return (
    <DropdownItem
      onClick={onClick}
    >
      Export to FIDE data format
    </DropdownItem>
  )
}

ExportTournamentItem.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ExportTournamentItem
