/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import {
  Container, Jumbotron, Row, Col,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import LoginButton from '../containers/LoginButtonContainer'
import { ROUTE_ISSUES } from '../../app/types'

const Home = () => {
  return (
    <>
      <Jumbotron>
        <Container>
          <h1 className="display-3 text-secondary">What is Chess Tour, and why?</h1>
          <p className="lead">
            One day in early 2019 I was looking for a pairing tool to organize a chess tournament. I certainly have looked up pairing software in the past but this was the first time I seriously did a research. I was shocked to see various options available that were so the 90s! Gee, isn't this already 9102 and shouldn't we have something more relevant for the lovely game that deserves it? So I decided to make a change and started this project. Since then, 9 months have passed and the project’s still far from finished. However, I’m releasing it now that I’ve completed the very basics. Comments are welcome and you can report bugs or request features
            {' '}
            <a href="https://www.facebook.com/chesstour.ca">here</a>
            . After all, in the world of software, user feedback is the best requirement.
          </p>
          <hr className="my-2" />
          <p>
            To give it a try, please first login. For TDs, you need an account so that I can differenciate your tournaments from others. For players, I have a plan to skip logins for registration but you need to login too at the moment. I've enabled login with facebook and google, but login by username/password is still one of the todos. For those interested to see my progress live, I've created a
            {' '}
            <Link to={ROUTE_ISSUES}>todo list</Link>
            .
          </p>
          <p className="lead">
            <LoginButton />
          </p>
        </Container>
      </Jumbotron>
      <Container>
        <Row>
          <Col md="4">
            <h2>Getting started</h2>
            <p>
              TDs get started by creating a tournament after login. Players get started by registering to existing tournaments. Once a tournament is created, both the TD (whoever created the tournament) and other users can register players. A user can register multiple players and there is no limit on the number of players he can register at the moment.
            </p>
          </Col>
          <Col md="4">
            <h2>Pairings</h2>
            <p>
              Once there are registered players, TD (whoever created the tournament) can create pairings. Registration is open util last round pairing is created, or it can be closed any time by tournament settings. The pairing system is powered by
              {' '}
              <a href="http://www.rrweb.org/javafo/JaVaFo.htm">JaVaFo</a>
, which based on this
              {' '}
              <a href="https://www.fide.com/FIDE/handbook/C04Annex3_FEP18.pdf">FIDE document</a>
              {' '}
is the pairing engine of a number of FIDE Endorsed Programs.
            </p>
          </Col>
          <Col md="4">
            <h2>The rest</h2>
            <p>
              Once a game is completed (out of this system), either the TD or the players of the game can report results. Once all results are reported, pairings of the next round can be created.
            </p>
            <p>
              Standings of the current round will be generated once all results of the round have been reported. Once all rounds of the tournment are completed, the tournament is considered completed.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home
