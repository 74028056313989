import axios from 'axios'
import httpAdapter from 'axios/lib/adapters/http'
import status from 'statuses'
import jwt from 'jsonwebtoken'

import config from '../config'
import { startAction, failureAction, successAction } from '../modules/common'
import {
  auth, getUnauthorized, getLoginInfo, getSilentLoginError, getAuth,
} from '../modules/auth'

class Http {
  constructor(accessToken = null, dispatch) {
    this.request = axios.create({
      baseURL: config.api.baseUrl,
    })
    if (accessToken !== null) {
      this.request.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    }
    this.accessToken = accessToken
    this.dispatch = dispatch
    this.status = null
    this.request.defaults.adapter = httpAdapter

    this.request.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        const { response } = error
        if (response) {
          const { status: statusCode = null, data = {} } = response
          if (statusCode === status('unauthorized')) {
            this.status = statusCode
          }
          return statusCode < 500 ? Promise.reject(data) : Promise.reject(error)
        }

        return Promise.reject(error)
      },
    )
  }

  /**
   *
   * @callback requestCallback
   */

  /**
   * Dispatch async function
   *
   * @param {requestCallback} func
   * @param {string} type
   * @param {Object} actions
   */
  async d(func, type, actions = { start: true, success: true, error: true }) {
    const callback = async () => {
      const { start, success, error } = actions
      if (start) {
        this.dispatch({ type: startAction(type) })
      }
      try {
        const res = await func()
        if (success) {
          this.dispatch({
            type: successAction(type),
            payload: res,
          })
        }
      } catch (err) {
        if (error) {
          this.dispatch({
            type: failureAction(type),
            payload: err,
          })
        }
        if (this.status === status('unauthorized')) {
          this.dispatch(getUnauthorized())
        }
      }
    }

    if (this.accessToken !== null) {
      const { exp } = jwt.decode(this.accessToken)
      // renew token if token is to expire in 30 secs or less
      if (Math.floor(Date.now() / 1000) > exp - 30) {
        const promise = new Promise((resolve) => {
          auth.checkSession(async (err, authResult) => {
            if (err) {
              this.dispatch(getSilentLoginError())
              resolve()
              return
            }
            const { accessToken } = authResult
            this.accessToken = accessToken
            this.request.defaults.headers.common.Authorization = `Bearer ${accessToken}`
            await callback()
            this.dispatch(getLoginInfo(authResult))
            this.dispatch(getAuth(authResult))
            resolve()
          })
        })
        await promise
        return
      }
    }

    await callback()
  }
}

export default Http
