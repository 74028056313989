import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ReenterModal from '../components/ReenterModal'
import { reenterTournamentPlayer } from '../actions'

const ReenterModalContainer = ({
  isOpen,
  toggle,
  accessToken,
  tournamentPlayer,
  reenterTournamentPlayerConnect,
}) => {
  const { id } = tournamentPlayer
  return (
    <ReenterModal
      isOpen={isOpen}
      toggle={toggle}
      tournamentPlayer={tournamentPlayer}
      confirmReenter={() => {
        return reenterTournamentPlayerConnect(accessToken, id)
      }}
    />
  )
}

ReenterModalContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
  tournamentPlayer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  reenterTournamentPlayerConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: { data: { accessToken } },
}) => {
  return {
    accessToken,
  }
}


const mapDispatchToProps = {
  reenterTournamentPlayerConnect: reenterTournamentPlayer,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReenterModalContainer)
