import React from 'react'
import { Link } from 'react-router-dom'
import { getYear } from 'date-fns'
import packageData from '../../../../package.json'

const Footer = () => {
  const startYear = 2019
  const currYear = getYear(new Date())
  const year = currYear === startYear ? startYear : `${startYear} - ${currYear}`
  const { author } = packageData
  return (
    <footer className="footer d-flex flex-row">
      <div>
        <span>&copy;</span>
        {` ${year} ${author} \u00b7 All rights reserved`}
      </div>
      <div className="ml-3">
        <Link to="/terms">Terms</Link>
      </div>
      <div className="ml-3">
        <Link to="/privacy">Privacy</Link>
      </div>
    </footer>
  )
}

export default Footer
