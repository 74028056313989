export const ROUTE_HOME = '/'
export const ROUTE_RELEASES = '/releases'
export const ROUTE_ISSUES = '/issues'
export const ROUTE_CALLBACK = '/callback'
export const ROUTE_LOGIN = '/login'
export const ROUTE_LOGOUT = '/logout'
export const ROUTE_TERMS = '/terms'
export const ROUTE_PRIVACY = '/privacy'
export const ROUTE_TOURNAMENT_CREATE = '/tournaments/create'
export const ROUTE_TOURNAMENTS = '/tournaments'
export const ROUTE_TOURNAMENTS_VIEW_ALL = '/tournaments/all'
export const ROUTE_TOURNAMENTS_VIEW = '/tournaments/:tabId'
export const ROUTE_TOURNAMENT_VIEW = '/tournaments/:id/:tabId'
export const ROUTE_TOURNAMENT_EDIT = '/tournaments/:id/edit'
export const ROUTE_TOURNAMENT_COPY = '/tournaments/:id/copy'
export const ROUTE_TOURNAMENT_CONFIG_EDIT = '/tournaments/:id/config'
export const ROUTE_TOURNAMENT_REGISTER = '/tournaments/:id/register'
export const ROUTE_TOURNAMENT_REGISTER_CONFIRM = '/tournaments/:id/register-confirm'
export const ROUTE_TOURNAMENT_PLAYER_VIEW = '/tournament-players/:id'
export const ROUTE_TOURNAMENT_PLAYER_EDIT = '/tournament-players/:id/edit'
export const ROUTE_TOURNAMENT_PAIRING_CREATE = '/tournament/:id/pairings/create'
export const ROUTE_TOURNAMENT_PAIRING_EDIT = '/tournament/:id/pairings/last-round/edit'
export const ROUTE_REDIRECT = '/tournaments/all'
