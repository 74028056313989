import toDoReducer, { initialState as toDoState } from './to-do'
import inProgressReducer, { initialState as inProgressState } from './in-progress'
import doneReducer, { initialState as doneState } from './done'

export const initialState = {
  toDo: toDoState,
  inProgress: inProgressState,
  done: doneState,
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        toDo: toDoReducer(state, action),
        inProgress: inProgressReducer(state, action),
        done: doneReducer(state, action),
      }
  }
}
