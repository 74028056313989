import React from 'react'
import {
  Button, UncontrolledTooltip,
} from 'reactstrap'
import { Link, generatePath } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ROUTE_TOURNAMENT_PAIRING_CREATE } from '../../../app/types'
import { Info } from '../../../common'

const CreatePairingsButton = ({
  tournamentId,
  canCreatePairings,
  nextRoundNo,
  cannotPairText,
}) => {
  return (
    <div id="createPairingsButton" className={classNames({ 'cursor-help': !canCreatePairings })}>
      <Button
        className="no-right-radius"
        color="primary"
        size="md"
        tag={Link}
        to={generatePath(ROUTE_TOURNAMENT_PAIRING_CREATE, { id: tournamentId })}
        disabled={!canCreatePairings}
      >
        <span className="d-inline-flex">
          {`Create round ${nextRoundNo} pairings`}
        </span>
        {!canCreatePairings && (
          <div className="d-inline-flex ml-1">
            <Info className="text-white" />
            <UncontrolledTooltip placement="right" target="createPairingsButton">
              {cannotPairText}
            </UncontrolledTooltip>
          </div>
        )}
      </Button>
    </div>
  )
}

CreatePairingsButton.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  canCreatePairings: PropTypes.bool.isRequired,
  nextRoundNo: PropTypes.number.isRequired,
  cannotPairText: PropTypes.string.isRequired,
}

export default CreatePairingsButton
