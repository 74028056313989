import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentSummary from '../components/TournamentSummary'
import { LoadingSpinner } from '../../common'

const TournamentSummaryContainer = ({ loading, loaded, tournament }) => {
  return (
    <>
      {loading && <LoadingSpinner />}
      {loaded && <TournamentSummary tournament={tournament} />}
    </>
  )
}

TournamentSummaryContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = ({
  tournament: {
    summary: { loading, loaded, data: tournament },
  },
}) => {
  return {
    loading,
    loaded,
    tournament,
  }
}

export default connect(mapStateToProps)(TournamentSummaryContainer)
