import React from 'react'
import PropTypes from 'prop-types'

import ActionModal from '../../common/components/ActionModal'
import { formatName } from '../../../libs/formatter'

const WithdrawModal = ({
  tournamentPlayer,
  isOpen,
  toggle,
  confirmWithdraw,
}) => {
  const headerText = 'Withdraw player?'
  const playerName = formatName(tournamentPlayer)
  const Body = () => {
    return (
      <>
        {'Are you sure you want to withdraw '}
        <span className="font-weight-less-bold">{playerName}</span>
        {' from the tournament? '}
        <span className="font-weight-less-bold">{`${playerName} `}</span>
        will no longer be paired once withdraw from the tournament.
      </>
    )
  }

  return (
    <ActionModal {...{
      isOpen,
      headerText,
      Body,
      toggle,
      confirmAction: confirmWithdraw,
    }}
    />
  )
}

WithdrawModal.propTypes = {
  tournamentPlayer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  confirmWithdraw: PropTypes.func.isRequired,
}

export default WithdrawModal
