import React from 'react'
import PropTypes from 'prop-types'

import TournamentListRow from './TournamentListRow'

const TournamentListBody = ({ docs }) => {
  return (
    <>
      {docs.map((doc) => {
        const { id } = doc
        return <TournamentListRow key={id} tournament={doc} />
      })}
    </>
  )
}

TournamentListBody.propTypes = {
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default TournamentListBody
