import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import { withRouter, generatePath } from 'react-router-dom'
import {
  Container, Row, Col, Button, FormGroup,
} from 'reactstrap'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Label, Form } from '../../common'
import NumRoundsInput from './NumRoundsInput'
import { required, renderField } from '../../../libs/form'
import DatePicker from '../../common/components/DatePicker'
import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'
import TournamentDescriptionEdit from './tournament-summary/TournamentDescriptionEdit'

const TournamentEdit = ({
  history,
  handleSubmit,
  submitting,
  // from Container
  formValues,
  saved,
  tournamentId,
  fromTournament,
  resetSaveTournamentConnect,
}) => {
  useEffect(() => {
    if (saved && tournamentId) {
      history.push(generatePath(ROUTE_TOURNAMENT_VIEW, { id: tournamentId, tabId: 'summary' }))
      return () => {
        resetSaveTournamentConnect()
      }
    }

    return undefined
  })

  const { startOn, endOn } = formValues
  const { id: fromTournamentId = null, name: fromTournamentName = null } = fromTournament
  const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ]
  return (
    <Container className="mt-3">
      <Form onSubmit={handleSubmit} submitting={submitting}>
        {fromTournamentId && (
        <FormGroup>
          <div className="d-flex flex-row">
            <div>
              Do you want to copy all registered players from
              {' '}
              <span className="font-weight-less-bold">{fromTournamentName}</span>
              ? Please note, byes will not be copied over.
            </div>
            <div className="ml-3 flex-grow-1 w-25">
              <Field
                type="react-select"
                name="copyTournamentPlayers"
                component={renderField}
                options={options}
                placeholder={null}
              />
            </div>
          </div>
        </FormGroup>
        )}
        <FormGroup>
          <Label for="name">Tournament name</Label>
          <Field type="text" id="name" name="name" component={renderField} validate={[required]} />
        </FormGroup>
        <FormGroup>
          <Label for="numRounds">Number of rounds</Label>
          <Field name="numRounds" component={renderField} sub={NumRoundsInput} validate={[required]} />
        </FormGroup>
        <FormGroup>
          <Label for="timeControl">Time control</Label>
          <Field type="text" id="timeControl" name="timeControl" component={renderField} validate={[required]} />
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <Label for="startOn">Start on</Label>
              <Field
                id="startOn"
                name="startOn"
                maxDate={endOn}
                showTime
                component={DatePicker}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="endOn">End on</Label>
              <Field
                id="endOn"
                name="endOn"
                minDate={startOn}
                showTime
                component={DatePicker}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="contact">Contact</Label>
          <Field type="textarea" id="contact" name="contact" component={renderField} />
        </FormGroup>
        <FormGroup>
          <Label for="timeControl">More details</Label>
          <TournamentDescriptionEdit />
        </FormGroup>
        <div className="d-flex flex-row justify-content-center">
          <div className="p-2">
            <Button type="submit" color="primary" className="my-2" disabled={submitting}>
              Save
            </Button>
          </div>
          <div className="p-2">
            <Button color="secondary" className="my-2" onClick={() => history.goBack()} disabled={submitting}>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  )
}

TournamentEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  tournamentId: PropTypes.string,
  fromTournament: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  formValues: PropTypes.shape({
    startOn: PropTypes.string,
    endOn: PropTypes.string,
  }).isRequired,
  resetSaveTournamentConnect: PropTypes.func.isRequired,
}

TournamentEdit.defaultProps = {
  tournamentId: null,
  fromTournament: {},
}

export default reduxForm({
  form: 'tournamentEdit',
})(withRouter(TournamentEdit))
