import React from 'react'
import { Modal, ModalBody, Spinner } from 'reactstrap'

const SavingSpinner = () => {
  return (
    <Modal
      isOpen
      centered
      fade={false}
      contentClassName="bg-transparent border-0"
    >
      <ModalBody className="d-flex justify-content-center">
        <Spinner className="saving-spinner" />
      </ModalBody>
    </Modal>
  )
}

export default SavingSpinner
