import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const NumRoundsInputButton = ({ onClick, value, ...rest }) => (
  <Button color="primary" onClick={() => onClick(value)} {...rest}>
    {value}
  </Button>
)

NumRoundsInputButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
}

export default NumRoundsInputButton
