import React from 'react'
import { connect } from 'react-redux'
import {
  withRouter,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Paginator } from '../../common'
import listTournaments from '../actions'

const PaginatorContainer = ({
  match,
  pagination,
  accessToken,
  listTournamentsConnect,
  activePage,
  setActivePage,
}) => {
  const onChange = (page) => {
    const { params: { tabId = '' } } = match
    const params = {}
    if (tabId === 'iCreated' || tabId === 'iRegistered') {
      params[tabId] = true
    }
    listTournamentsConnect(accessToken, { ...params, page })
  }

  const { last } = pagination
  const show = last > 1
  return (
    <>
      {show && (
      <Paginator
        pagination={pagination}
        onChange={onChange}
        activePage={activePage}
        setActivePage={setActivePage}
      />
      )}
    </>
  )
}

PaginatorContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  accessToken: PropTypes.string,
  pagination: PropTypes.shape({
    last: PropTypes.number.isRequired,
  }).isRequired,
  listTournamentsConnect: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
}

PaginatorContainer.defaultProps = {
  accessToken: null,
}

const mapStateToProps = ({
  auth: {
    data: { accessToken },
  },
  tournaments: { data: { pagination } },
}) => ({
  accessToken,
  pagination,
})

const mapDispatchToProps = { listTournamentsConnect: listTournaments }

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(PaginatorContainer))
