import React from 'react'
import { withRouter, generatePath, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button, Row, Col, UncontrolledTooltip,
} from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { ROUTE_TOURNAMENT_REGISTER } from '../../app/types'
import { Info } from '../../common'

const TournamentRegisterButtonContainer = ({
  match: {
    params: { id },
  },
  loggedIn,
  tournamentLoaded,
  userLoaded,
  tournament: {
    createdBy, settings: {
      tournament: { isPublic },
    },
  },
  user: { id: userId },
}) => {
  const isMine = userId === createdBy
  const show = tournamentLoaded && (!loggedIn || userLoaded) && !isMine && isPublic

  return show && (
    <Row className="my-4">
      <Col
        size="auto"
        id="registerButton"
        className={classNames(
          'd-flex', 'justify-content-center', { 'cursor-help': !loggedIn },
        )}
      >
        <Button
          color="danger"
          size="lg"
          block
          tag={Link}
          to={generatePath(ROUTE_TOURNAMENT_REGISTER, { id })}
          className="w-50 btn-rounded"
          style={{ maxWidth: '500px' }}
          disabled={!loggedIn}
        >
          Register
          {!loggedIn && (
            <div className="d-inline-flex ml-1">
              <Info className="text-white" />
              <UncontrolledTooltip placement="top" target="registerButton">
                To register for the tournament, please first login.
              </UncontrolledTooltip>
            </div>
          )}

        </Button>
      </Col>
    </Row>
  )
}

TournamentRegisterButtonContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  tournamentLoaded: PropTypes.bool.isRequired,
  userLoaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    settings: PropTypes.shape({
      tournament: PropTypes.shape({
        isPublic: PropTypes.bool,
      }),
    }),
    createdBy: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = ({
  auth: { loggedIn },
  tournament: {
    summary: {
      loaded: tournamentLoaded,
      data: tournament,
    },
  },
  user: {
    details: {
      loaded: userLoaded,
      data: user,
    },
  },
}) => {
  return {
    loggedIn,
    tournamentLoaded,
    userLoaded,
    tournament,
    user,
  }
}

export default connect(
  mapStateToProps,
)(withRouter(TournamentRegisterButtonContainer))
