import React from 'react'
import { Button } from 'reactstrap'
import { FaCog } from 'react-icons/fa'
import { Link, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ROUTE_TOURNAMENT_CONFIG_EDIT } from '../../../app/types'

const ConfigTournamentButton = ({
  tournamentId,
}) => {
  return (
    <Button
      color="secondary"
      tag={Link}
      to={generatePath(ROUTE_TOURNAMENT_CONFIG_EDIT, { id: tournamentId })}
    >
      <FaCog className="mr-2" style={{ fontSize: '1.5rem' }} />
      Tournament settings
    </Button>
  )
}

ConfigTournamentButton.propTypes = {
  tournamentId: PropTypes.string.isRequired,
}

export default ConfigTournamentButton
