import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Releases from '../components/Releases'
import getReleases from '../actions'
import { LoadingSpinner } from '../../common'

const ReleasesContainer = ({
  loading,
  loaded,
  docs,
  getReleasesConnect,
}) => {
  useEffect(() => {
    getReleasesConnect()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  return (
    <>
      {loading && <LoadingSpinner />}
      {loaded && <Releases docs={docs} />}
    </>
  )
}

ReleasesContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  docs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getReleasesConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  releases: { loading, loaded, data: docs },
}) => ({
  loading,
  loaded,
  docs,
})

const mapDispatchToProps = { getReleasesConnect: getReleases }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReleasesContainer)
