/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

const Textarea = ({ text, placeholder }) => {
  const textList = text === '' ? [] : text.split('\n')
  return (
    <div>
      {textList.length > 0 ? textList.map((item, index) => {
        return <div key={`text-${index}`}>{item}</div>
      }) : (
        <div className="text-muted">
          {placeholder}
        </div>
      )}
    </div>
  )
}

Textarea.propTypes = {
  text: PropTypes.string,
  placeholder: PropTypes.string,
}

Textarea.defaultProps = {
  placeholder: '',
  text: '',
}

export default Textarea
