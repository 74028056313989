import React from 'react'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'
import { Link, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'

import { formatName } from '../../../../libs/formatter'
import TournamentPlayer from '../TournamentPlayer'
import { ROUTE_TOURNAMENT_PLAYER_VIEW } from '../../../app/types'

const TournamentPlayerModal = ({
  isOpen, toggle, tournamentPlayer, isMine,
}) => {
  const { id } = tournamentPlayer
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        <Link to={generatePath(ROUTE_TOURNAMENT_PLAYER_VIEW, { id })}>
          {formatName(tournamentPlayer)}
        </Link>
      </ModalHeader>
      <ModalBody>
        <TournamentPlayer tournamentPlayer={tournamentPlayer} isMine={isMine} />
      </ModalBody>
      <ModalFooter>
        <div className="p-2">
          <Button color="secondary" onClick={toggle}>Close</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

TournamentPlayerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isMine: PropTypes.bool.isRequired,
  tournamentPlayer: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}

export default TournamentPlayerModal
