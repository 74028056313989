import Http from './http'

class TournamentPlayer extends Http {
  async add(tournamentId, data) {
    const res = await this.request.post(`/tournaments/${tournamentId}/players`, data)
    return res.data
  }

  async update(tournamentPlayerId, data) {
    const res = await this.request.put(`/tournament-players/${tournamentPlayerId}`, data)
    return res.data
  }

  async list(tournamentId) {
    const res = await this.request.get(`/tournaments/${tournamentId}/players`)
    return res.data
  }

  async get(id) {
    const res = await this.request.get(`/tournament-players/${id}`)
    return res.data
  }

  async delete(id) {
    const res = await this.request.delete(`/tournament-players/${id}`)
    return res.data
  }

  async reenter(id) {
    const res = await this.request.put(`/tournament-players/${id}/reenter`, {})
    return res.data
  }

  async withdraw(id) {
    const res = await this.request.put(`/tournament-players/${id}/withdraw`, {})
    return res.data
  }
}

export default TournamentPlayer
