import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Withdrawn from './Withdrawn'

const RowNo = ({ rowNo, withdrawn }) => {
  const target = `row-no-${rowNo}`
  return (
    <td
      id={target}
      className={classNames('d-flex', 'flex-row', { 'cursor-help': withdrawn })}
    >
      <div>
        {rowNo}
      </div>
      <div>
        {withdrawn && <Withdrawn target={target} />}
      </div>
    </td>
  )
}

RowNo.propTypes = {
  rowNo: PropTypes.number.isRequired,
  withdrawn: PropTypes.bool.isRequired,
}

export default RowNo
