import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import LoginButton from '../../navbar/components/LoginButton'

const LoginButtonContainer = ({ loggedIn }) => {
  return !loggedIn && <LoginButton size="lg" />
}

LoginButtonContainer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
}

const mapStateToProps = ({ auth: { loggedIn } }) => ({
  loggedIn,
})

export default connect(mapStateToProps)(LoginButtonContainer)
