import React from 'react'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Table,
} from 'reactstrap'
import PropTypes from 'prop-types'

const LegendsModal = ({ isOpen, toggle, legends }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Legends</ModalHeader>
      <ModalBody>
        <Table size="sm">
          <thead>
            <tr>
              <th>Symbol</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {legends.map((item) => {
              const { symbol, desc } = item
              return (
                <tr key={symbol}>
                  <td>{symbol}</td>
                  <td>{desc}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <div className="p-2">
          <Button color="secondary" onClick={toggle}>Close</Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

LegendsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  legends: PropTypes.arrayOf(PropTypes.shape({
    symbol: PropTypes.string,
    desc: PropTypes.string,
  })).isRequired,
}

export default LegendsModal
