import React from 'react'
import PropTypes from 'prop-types'

import { formatName, formatPoints } from '../../../libs/formatter'

const StandingRow = ({
  doc: {
    id, playerNo, firstName, lastName, rating = null, totalPoints, rounds,
  },
}) => {
  return (
    <tr key={id}>
      <td>{playerNo}</td>
      <td>{formatName({ firstName, lastName })}</td>
      <td>{rating}</td>
      {rounds.map(({
        id: roundId, opponentNo, color, result,
      }) => {
        return <td key={roundId}>{`${opponentNo || ''} ${color || ''} ${result}`}</td>
      })}
      <td>{formatPoints(totalPoints)}</td>
    </tr>
  )
}

StandingRow.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.string.isRequired,
    playerNo: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    rating: PropTypes.number,
    totalPoints: PropTypes.number.isRequired,
    rounds: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    byes: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
}

export default StandingRow
