import React from 'react'
import {
  Container, CardDeck,
} from 'reactstrap'

import ToDoContainer from '../containers/ToDoContainer'
import InProgressContainer from '../containers/InProgressContainer'
import DoneContainer from '../containers/DoneContainer'
import LoadIssuesData from '../containers/LoadIssuesData'

const Issues = () => {
  return (
    <Container className="py-3">
      <LoadIssuesData />
      <CardDeck className="flex-grow-1">
        <ToDoContainer />
        <InProgressContainer />
        <DoneContainer />
      </CardDeck>
    </Container>
  )
}

export default Issues
