import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

import TournamentListHeader from '../components/TournamentListHeader'
import TournamentListBody from '../components/TournamentListBody'
import listTournaments from '../actions'
import { LoadingSpinner, NoResults } from '../../common'

const TournamentListContainer = ({
  tabId,
  accessToken,
  loading,
  loaded,
  docs,
  pagination: { page },
  listTournamentsConnect,
}) => {
  useEffect(() => {
    const params = {}
    if (tabId === 'iCreated' || tabId === 'iRegistered') {
      params[tabId] = true
    }
    listTournamentsConnect(accessToken, { ...params, page })
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [accessToken, tabId])

  return (
    <Table>
      <TournamentListHeader />
      <tbody>
        {loading && (
          <tr>
            <td>
              <LoadingSpinner />
            </td>
          </tr>
        )}
        {loaded && (
          docs.length ? (
            <TournamentListBody
              docs={docs}
            />
          ) : <NoResults colSpan={6} />
        )}
      </tbody>
    </Table>
  )
}

TournamentListContainer.propTypes = {
  tabId: PropTypes.oneOf(['all', 'iCreated', 'iRegistered']).isRequired,
  accessToken: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  docs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
  }).isRequired,
  listTournamentsConnect: PropTypes.func.isRequired,
}

TournamentListContainer.defaultProps = {
  accessToken: null,
}

const mapStateToProps = ({
  tournaments: { loading, loaded, data: { docs, pagination } },
  auth: {
    data: { accessToken },
  },
}) => ({
  loading,
  loaded,
  docs,
  pagination,
  accessToken,
})

const mapDispatchToProps = { listTournamentsConnect: listTournaments }

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentListContainer)
