import { REHYDRATE } from 'redux-persist'

import authReducer, { initialState as authState } from './authReducer'
import profileReducer, { initialState as profileState } from './profileReducer'
import detailsReducer, { initialState as detailsState } from './detailsReducer'

export const initialState = {
  auth: authState,
  profile: profileState,
  details: detailsState,
}

export default (state = initialState, action) => {
  const { payload = {} } = action
  const { user = {} } = (payload === null ? {} : payload)
  switch (action.type) {
    // don't know exactly why I've to do this...
    case REHYDRATE:
      return {
        ...state,
        ...user,
      }
    default:
      return {
        ...state,
        auth: authReducer(state, action),
        profile: profileReducer(state, action),
        details: detailsReducer(state, action),
      }
  }
}
