import { GET_TOURNAMENTS } from './types'
import Tournament from '../../apis/tournament'

const listTournaments = (accessToken, params = {}) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.list(params)
  return tournament.d(func, GET_TOURNAMENTS)
}

export default listTournaments
