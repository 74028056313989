import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import Callback from '../components/Callback'
import auth from '../auth'

const CallbackContainer = ({
  location, lockReady, loggedIn,
}) => {
  // Setup auth
  const { hash } = location
  useEffect(() => {
    if (lockReady && hash) {
      auth.resumeAuth(hash)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [lockReady, hash])

  return <Callback loggedIn={loggedIn} />
}

CallbackContainer.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  lockReady: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
}

const mapStateToProps = ({
  auth: {
    loggedIn,
  },
  lock: {
    ready: lockReady,
  },
}) => ({
  lockReady,
  loggedIn,
})

export default connect(mapStateToProps)(
  withRouter(CallbackContainer),
)
