import { failureAction, successAction, startAction } from '../common'
import { GET_RELEASES } from './types'

export const initialState = {
  loading: false,
  loaded: false,
  error: {},
  data: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case startAction(GET_RELEASES):
      return {
        ...state,
        ...initialState,
        loading: true,
        loaded: false,
      }
    case successAction(GET_RELEASES):
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {},
        data: action.payload,
      }
    case failureAction(GET_RELEASES):
      return {
        ...state,
        ...initialState,
        error: action.payload,
      }

    default:
      return state
  }
}

export default reducer
