import {
  failureAction, successAction, startAction,
} from '../../common'
import {
  GET_TOURNAMENT, CLOSE_TOURNAMENT, REOPEN_TOURNAMENT, SAVE_TOURNAMENT_DESCRIPTION,
} from '../types'

export const initialState = {
  loading: false,
  loaded: false,
  error: {},
  data: {},
}

let desc
let data
const reducer = ({ summary: state }, action) => {
  switch (action.type) {
    case startAction(GET_TOURNAMENT):
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
        data: {},
      }
    case successAction(GET_TOURNAMENT):
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {},
        data: action.payload,
      }
    case successAction(SAVE_TOURNAMENT_DESCRIPTION):
      ({ desc } = action.payload);
      ({ data } = state)
      return {
        ...state,
        data: {
          ...data,
          desc,
        },
      }
    case failureAction(GET_TOURNAMENT):
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
        data: {},
      }
    case successAction(CLOSE_TOURNAMENT):
    case successAction(REOPEN_TOURNAMENT):
      return {
        ...state,
        data: {
          ...state.data,
          completed: action.payload,
        },
      }

    default:
      return state
  }
}

export default reducer
