import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import PropTypes from 'prop-types'
import { SimpleRow } from '../../common'

import { getSelectedOptions } from './TournamentSettingsEdit'

const TournamentSettings = ({
  settings,
}) => {
  const {
    tournament: {
      isPublic,
      maxHalfPointByes,
    },
    tournamentPlayer: {
      requiredFields = [],
    },
  } = settings
  const filedList = getSelectedOptions(requiredFields).map((item) => {
    const { label } = item
    return label
  })
  return (
    <>
      <Card>
        <CardHeader>
          <h4>Tournament rules</h4>
        </CardHeader>
        <CardBody>
          <SimpleRow {...{
            label: 'Max number of half point byes allowed',
            text: maxHalfPointByes,
            flex: true,
          }}
          />
        </CardBody>
      </Card>
      <Card className="mt-4">
        <CardHeader>
          <h4>Player registration</h4>
        </CardHeader>
        <CardBody>
          <SimpleRow {...{
            label: 'Open for public registration',
            text: isPublic ? 'Yes' : 'No',
            flex: true,
          }}
          />
          <SimpleRow {...{
            label: 'Required fields',
            text: filedList.length ? filedList.join(', ') : <span className="text-muted">Not specified</span>,
            flex: true,
          }}
          />
        </CardBody>
      </Card>
    </>
  )
}

TournamentSettings.propTypes = {
  settings: PropTypes.shape({
    tournament: PropTypes.shape({
      isPublic: PropTypes.bool.isRequired,
      maxHalfPointByes: PropTypes.number.isRequired,
    }),
    tournamentPlayer: PropTypes.shape({
      requiredFields: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
}

export default TournamentSettings
