import React from 'react'
import {
  Nav, NavItem, NavLink, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import {
  ROUTE_RELEASES, ROUTE_ISSUES, ROUTE_TOURNAMENTS,
} from '../../app/types'

const Menu = () => {
  return (
    <Nav navbar className="mr-2">
      <NavItem>
        <NavLink tag={Link} to={ROUTE_TOURNAMENTS}>Tournaments</NavLink>
      </NavItem>
      <UncontrolledDropdown nav>
        <DropdownToggle nav caret>
          What&apos;s new
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem tag={Link} to={ROUTE_RELEASES}>Releases</DropdownItem>
          <DropdownItem tag={Link} to={ROUTE_ISSUES}>Todos</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  )
}

export default Menu
