import React from 'react'
import { Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

const FormSubmitButtons = ({
  history,
  submitting,
}) => {
  return (
    <div className="d-flex flex-row justify-content-center">
      <div className="p-2">
        <Button type="submit" color="primary" className="my-2" disabled={submitting}>
          Save
        </Button>
      </div>
      <div className="p-2">
        <Button color="secondary" className="my-2" onClick={() => history.goBack()} disabled={submitting}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

FormSubmitButtons.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default withRouter(FormSubmitButtons)
