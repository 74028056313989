import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'

import { renderField } from '../../../libs/form'
import ReduxFormContext from '../../common/contexts/ReduxFormContext'
import { formatName } from '../../../libs/formatter'

const ForbiddenList = ({
  id, name,
}) => {
  return (
    <ReduxFormContext.Consumer>
      {({ change, formValues, docs }) => {
        const options = docs.filter((doc) => {
          const { id: playerId } = doc
          return playerId !== id
        }).map((doc) => {
          const { id: playerId, firstName, lastName } = doc
          return { value: playerId, label: formatName({ firstName, lastName }) }
        })
        return (
          <Field
            type="react-select"
            name={`forbiddenLists[${id}]`}
            component={renderField}
            isMulti
            placeholder={null}
            options={options}
            onChange={(value) => {
              const currForbiddenList = value === null || value === '' ? [] : value
              const currForbiddenIds = currForbiddenList.map((item) => {
                const { value: forbiddenId } = item
                return forbiddenId
              })
              const { forbiddenLists } = formValues
              const updatedForbiddenLists = Object.entries(forbiddenLists).map((item) => {
                const [tournamentPlayerId, list = []] = item
                const forbiddenList = list === null || list === '' ? [] : list
                const forbiddenIds = forbiddenList.map((i) => {
                  const { value: forbiddenId } = i
                  return forbiddenId
                })
                let updatedList
                if (tournamentPlayerId === id) {
                  // update current player's forbidden list
                  updatedList = [...currForbiddenList]
                } else if (currForbiddenIds.includes(tournamentPlayerId)
                  && !forbiddenIds.includes(id)) {
                  // add current player to each of his forbidden player's forbidden list, if not already added
                  updatedList = [...forbiddenList]
                  updatedList.push({ value: id, label: name })
                } else if (!currForbiddenIds.includes(tournamentPlayerId)) {
                  // remove current player from the forbidden list of the player,
                  // who is not in current player's forbidden list
                  updatedList = forbiddenList.filter((i) => {
                    const { value: forbiddenId } = i
                    return forbiddenId !== id
                  })
                } else {
                  updatedList = [...forbiddenList]
                }

                return { tournamentPlayerId, forbiddenList: updatedList }
              }).reduce((acc, item) => {
                const { tournamentPlayerId, forbiddenList } = item
                const result = acc
                result[tournamentPlayerId] = forbiddenList

                return result
              }, {})
              change('forbiddenLists', updatedForbiddenLists)
            }}
          />
        )
      }}
    </ReduxFormContext.Consumer>
  )
}

ForbiddenList.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default ForbiddenList
