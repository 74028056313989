import Http from './http'

class Release extends Http {
  async list() {
    const res = await this.request.get('/releases')
    return res.data
  }
}

export default Release
