import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import TournamentDescription from '../components/tournament-summary/TournamentDescription'
import { updateTournamentDescription, resetSaveTournament } from '../actions'
import { LoadingSpinner } from '../../common'

const TournamentDescriptionContainer = ({
  match: {
    params: { id },
  },
  accessToken,
  loading,
  loaded,
  saved,
  tournament,
  user: { id: userId = null },
  updateTournamentDescriptionConnect,
  resetSaveTournamentConnect,
}) => {
  const onSubmit = (data) => {
    return updateTournamentDescriptionConnect(accessToken, id, data)
  }

  const { desc } = tournament
  return (
    <>
      {loading && <LoadingSpinner />}
      {loaded && (
      <TournamentDescription
        onSubmit={onSubmit}
        initialValues={{ desc }}
        tournament={tournament}
        userId={userId}
        saved={saved}
        resetSaveTournamentConnect={resetSaveTournamentConnect}
      />
      )}
    </>
  )
}

TournamentDescriptionContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  accessToken: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    desc: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  updateTournamentDescriptionConnect: PropTypes.func.isRequired,
  resetSaveTournamentConnect: PropTypes.func.isRequired,
}

TournamentDescriptionContainer.defaultProps = {
  accessToken: null,
}

const mapStateToProps = (state) => {
  const {
    auth: {
      data: { accessToken },
    },
    tournament: {
      summary: {
        loading: loadingTournament,
        loaded: tournamentloaded,
        data: tournament,
      },
      edit: { saved },
    },
    user: {
      details: {
        data: user,
      },
    },
  } = state

  return {
    accessToken,
    loading: loadingTournament,
    loaded: tournamentloaded,
    saved,
    tournament,
    user,
  }
}

const mapDispatchToProps = {
  updateTournamentDescriptionConnect: updateTournamentDescription,
  resetSaveTournamentConnect: resetSaveTournament,
}


export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(TournamentDescriptionContainer))
