import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import { formatName, formatPoints } from '../../../libs/formatter'
import PairingRowMenu from '../containers/PairingRowMenuContainer'
import Result from '../containers/ResultContainer'
import RowNo from '../../tournament-player/components/RowNo'
import TournamentPlayerModal from '../../tournament-player/components/tournament-player-list-row/TournamentPlayerModal'

const PairingRow = ({
  pairing: {
    id, boardNo, white, black = {}, result,
  },
  isMyTournament,
  tournamentCompleted,
  userId,
}) => {
  const [isWhiteOpen, setIsWhiteOpen] = useState(false)
  const [isBlackOpen, setIsBlackOpen] = useState(false)

  const { createdBy: whiteCreatedBy } = white
  const { createdBy: blackCreatedBy = null } = black
  const isMyPairing = isMyTournament || (whiteCreatedBy === userId || blackCreatedBy === userId)
  const isWhiteMine = isMyTournament || whiteCreatedBy === userId
  const isBlackMine = isMyTournament || blackCreatedBy === userId
  const noOpponent = blackCreatedBy === null
  const canReport = isMyPairing && !tournamentCompleted && !noOpponent
  const { withdrawn = false } = white

  return (
    <tr>
      <RowNo rowNo={boardNo} withdrawn={noOpponent && withdrawn} />
      <td className="font-weight-less-bold font-italic">
        <Button
          color="link"
          onClick={() => { setIsWhiteOpen(true) }}
          className="p-0 border-0 align-top"
        >
          {formatName(white)}
        </Button>
        <TournamentPlayerModal
          tournamentPlayer={white}
          isMine={isWhiteMine}
          isOpen={isWhiteOpen}
          toggle={() => { setIsWhiteOpen(false) }}
        />
      </td>
      <td>{white.rating}</td>
      <td>{formatPoints(white.totalPoints)}</td>
      <td className="font-weight-less-bold font-italic">
        {noOpponent ? 'Bye' : (
          <>
            <Button
              color="link"
              onClick={() => { setIsBlackOpen(true) }}
              className="p-0 border-0 align-top"
            >
              {formatName(black)}
            </Button>
            <TournamentPlayerModal
              tournamentPlayer={black}
              isMine={isBlackMine}
              isOpen={isBlackOpen}
              toggle={() => { setIsBlackOpen(false) }}
            />
          </>
        )}
      </td>
      <td>{!noOpponent && black.rating}</td>
      <td>{!noOpponent && formatPoints(black.totalPoints)}</td>
      <td className="pt-2">{result ? <Result id={id} result={result} /> : ''}</td>
      <td className="w-5 px-0">
        {canReport && (
          <PairingRowMenu id={id} />
        )}
      </td>
    </tr>
  )
}

PairingRow.propTypes = {
  pairing: PropTypes.shape({
    id: PropTypes.string.isRequired,
    boardNo: PropTypes.number.isRequired,
    whiteId: PropTypes.string.isRequired,
    white: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      totalPoints: PropTypes.number.isRequired,
      rating: PropTypes.number,
      createdBy: PropTypes.string.isRequired,
      withdrawn: PropTypes.bool,
    }).isRequired,
    black: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      totalPoints: PropTypes.number,
      rating: PropTypes.number,
      createdBy: PropTypes.string,
    }),
    blackId: PropTypes.string,
    result: PropTypes.string,
  }).isRequired,
  isMyTournament: PropTypes.bool.isRequired,
  tournamentCompleted: PropTypes.bool.isRequired,
  userId: PropTypes.string,
}

PairingRow.defaultProps = {
  userId: null,
}

export default PairingRow
