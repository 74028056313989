import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import Wrapper from './Wrapper'

const PublicRoute = ({ component, path }) => {
  const render = (props) => {
    return (
      <Wrapper component={component} {...props} isPublic />
    )
  }

  return (
    <Route path={path} render={render} />
  )
}

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  path: PropTypes.string.isRequired,
}

export default PublicRoute
