import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { LoadingSpinner, NoResults } from '../../common'
import PairingListBody from '../components/PairingListBody'
import { getPairing } from '../actions'
import { getTournament } from '../../tournament/actions'

const PairingListBodyContainer = ({
  match: {
    params: { id },
  },
  loading,
  loaded,
  pairing: { docs },
  tournament,
  user,
  resultUpdated,
  getPairingConnect,
  getTournamentConnect,
}) => {
  useEffect(() => {
    if (resultUpdated) {
      getTournamentConnect(null, id)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [resultUpdated])

  useEffect(() => {
    if (id) {
      getPairingConnect(null, id)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [id])

  let body
  if (loading) {
    body = (
      <tr>
        <td>
          <LoadingSpinner />
        </td>
      </tr>
    )
  } else if (loaded) {
    if (docs.length) {
      const { id: userId = null } = user
      const { createdBy, completed = false } = tournament
      const isMyTournament = createdBy === userId

      body = (
        <PairingListBody
          docs={docs}
          isMyTournament={isMyTournament}
          tournamentCompleted={completed}
          userId={userId}
        />
      )
    } else {
      body = (
        <NoResults colSpan={9} text="Pairings have yet be created." />
      )
    }
  }
  return <tbody>{body}</tbody>
}

PairingListBodyContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  pairing: PropTypes.shape({
    docs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  tournament: PropTypes.shape({
    createdBy: PropTypes.string,
    completed: PropTypes.bool,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  resultUpdated: PropTypes.bool.isRequired,
  getPairingConnect: PropTypes.func.isRequired,
  getTournamentConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  tournamentPairing: {
    details: {
      loading: loadingPairing,
      loaded: pairingLoaded,
      data: pairing,
    },
    result: { updated: resultUpdated },
  },
  tournament: {
    summary: {
      loading: loadingTournament,
      loaded: tournamentLoaded,
      data: tournament,
    },
  },
  user: {
    details: {
      data: user,
    },
  },
}) => ({
  loading: loadingPairing || loadingTournament,
  loaded: pairingLoaded && tournamentLoaded,
  pairing,
  tournament,
  user,
  resultUpdated,
})

const mapDispatchToProps = {
  getPairingConnect: getPairing,
  getTournamentConnect: getTournament,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PairingListBodyContainer))
