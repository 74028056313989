import Http from './http'

class Standing extends Http {
  async get(tournamentId, params = {}) {
    const res = await this.request.get(`/tournaments/${tournamentId}/standing`, { params })
    return res.data
  }

  async getWallChart(tournamentId, params = {}) {
    const res = await this.request.get(`/tournaments/${tournamentId}/wall-chart`, { params })
    return res.data
  }
}

export default Standing
