import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ROUTE_LOGIN } from '../../app/types'

const LoginButton = ({ size }) => (
  <Button
    color="primary"
    tag={Link}
    to={ROUTE_LOGIN}
    size={size}
  >
    Log In
  </Button>
)

LoginButton.propTypes = {
  size: PropTypes.string,
}

LoginButton.defaultProps = {
  size: 'md',
}

export default LoginButton
