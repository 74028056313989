import React, { useEffect } from 'react'
import { Link, generatePath } from 'react-router-dom'
import { reduxForm } from 'redux-form'
import {
  Container, Button,
} from 'reactstrap'
import { GoArrowLeft } from 'react-icons/go'
import _ from 'lodash'
import PropTypes from 'prop-types'


import { Form } from '../../common'
import { bitArrayToList } from '../../../libs/convert'

import { ROUTE_TOURNAMENT_REGISTER } from '../../app/types'
import TournamentPlayer, { getForbiddenPlayers } from '../../tournament-player/components/TournamentPlayer'

const TournamentRegisterConfirm = ({
  destroy,
  submitting,
  handleSubmit,
  formValues,
  tournamentId,
  tournamentPlayers,
  doDestroy,
  cancelAddTournamentPlayerConnect,
  resetEditTournamentPlayerConnect,
}) => {
  const {
    firstName, lastName, rating, contact, byes, forbiddenList = [],
  } = formValues

  const forbiddenIds = _.isArray(forbiddenList) ? forbiddenList.map((item) => {
    const { value } = item
    return value
  }) : []

  const tournamentPlayer = {
    firstName,
    lastName,
    rating,
    contact,
    byes: bitArrayToList(byes),
    forbiddenPlayers: getForbiddenPlayers(tournamentPlayers, forbiddenIds),
  }

  useEffect(() => {
    if (doDestroy) {
      return () => {
        resetEditTournamentPlayerConnect()
        destroy()
      }
    }

    // eslint warning without anything returned
    return undefined
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [doDestroy])

  return (
    <Container>
      <Form onSubmit={handleSubmit} submitting={submitting}>
        <TournamentPlayer tournamentPlayer={tournamentPlayer} isMine />
        <div className="d-flex flex-row justify-content-center mt-5">
          <div className="p-2">
            <Button
              color="primary"
              tag={Link}
              to={generatePath(ROUTE_TOURNAMENT_REGISTER, { id: tournamentId })}
            >
              <GoArrowLeft style={{ fontSize: '1.5rem' }} />
              Back
            </Button>
          </div>
          <div className="p-2">
            <Button type="submit" color="primary" disabled={submitting}>
              Confirm registration
            </Button>
          </div>
          <div className="p-2">
            <Button
              color="secondary"
              onClick={() => {
                cancelAddTournamentPlayerConnect()
              }}
              disabled={submitting}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  )
}

TournamentRegisterConfirm.propTypes = {
  destroy: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    rating: PropTypes.string,
    contact: PropTypes.string,
    byes: PropTypes.array,
    forbiddenList: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
  }).isRequired,
  tournamentId: PropTypes.string.isRequired,
  tournamentPlayers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  })).isRequired,
  doDestroy: PropTypes.bool.isRequired,
  cancelAddTournamentPlayerConnect: PropTypes.func.isRequired,
  resetEditTournamentPlayerConnect: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'tournamentRegister',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(TournamentRegisterConfirm)
