import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentView from '../components/TournamentView'
import TournamentData from '../../common/containers/LoadDataByMatch'
import { getTournament } from '../actions'
import { LoadingSpinner } from '../../common'

const TournamentViewContainer = ({
  loading,
  loaded,
  tournament,
  user: { id: userId = null },
}) => {
  return (
    <>
      <TournamentData loadData={getTournament} />
      {loading && <LoadingSpinner />}
      {loaded && (
        <TournamentView
          tournament={tournament}
          userId={userId}
        />
      )}
    </>
  )
}

TournamentViewContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = ({
  tournament: {
    summary: {
      loading,
      loaded,
      data: tournament,
    },
  },
  user: {
    details: {
      data: user,
    },
  },
}) => {
  return {
    loading,
    loaded,
    tournament,
    user,
  }
}

export default connect(mapStateToProps)(TournamentViewContainer)
