import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import { withRouter, generatePath } from 'react-router-dom'
import {
  FormGroup, Card, CardBody, CardTitle, Label as ReactLabel,
} from 'reactstrap'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Label, Form, FormSubmitButtons } from '../../common'
import { renderField } from '../../../libs/form'
import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'

export const options = [
  { value: 'contact', label: 'Contact' },
  { value: 'rating', label: 'Rating' },
]
export const getSelectedOptions = (requiredFields) => {
  const lookup = {}
  options.forEach((item) => {
    const { value, label } = item
    lookup[value] = label
  })

  return requiredFields.filter((item) => {
    return !!lookup[item]
  }).map((item) => {
    return { value: item, label: lookup[item] }
  })
}

const TournamentSettingsEdit = ({
  history,
  handleSubmit,
  submitting,
  saved,
  tournament,
  resetSaveTournamentConnect,
}) => {
  useEffect(() => {
    if (saved) {
      const { id } = tournament
      history.push(generatePath(ROUTE_TOURNAMENT_VIEW, { id, tabId: 'settings' }))
      return () => {
        resetSaveTournamentConnect()
      }
    }

    return undefined
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saved])

  const { numRounds } = tournament
  return (
    <Form onSubmit={handleSubmit} submitting={submitting}>
      <Card color="light" className="border-0">
        <CardBody>
          <CardTitle>
            <h3 className="font-weight-light">Tournament rules</h3>
          </CardTitle>
          <FormGroup>
            <Label for="maxHalfPointByes" className="mb-0">Max number of half point byes allowed</Label>
            <p>
              <small className="text-muted">
                The maximum number of half point byes a player is allowed to take
              </small>
            </p>
            <Field
              type="number"
              id="maxHalfPointByes"
              name="tournament.maxHalfPointByes"
              component={renderField}
              min="0"
              max={numRounds}
            />
          </FormGroup>
        </CardBody>
      </Card>
      <Card color="light" className="border-0 mt-3">
        <CardBody>
          <CardTitle>
            <h3 className="font-weight-light">Player registration</h3>
          </CardTitle>
          <FormGroup>
            <ReactLabel for="isPublic">
              <span className="mr-3">
                Open for public registration
              </span>
              <p>
                <small className="text-muted">
                  Turn this off to allow registration by TD only
                </small>
              </p>
              <div className="mt-2">
                <Field
                  type="react-switch"
                  id="isPublic"
                  name="tournament.isPublic"
                  component={renderField}
                />
              </div>
            </ReactLabel>
          </FormGroup>
          <FormGroup>
            <Label for="requiredFields">Required fields</Label>
            <Field
              type="react-select"
              id="requiredFields"
              name="tournamentPlayer.requiredFields"
              component={renderField}
              isMulti
              placeholder="Please select the fields that you want to make required."
              options={options}
            />
          </FormGroup>
        </CardBody>
      </Card>
      <FormSubmitButtons submitting={submitting} />
    </Form>
  )
}

TournamentSettingsEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string.isRequired,
    numRounds: PropTypes.number.isRequired,
  }).isRequired,
  resetSaveTournamentConnect: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'tournamentConfigEdit',
})(withRouter(TournamentSettingsEdit))
