import {
  SAVE_TOURNAMENT,
  RESET_SAVE_TOURNAMENT,
  SAVE_TOURNAMENT_DESCRIPTION,
  SAVE_TOURNAMENT_SETTINGS,
  GET_TOURNAMENT,
  DELETE_TOURNAMENT,
  RESET_DELETE_TOURNAMENT,
  REOPEN_TOURNAMENT,
  EXPORT_TOURNAMENT,
  RESET_EXPORT_TOURNAMENT,
} from './types'
import { Tournament } from '../../apis'

export const addTournament = (accessToken, data) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.add(data)
  return tournament.d(func, SAVE_TOURNAMENT)
}

export const copyTournament = (accessToken, data, options = {}) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.copy(data, options)
  return tournament.d(func, SAVE_TOURNAMENT)
}

export const updateTournament = (accessToken, tournamentId, data) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.update(tournamentId, data)
  return tournament.d(func, SAVE_TOURNAMENT)
}

export const updateTournamentDescription = (accessToken, tournamentId, data) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.updateDescription(tournamentId, data)
  return tournament.d(func, SAVE_TOURNAMENT_DESCRIPTION)
}

export const updateTournamentSettings = (accessToken, tournamentId, data) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.updateSettings(tournamentId, data)
  return tournament.d(func, SAVE_TOURNAMENT_SETTINGS)
}

export const reopenTournament = (accessToken, tournamentId) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.reopen(tournamentId)
  return tournament.d(func, REOPEN_TOURNAMENT)
}

export const closeTournament = (accessToken, tournamentId) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.close(tournamentId)
  return tournament.d(func, REOPEN_TOURNAMENT)
}

export const resetSaveTournament = () => ({
  type: RESET_SAVE_TOURNAMENT,
})

export const getTournament = (accessToken, id) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.get(id)
  return tournament.d(func, GET_TOURNAMENT)
}

export const exportTournamentTrf16 = (accessToken, id) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.exportTrf16(id)
  return tournament.d(func, EXPORT_TOURNAMENT)
}

export const resetExportTournament = () => ({
  type: RESET_EXPORT_TOURNAMENT,
})

export const deleteTournament = (accessToken, id) => async (dispatch) => {
  const tournament = new Tournament(accessToken, dispatch)
  const func = async () => tournament.delete(id)
  return tournament.d(func, DELETE_TOURNAMENT)
}

export const resetDeleteTournament = () => ({
  type: RESET_DELETE_TOURNAMENT,
})
