import { failureAction, successAction, startAction } from '../../common'
import {
  DELETE_TOURNAMENT_PLAYER,
  RESET_DELETE_TOURNAMENT_PLAYER,
} from '../types'

export const initialState = {
  deleting: false,
  deleted: false,
  error: {},
}

const reducer = ({ delete: state }, action) => {
  switch (action.type) {
    case startAction(DELETE_TOURNAMENT_PLAYER):
      return {
        ...state,
        deleting: true,
        deleted: false,
        error: {},
      }
    case successAction(DELETE_TOURNAMENT_PLAYER):
      return {
        ...state,
        deleting: false,
        deleted: true,
        error: {},
      }
    case failureAction(DELETE_TOURNAMENT_PLAYER):
      return {
        ...state,
        deleting: false,
        deleted: false,
        error: action.payload,
      }

    case RESET_DELETE_TOURNAMENT_PLAYER:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
