import React from 'react'
import { BreadcrumbItem } from 'reactstrap'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { ROUTE_TOURNAMENT_PAIRING_CREATE, ROUTE_TOURNAMENT_PAIRING_EDIT } from '../../app/types'

const PairingEditBreadcrumbTitle = ({ tournament: { numRoundsCreated }, match: { path } }) => {
  let roundNo
  let title
  if (path === ROUTE_TOURNAMENT_PAIRING_CREATE) {
    roundNo = numRoundsCreated + 1
    title = `Create round ${roundNo} pairings`
  } else if (path === ROUTE_TOURNAMENT_PAIRING_EDIT) {
    roundNo = numRoundsCreated
    title = `Redo round ${roundNo} pairings`
  } else {
    throw new Error(`Unexpected path: ${path}.`)
  }

  return (
    <BreadcrumbItem active>
      {title}
    </BreadcrumbItem>
  )
}

PairingEditBreadcrumbTitle.propTypes = {
  tournament: PropTypes.shape({
    numRoundsCreated: PropTypes.number.isRequired,
  }).isRequired,
  match: ReactRouterPropTypes.match.isRequired,
}

export default PairingEditBreadcrumbTitle
