import React from 'react'
import PropTypes from 'prop-types'

import Release from './Release'

const Releases = ({ docs }) => {
  return (
    <>
      {docs.reduce((acc, doc) => {
        const { id, draft } = doc
        if (draft === false) {
          acc.push(<Release key={id} {...doc} />)
        }
        return acc
      }, [])}
    </>
  )
}

Releases.propTypes = {
  docs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    draft: PropTypes.bool.isRequired,
  })).isRequired,
}

export default Releases
