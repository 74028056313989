import React from 'react'
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu,
} from 'reactstrap'
import PropTypes from 'prop-types'

import ForfeitTooltip from './ForfeitTooltip'

const PairingRowMenu = ({ submitResult }) => {
  return (
    <UncontrolledButtonDropdown>
      <DropdownToggle caret>
        Report result
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={() => submitResult('1')}>White won</DropdownItem>
        <DropdownItem onClick={() => submitResult('=')}>draw</DropdownItem>
        <DropdownItem onClick={() => submitResult('0')}>Black won</DropdownItem>
        <DropdownItem divider />
        <div id="black-forfeited" className="d-flex cursor-help">
          <div>
            <DropdownItem onClick={() => submitResult('+')} className="pr-1">
              White won by forfeit
            </DropdownItem>
          </div>
          <ForfeitTooltip target="black-forfeited" />
        </div>
        <div id="white-forfeited" className="d-flex cursor-help">
          <div>
            <DropdownItem onClick={() => submitResult('-')} className="pr-1">
              Black won by forfeit
            </DropdownItem>
          </div>
          <ForfeitTooltip target="white-forfeited" />
        </div>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

PairingRowMenu.propTypes = {
  submitResult: PropTypes.func.isRequired,
}

export default PairingRowMenu
