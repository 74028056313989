import shortid from 'shortid'

import { ADD_ALERT, CLEAR_ALERTS } from './types'

export const addAlert = (text, props = {}) => {
  return {
    type: ADD_ALERT,
    payload: { id: shortid.generate(), text, props },
  }
}

export const clearAlerts = () => {
  return {
    type: CLEAR_ALERTS,
  }
}
