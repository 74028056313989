import React from 'react'
import { Field, FieldArray } from 'redux-form'
import {
  FormGroup, Label, Row, Col,
} from 'reactstrap'
import PropTypes from 'prop-types'

import { required, renderField } from '../../../libs/form'
import Byes from './Byes'
import ReduxFormContext from '../../common/contexts/ReduxFormContext'
import { formatName } from '../../../libs/formatter'

/**
 * Given a list of validating functions, and assuming first is the required function,
 * remove the required validator if field is not in requiredFields
 *
 * @param {*} functions
 * @param {*} requiredFields
 * @param {*} field
 */
const filterValidateFunctions = (functions, requiredFields, field) => {
  const [func, ...rest] = functions

  return requiredFields.includes(field) ? [func, ...rest] : [...rest]
}

const TournamentPlayerEditFields = ({
  requiredFields,
}) => {
  return (
    <>
      <Row>
        <Col>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Field type="text" id="firstName" name="firstName" component={renderField} validate={[required]} />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Field type="text" id="lastName" name="lastName" component={renderField} validate={[required]} />
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="rating">Rating</Label>
        <Field
          type="number"
          id="rating"
          name="rating"
          component={renderField}
          validate={filterValidateFunctions([required], requiredFields, 'rating')}
        />
      </FormGroup>
      <FormGroup>
        <Label for="contact">Contact</Label>
        <Field
          type="textarea"
          id="contact"
          name="contact"
          component={renderField}
          validate={filterValidateFunctions([required], requiredFields, 'contact')}
        />
      </FormGroup>
      <Label className="mb-0">Byes</Label>
      <p>
        <small className="text-muted">
          Bye request is subject to tournament rules and at the discretion of tournament director(s)
        </small>
      </p>
      <FieldArray name="byes" component={Byes} />
      <FormGroup>
        <Label for="forbiddenList" className="mb-0">Not paired with</Label>
        <p>
          <small className="text-muted">
            Avoid pairing request is subject to tournament rules and at the discretion of tournament director(s)
          </small>
        </p>
        <ReduxFormContext.Consumer>
          {({ tournamentPlayerId: id, tournamentPlayers: docs }) => {
            const options = docs.filter((doc) => {
              const { id: playerId } = doc
              return playerId !== id
            }).map((doc) => {
              const { id: playerId, firstName, lastName } = doc
              return { value: playerId, label: formatName({ firstName, lastName }) }
            })

            return (
              <Field
                type="react-select"
                id="forbiddenList"
                name="forbiddenList"
                component={renderField}
                isMulti
                placeholder={null}
                options={options}
              />
            )
          }}
        </ReduxFormContext.Consumer>
      </FormGroup>
    </>
  )
}

TournamentPlayerEditFields.propTypes = {
  requiredFields: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default TournamentPlayerEditFields
