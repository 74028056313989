import { failureAction, successAction, startAction } from '../../common'
import { ADD_OR_GET_USER } from '../types'

export const initialState = {
  loading: false,
  loaded: false,
  error: {},
  data: {},
}

const reducer = ({ details: state }, action) => {
  switch (action.type) {
    case startAction(ADD_OR_GET_USER):
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
        data: {},
      }
    case successAction(ADD_OR_GET_USER):
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {},
        data: action.payload,
      }
    case failureAction(ADD_OR_GET_USER):
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
        data: {},
      }
    default:
      return state
  }
}

export default reducer
