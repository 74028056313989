import React from 'react'
import { DropdownItem } from 'reactstrap'
import PropTypes from 'prop-types'

const CloseTournamentItem = ({ onClick }) => {
  return (
    <DropdownItem
      onClick={onClick}
    >
      Complete tournament
    </DropdownItem>
  )
}

CloseTournamentItem.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default CloseTournamentItem
