import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import PairingRowMenu from '../components/PairingRowMenu'
import { updateResult } from '../actions'
import { LoadingSpinner } from '../../common'

const PairingRowMenuContainer = ({
  id,
  accessToken,
  updateResultConnect,
}) => {
  const submitResult = (result) => {
    updateResultConnect(accessToken, id, { result })
  }

  const ready = id && accessToken
  return ready ? <PairingRowMenu submitResult={submitResult} /> : <LoadingSpinner />
}

PairingRowMenuContainer.propTypes = {
  id: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  updateResultConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: {
    data: { accessToken },
  },
}) => ({
  accessToken,
})

const mapDispatchToProps = {
  updateResultConnect: updateResult,
}

export default connect(mapStateToProps, mapDispatchToProps)(PairingRowMenuContainer)
