import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { doneUnknownError } from '../actions'
import { addAlert } from '../../common'

const UnknownErrorContainer = ({
  unknownError,
  doneUnknownErrorConnect,
  addAlertConnect,
}) => {
  useEffect(() => {
    if (unknownError) {
      const text = 'Sorry, an error has occured during login. Please try again.'
      addAlertConnect(text, { color: 'danger' })
      doneUnknownErrorConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [unknownError])

  return <></>
}

UnknownErrorContainer.propTypes = {
  unknownError: PropTypes.bool.isRequired,
  doneUnknownErrorConnect: PropTypes.func.isRequired,
  addAlertConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: { unknownError },
}) => ({
  unknownError,
})

const mapDispatchToProps = {
  doneUnknownErrorConnect: doneUnknownError,
  addAlertConnect: addAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(UnknownErrorContainer)
