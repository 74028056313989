import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import PropTypes from 'prop-types'

import config from '../../../config'

const RichEditor = ({
  onChange,
  initialValue,
}) => {
  const onEditorChange = (content) => {
    // console.log('Content was updated:', content)
    onChange(content)
  }

  return (
    <Editor
      apiKey={config.tinymce.apiKey}
      init={{
        height: 500,
        menubar: true,
        remove_linebreaks: true,
        menu: {
          file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
          edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
          view: {
            title: 'View',
            items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen',
          },
          insert: {
            title: 'Insert',
            items: 'codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime',
          },
          format: {
            title: 'Format',
            items: 'bold italic underline strikethrough superscript subscript codeformat | formats'
            + ' blockformats fontformats fontsizes align | forecolor backcolor | removeformat',
          },
          tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
          table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
          help: { title: 'Help', items: 'help' },
        },
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
              'undo redo | formatselect | bold italic forecolor backcolor | '
              + 'alignleft aligncenter alignright alignjustify | '
              + 'bullist numlist outdent indent | removeformat fullscreen  preview | help',
        toolbar_sticky: true,
        toolbar_mode: 'sliding',
      }}
      onEditorChange={onEditorChange}
      initialValue={initialValue}
    />
  )
}

RichEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
}

RichEditor.defaultProps = {
  initialValue: '',
}

export default RichEditor
