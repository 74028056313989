import React from 'react'
import PropTypes from 'prop-types'

import DeleteModal from '../../common/components/DeleteModal'

const DeletePairingsModal = ({
  roundNo, isOpen, toggle, confirmDelete,
}) => {
  const headerText = `Delete round ${roundNo} pairings?`
  const Body = () => {
    return (
      <>
        {`Are you sure you want to delete round ${roundNo} pairings?`}
        {' '}
        <span className="text-danger font-weight-less-bold">All reported results will be lost.</span>
      </>
    )
  }

  return (
    <DeleteModal {...{
      isOpen,
      headerText,
      Body,
      toggle,
      confirmDelete,
    }}
    />
  )
}

DeletePairingsModal.propTypes = {
  roundNo: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
}

export default DeletePairingsModal
