import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import StandingList from '../components/StandingList'
import { LoadingSpinner, LegendsModal } from '../../common'
import { getStanding } from '../actions'
import { resultMap } from '../../tournament-pairing/components/Result'

const StandingListContainer = ({
  match: {
    params: { id },
  },
  loading,
  loaded,
  standing: { roundNo, docs },
  getStandingConnect,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    if (id) {
      getStandingConnect(null, id)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [id])

  if (loading) {
    return <LoadingSpinner />
  }

  const symbols = ['U', 'F', 'H', 'Z', '1', '=', '0', '+', '-']
  const legends = []
  symbols.forEach((symbol) => {
    if (resultMap[symbol]) {
      const { desc } = resultMap[symbol]
      legends.push({ symbol, desc })
    }
  })

  return loaded ? (
    <>
      <div className="h5 d-flex flex-row">
        <div className="mr-3">
          {`Standings after round ${roundNo}`}
        </div>
        <div className="mr-3">
          <Button color="secondary" onClick={() => { setIsOpen(true) }}>Show legends</Button>
        </div>
      </div>
      <StandingList
        roundNo={roundNo}
        docs={docs}
      />
      <LegendsModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        legends={legends}
      />
    </>
  ) : null
}

StandingListContainer.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  standing: PropTypes.shape({
    roundNo: PropTypes.number,
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  getStandingConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  tournamentStanding: { loading, loaded, data: standing },
}) => ({
  loading,
  loaded,
  standing,
})

const mapDispatchToProps = {
  getStandingConnect: getStanding,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(StandingListContainer))
