import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'

import TournamentSettingsEdit, { getSelectedOptions } from '../components/TournamentSettingsEdit'
import { updateTournamentSettings, resetSaveTournament, getTournament } from '../actions'
import { LoadingSpinner } from '../../common'
import TournamentData from '../../common/containers/LoadDataByMatch'

const TournamentSettingsEditContainer = ({
  accessToken,
  loading,
  loaded,
  tournament,
  saved,
  updateTournamentSettingsConnect,
  resetSaveTournamentConnect,
}) => {
  const { id } = tournament
  const onSubmit = (data) => {
    const {
      tournamentPlayer: { requiredFields = [] },
    } = data
    const settings = {
      ...data,
      tournamentPlayer: {
        requiredFields: _.isArray(requiredFields) ? requiredFields.map((item) => {
          const { value } = item
          return value
        }) : [],
      },
    }
    return updateTournamentSettingsConnect(accessToken, id, { settings })
  }

  const getInitialValues = () => {
    const { settings } = tournament
    const {
      tournamentPlayer: {
        requiredFields,
      },
    } = settings
    return {
      ...settings,
      tournamentPlayer: {
        requiredFields: getSelectedOptions(requiredFields),
      },
    }
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      <TournamentData loadData={getTournament} />
      {loaded && (
        <TournamentSettingsEdit
          onSubmit={onSubmit}
          initialValues={getInitialValues()}
          saved={saved}
          tournamentId={id}
          tournament={tournament}
          resetSaveTournamentConnect={resetSaveTournamentConnect}
        />
      )}
    </>
  )
}

TournamentSettingsEditContainer.propTypes = {
  accessToken: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    settings: PropTypes.shape({
      tournament: PropTypes.shape({
        isPublic: PropTypes.bool,
      }),
      tournamentPlayer: PropTypes.shape({
        requiredFields: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }),
  saved: PropTypes.bool.isRequired,
  updateTournamentSettingsConnect: PropTypes.func.isRequired,
  resetSaveTournamentConnect: PropTypes.func.isRequired,
}

TournamentSettingsEditContainer.defaultProps = {
  accessToken: null,
  tournament: {},
}

const mapStateToProps = (state) => {
  const {
    auth: {
      data: { accessToken },
    },
    tournament: {
      summary: { loading, loaded, data: tournament },
      edit: { saved },
    },
  } = state

  return {
    accessToken,
    loading,
    loaded,
    tournament,
    saved,
  }
}

const mapDispatchToProps = {
  updateTournamentSettingsConnect: updateTournamentSettings,
  resetSaveTournamentConnect: resetSaveTournament,
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentSettingsEditContainer)
