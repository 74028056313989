import React, { useState } from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

import TournamentPlayerListBody from '../containers/TournamentPlayerListBodyContainer'
import TournamentPlayerDeleteModal from '../containers/TournamentPlayerDeleteModalContainer'
import DeleteConfirmContext from '../../tournament/contexts/DeleteConfirmContext'

const TournamentPlayerList = ({ loggedIn, userId }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [playerToDelete, setPlayerToDelete] = useState({ firstName: '', lastName: '' })

  return (
    <>
      <Table hover className="mt-3">
        <thead>
          <tr>
            <th>#</th>
            <th>First name</th>
            <th>Last name</th>
            <th>Rating</th>
            <th>Created at</th>
            <th>Created by</th>
            <th>{' '}</th>
          </tr>
        </thead>
        <DeleteConfirmContext.Provider value={{
          setIsOpen, setPlayerToDelete, userId,
        }}
        >
          <TournamentPlayerListBody />
        </DeleteConfirmContext.Provider>
      </Table>
      {loggedIn && (
      <TournamentPlayerDeleteModal
        tournamentPlayer={playerToDelete}
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
      />
      )}
    </>
  )
}

TournamentPlayerList.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  userId: PropTypes.string,
}

TournamentPlayerList.defaultProps = {
  userId: null,
}

export default TournamentPlayerList
