import { ROUTE_CALLBACK, ROUTE_LOGOUT } from '../modules/app/types'

const config = {
  auth: {
    callbackUrl: `${process.env.REACT_APP_DOMAIN}${ROUTE_CALLBACK}`,
    logoutUrl: `${process.env.REACT_APP_DOMAIN}${ROUTE_LOGOUT}`,
  },
  api: {
    baseUrl: process.env.REACT_APP_API_DOMAIN,
  },
  app: {
    domain: process.env.REACT_APP_DOMAIN,
  },
  tinymce: {
    apiKey: 'ejcb9pg1fchtsnkx9z3r4p4g8lerkl1r1zmkfujz29eiafbk',
  },
}

export default config
