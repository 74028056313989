import React from 'react'
import PropTypes from 'prop-types'

import DeleteModal from '../../common/components/DeleteModal'

const DeleteTournamentModal = ({
  name, isOpen, toggle, confirmDelete,
}) => {
  const headerText = 'Delete tournament?'
  const Body = () => {
    return (
      <>
        {'Are you sure you want to delete tournament '}
        <span className="font-weight-less-bold">{name}</span>
        ? All registered players will be deleted along with the tournament.
      </>
    )
  }

  return (
    <DeleteModal {...{
      isOpen,
      headerText,
      Body,
      toggle,
      confirmDelete,
    }}
    />
  )
}

DeleteTournamentModal.propTypes = {
  name: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
}

export default DeleteTournamentModal
