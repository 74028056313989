import React from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { Alert } from 'reactstrap'
import PropTypes from 'prop-types'

import TournamentEdit from '../components/TournamentEdit'
import { copyTournament, resetSaveTournament, getTournament } from '../actions'
import { LoadingSpinner } from '../../common'
import TournamentData from '../../common/containers/LoadDataByMatch'


const TournamentCopyContainer = ({
  accessToken,
  loading,
  loaded,
  tournament,
  saved,
  newTournamentId,
  formValues,
  copyTournamentConnect,
  resetSaveTournamentConnect,
}) => {
  const onSubmit = (data) => {
    const { copyTournamentPlayers = {} } = data
    const { value: doCopy = false } = copyTournamentPlayers
    const { id: fromTournamentId } = tournament
    const options = doCopy ? { fromTournamentId } : {}
    return copyTournamentConnect(accessToken, data, options)
  }

  const { name } = tournament
  return (
    <>
      {loading && <LoadingSpinner />}
      <TournamentData loadData={getTournament} />
      {loaded && (
        <Alert color="info">
          The tournament you are creating is copied from
          {' '}
          <span className="font-weight-less-bold">{name}</span>
          .
        </Alert>
      )}
      {loaded && (
        <TournamentEdit
          {...{
            onSubmit,
            initialValues: tournament,
            saved,
            tournamentId: newTournamentId,
            fromTournament: tournament,
            formValues,
            resetSaveTournamentConnect,
          }}
        />
      )}
    </>
  )
}

TournamentCopyContainer.propTypes = {
  accessToken: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    numRoundsCreated: PropTypes.number,
  }),
  saved: PropTypes.bool.isRequired,
  newTournamentId: PropTypes.string,
  formValues: PropTypes.shape({
    startOn: PropTypes.string,
    endOn: PropTypes.string,
  }).isRequired,
  copyTournamentConnect: PropTypes.func.isRequired,
  resetSaveTournamentConnect: PropTypes.func.isRequired,
}

TournamentCopyContainer.defaultProps = {
  accessToken: null,
  tournament: {},
  newTournamentId: null,
}

const mapStateToProps = (state) => {
  const {
    auth: {
      data: { accessToken },
    },
    tournament: {
      summary: { loading, loaded, data: tournament },
      edit: { saved, data: { id: newTournamentId = null } },
    },
  } = state

  return {
    accessToken,
    loading,
    loaded,
    tournament,
    saved,
    newTournamentId,
    formValues: getFormValues('tournamentEdit')(state) || {},
  }
}

const mapDispatchToProps = {
  copyTournamentConnect: copyTournament,
  resetSaveTournamentConnect: resetSaveTournament,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentCopyContainer)
