import React from 'react'
import PropTypes from 'prop-types'

const NoResults = ({ colSpan, text }) => {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="d-flex justify-content-center">
          <h6 className="text-info">{text}</h6>
        </div>
      </td>
    </tr>
  )
}

NoResults.propTypes = {
  colSpan: PropTypes.number.isRequired,
  text: PropTypes.string,
}

NoResults.defaultProps = {
  text: 'No results found.',
}

export default NoResults
