import Http from './http'

class User extends Http {
  // This special API creates user if it does not exist
  // Load user if it already exists (authId matches)
  async addOrGetUser(data) {
    const res = await this.request.post('/users', data)
    return res.data
  }
}

export default User
