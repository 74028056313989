import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import 'chess-tour-style/dist/css/chess-tour-style.css'
import { store, persistor } from '../../../store'
import Routes from './Routes'
import { Callback, Logout, Login } from '../../auth'
import { Tournaments, TournamentsTabs } from '../../tournaments'
import {
  TournamentCreate,
  TournamentEdit,
  TournamentCopy,
  TournamentSettingsEdit,
  TournamentView,
  TournamentRegisterEdit,
  TournamentRegisterConfirm,
} from '../../tournament'
import { PairingCreate, PairingEdit } from '../../tournament-pairing'
import { TournamentPlayerEdit, TournamentPlayer } from '../../tournament-player'

import { Terms, Privacy } from '../../navbar'
import { Home } from '../../home'
import { Releases } from '../../releases'
import { Issues } from '../../issues'
import {
  ROUTE_HOME,
  ROUTE_RELEASES,
  ROUTE_ISSUES,
  ROUTE_CALLBACK,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_TERMS,
  ROUTE_PRIVACY,
  ROUTE_TOURNAMENTS,
  ROUTE_TOURNAMENTS_VIEW,
  ROUTE_TOURNAMENT_CREATE,
  ROUTE_TOURNAMENTS_VIEW_ALL,
  ROUTE_TOURNAMENT_VIEW,
  ROUTE_TOURNAMENT_EDIT,
  ROUTE_TOURNAMENT_COPY,
  ROUTE_TOURNAMENT_CONFIG_EDIT,
  ROUTE_TOURNAMENT_REGISTER,
  ROUTE_TOURNAMENT_REGISTER_CONFIRM,
  ROUTE_TOURNAMENT_PLAYER_VIEW,
  ROUTE_TOURNAMENT_PLAYER_EDIT,
  ROUTE_TOURNAMENT_PAIRING_CREATE,
  ROUTE_TOURNAMENT_PAIRING_EDIT,
  ROUTE_REDIRECT,
} from '../types'

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Routes>
        <Route path={ROUTE_HOME} component={Home} exact isPublic withContainer={false} />
        <Route path={ROUTE_RELEASES} component={Releases} exact isPublic />
        <Route path={ROUTE_ISSUES} component={Issues} exact isPublic withContainer={false} />
        <Route path={ROUTE_CALLBACK} component={Callback} isPublic />
        <Route path={ROUTE_LOGIN} component={Login} isPublic />
        <Route path={ROUTE_LOGOUT} component={Logout} isPublic />
        <Route path={ROUTE_TERMS} component={Terms} isPublic />
        <Route path={ROUTE_PRIVACY} component={Privacy} isPublic />
        <Route path={ROUTE_TOURNAMENTS} component={Tournaments} isPublic exact />
        <Route path={ROUTE_TOURNAMENT_CREATE} component={TournamentCreate} exact />
        <Route path={ROUTE_TOURNAMENTS_VIEW_ALL} component={TournamentsTabs} isPublic exact />
        <Route path={ROUTE_TOURNAMENTS_VIEW} component={TournamentsTabs} exact />
        <Route path={ROUTE_TOURNAMENT_REGISTER} component={TournamentRegisterEdit} exact />
        <Route path={ROUTE_TOURNAMENT_REGISTER_CONFIRM} component={TournamentRegisterConfirm} exact />
        <Route path={ROUTE_TOURNAMENT_EDIT} component={TournamentEdit} exact />
        <Route path={ROUTE_TOURNAMENT_COPY} component={TournamentCopy} exact />
        <Route path={ROUTE_TOURNAMENT_CONFIG_EDIT} component={TournamentSettingsEdit} exact />
        <Route path={ROUTE_TOURNAMENT_VIEW} component={TournamentView} isPublic exact />
        <Route path={ROUTE_TOURNAMENT_PLAYER_EDIT} component={TournamentPlayerEdit} exact />
        <Route path={ROUTE_TOURNAMENT_PLAYER_VIEW} component={TournamentPlayer} isPublic exact />
        <Route path={ROUTE_TOURNAMENT_PAIRING_CREATE} component={PairingCreate} exact />
        <Route path={ROUTE_TOURNAMENT_PAIRING_EDIT} component={PairingEdit} exact />
        <Redirect to={ROUTE_REDIRECT} />
      </Routes>
    </PersistGate>
  </Provider>
)

export default App
