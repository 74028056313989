import React from 'react'
import { Input, FormFeedback, FormText } from 'reactstrap'
import Select from 'react-select'
import Switch from 'react-switch'
import PropTypes from 'prop-types'

import { RichEditor } from '../modules/common'

const renderTextField = ({
  component: Component, input, meta: { touched, error, warning }, ...custom
}) => {
  const invalid = !!(touched && error)
  return (
    <>
      <Component invalid={invalid} {...input} {...custom} />
      {error && <FormFeedback>{error}</FormFeedback>}
      {!error && warning && <FormText>{warning}</FormText>}
    </>
  )
}

renderTextField.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
    warning: PropTypes.string,
  }).isRequired,
}

const renderRadioField = ({ value, input, ...custom }) => (
  <Input type="radio" checked={value === input.value} {...input} {...custom} />
)

renderRadioField.propTypes = {
  value: PropTypes.string.isRequired,
  input: PropTypes.shape({
    value: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
}

const renderCheckbox = ({ input, ...custom }) => {
  const { value } = input
  return (
    <Input type="checkbox" checked={!!value} {...input} {...custom} />
  )
}

renderCheckbox.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
}

const renderSelectField = ({
  input, meta: { touched, error }, children, ...custom
}) => (
  <Input type="select" {...(touched ? { valid: !error } : {})} {...input} {...custom}>
    {children}
  </Input>
)

renderSelectField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
}

const renderReactSelectField = ({
  input: { value, onChange, onBlur },
  onChange: customOnChange = null,
  ...custom
}) => {
  return (
    <Select
      classNamePrefix="react-select"
      value={value}
      onChange={customOnChange === null ? onChange : (v) => customOnChange(v)}
      onBlur={() => onBlur()}
      {...custom}
    />
  )
}

renderReactSelectField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }),
      PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })),
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  onChange: PropTypes.func,
}

renderReactSelectField.defaultProps = {
  onChange: null,
}

const renderReactSwitchField = ({
  input: { value, onChange },
  ...custom
}) => {
  return (
    <Switch
      checked={value}
      onChange={onChange}
      className="react-switch align-bottom bg-primary"
      onColor="#007bff"
      {...custom}
    />
  )
}

renderReactSwitchField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.bool,
    onChange: PropTypes.func,
  }).isRequired,
}

const renderRichEditorField = ({
  input: { value = '', onChange },
  ...custom
}) => {
  return (
    <RichEditor
      initialValue={value}
      onChange={onChange}
      {...custom}
    />
  )
}

renderRichEditorField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
}

const renderField = ({ type, sub: component, ...rest }) => {
  // switch
  switch (type) {
    case 'text':
      return renderTextField({ component: Input, ...rest })
    case 'number':
      return renderTextField({ component: Input, type: 'number', ...rest })
    case 'textarea':
      return renderTextField({ component: Input, type: 'textarea', ...rest })
    case 'radio':
      return renderRadioField({ ...rest })
    case 'checkbox':
      return renderCheckbox({ ...rest })
    case 'select':
      return renderSelectField({ ...rest })
    case 'react-select':
      return renderReactSelectField({ ...rest })
    case 'rich-editor':
      return renderRichEditorField({ ...rest })
    case 'react-switch':
      return renderReactSwitchField({ ...rest })
    default:
      if (component) {
        return renderTextField({ component, ...rest })
      }
      throw new Error('Invalid type.')
  }
}

renderField.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'textarea',
    'radio',
    'checkbox',
    'select',
    'react-select',
    'rich-editor',
    'react-switch',
  ]),
  sub: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
}

renderField.defaultProps = {
  type: null,
  sub: null,
}

export default renderField
