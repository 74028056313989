export const SAVE_TOURNAMENT = 'SAVE_TOURNAMENT'
export const RESET_SAVE_TOURNAMENT = 'RESET_SAVE_TOURNAMENT'
export const SAVE_TOURNAMENT_DESCRIPTION = 'SAVE_TOURNAMENT_DESCRIPTION'
export const SAVE_TOURNAMENT_SETTINGS = 'SAVE_TOURNAMENT_SETTINGS'
export const GET_TOURNAMENT = 'GET_TOURNAMENT'
export const DELETE_TOURNAMENT = 'DELETE_TOURNAMENT'
export const RESET_DELETE_TOURNAMENT = 'RESET_DELETE_TOURNAMENT'
export const CLOSE_TOURNAMENT = 'CLOSE_TOURNAMENT'
export const REOPEN_TOURNAMENT = 'REOPEN_TOURNAMENT'
export const EXPORT_TOURNAMENT = 'EXPORT_TOURNAMENT'
export const RESET_EXPORT_TOURNAMENT = 'RESET_EXPORT_TOURNAMENT'
