import { failureAction, successAction, startAction } from '../../common'
import { GET_PAIRING, UPDATE_RESULT } from '../types'

export const initialState = {
  loading: false,
  loaded: false,
  error: {},
  data: {},
}

const reducer = ({ details: state }, action) => {
  const {
    data: { roundNo, docs },
  } = state
  const data = action.payload

  switch (action.type) {
    case startAction(GET_PAIRING):
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
        data: {},
      }
    case successAction(GET_PAIRING):
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {},
        data: action.payload,
      }
    case failureAction(GET_PAIRING):
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
        data: {},
      }
    case successAction(UPDATE_RESULT):
      return {
        ...state,
        data: {
          roundNo,
          docs: docs.map((doc) => {
            if (data.id === doc.id) {
              const { updatedAt, updatedBy, result } = data
              return {
                ...doc,
                updatedAt,
                updatedBy,
                result,
              }
            }
            return doc
          }),
        },
      }
    default:
      return state
  }
}

export default reducer
