import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentSettings from '../components/TournamentSettings'
import { LoadingSpinner } from '../../common'

const TournamentSettingsContainer = ({
  loading,
  loaded,
  tournament: {
    settings,
  },
}) => {
  return (
    <>
      {loading && <LoadingSpinner />}
      {loaded && (
        <TournamentSettings settings={settings} />
      )}
    </>
  )
}

TournamentSettingsContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    settings: PropTypes.shape({
      tournamentPlayer: PropTypes.shape({
        requiredFields: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }).isRequired,
}

const mapStateToProps = (state) => {
  const {
    tournament: {
      summary: { loading, loaded, data: tournament },
    },
  } = state

  return {
    loading,
    loaded,
    tournament,
  }
}

export default connect(mapStateToProps)(TournamentSettingsContainer)
