/* eslint-disable import/prefer-default-export */
import _ from 'lodash'
import { bitArrayToList } from '../../libs/convert'

export const submit = (data, callback) => {
  const { byes, forbiddenList, ...rest } = data
  return callback({
    ...rest,
    byes: bitArrayToList(byes),
    forbiddenIds: _.isArray(forbiddenList) ? forbiddenList.map((item) => {
      const { value } = item
      return value
    }) : [],
  })
}
