import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { clearAlerts } from '../actions'
import AppAlerts from '../components/AppAlerts'

const AppAlertsContainer = ({ clearAlertsConnect, alerts, location: { pathname } }) => {
  useEffect(() => {
    if (alerts.length) {
      clearAlertsConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pathname])

  return (
    <AppAlerts alerts={alerts} />
  )
}

AppAlertsContainer.propTypes = {
  location: ReactRouterPropTypes.location.isRequired,
  ...AppAlerts.propTypes,
  clearAlertsConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({ alerts }) => ({
  alerts,
})

const mapDispatchToProps = {
  clearAlertsConnect: clearAlerts,
}

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(AppAlertsContainer))
