import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import IssuesColumn from '../components/IssuesColumn'
import { LoadingSpinner } from '../../common'

const DoneContainer = ({
  loading,
  loaded,
  numResults,
  docs,
}) => {
  return (
    <>
      {loading && <LoadingSpinner />}
      {loaded && <IssuesColumn title="Completed" numResults={numResults} docs={docs} />}
    </>
  )
}

DoneContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  numResults: PropTypes.number.isRequired,
  docs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

const mapStateToProps = ({
  issues: { done: { loading, loaded, data: { numResults, docs } } },
}) => ({
  loading,
  loaded,
  numResults,
  docs,
})

export default connect(mapStateToProps)(DoneContainer)
