import {
  GET_TOURNAMENT_PLAYERS,
  GET_TOURNAMENT_PLAYER,
  ADD_TOURNAMENT_PLAYER,
  UPDATE_TOURNAMENT_PLAYER,
  DELETE_TOURNAMENT_PLAYER,
  RESET_DELETE_TOURNAMENT_PLAYER,
  CANCEL_ADD_TOURNAMENT_PLAYER,
  RESET_ADD_TOURNAMENT_PLAYER,
  WITHDRAW_TOURNAMENT_PLAYER,
  REENTER_TOURNAMENT_PLAYER,
  RESET_WITHDRAW_TOURNAMENT_PLAYER,
} from './types'
import { TournamentPlayer } from '../../apis'

export const listTournamentPlayers = (accessToken, tournamentId) => async (dispatch) => {
  const tournamentPlayer = new TournamentPlayer(accessToken, dispatch)
  const func = async () => tournamentPlayer.list(tournamentId)
  return tournamentPlayer.d(func, GET_TOURNAMENT_PLAYERS)
}

export const getTournamentPlayer = (accessToken, tournamentPlayerId) => async (dispatch) => {
  const tournamentPlayer = new TournamentPlayer(accessToken, dispatch)
  const func = async () => tournamentPlayer.get(tournamentPlayerId)
  return tournamentPlayer.d(func, GET_TOURNAMENT_PLAYER)
}

export const deleteTournamentPlayer = (accessToken, tournamentPlayerId) => async (dispatch) => {
  const tournamentPlayer = new TournamentPlayer(accessToken, dispatch)
  const func = async () => tournamentPlayer.delete(tournamentPlayerId)
  return tournamentPlayer.d(func, DELETE_TOURNAMENT_PLAYER)
}

export const withdrawTournamentPlayer = (accessToken, tournamentPlayerId) => async (dispatch) => {
  const tournamentPlayer = new TournamentPlayer(accessToken, dispatch)
  const func = async () => tournamentPlayer.withdraw(tournamentPlayerId)
  return tournamentPlayer.d(func, WITHDRAW_TOURNAMENT_PLAYER)
}

export const reenterTournamentPlayer = (accessToken, tournamentPlayerId) => async (dispatch) => {
  const tournamentPlayer = new TournamentPlayer(accessToken, dispatch)
  const func = async () => tournamentPlayer.reenter(tournamentPlayerId)
  return tournamentPlayer.d(func, REENTER_TOURNAMENT_PLAYER)
}

export const addTournamentPlayer = (accessToken, tournamentId, data) => async (dispatch) => {
  const tournamentPlayer = new TournamentPlayer(accessToken, dispatch)
  const func = async () => tournamentPlayer.add(tournamentId, data)
  return tournamentPlayer.d(func, ADD_TOURNAMENT_PLAYER)
}

export const updateTournamentPlayer = (accessToken, tournamentPlayerId, data) => async (dispatch) => {
  const tournamentPlayer = new TournamentPlayer(accessToken, dispatch)
  const func = async () => tournamentPlayer.update(tournamentPlayerId, data)
  return tournamentPlayer.d(func, UPDATE_TOURNAMENT_PLAYER)
}

export const resetEditTournamentPlayer = () => ({
  type: RESET_ADD_TOURNAMENT_PLAYER,
})

export const cancelAddTournamentPlayer = () => ({
  type: CANCEL_ADD_TOURNAMENT_PLAYER,
})

export const resetDeleteTournamentPlayer = () => ({
  type: RESET_DELETE_TOURNAMENT_PLAYER,
})

export const resetWithdrawTournamentPlayer = () => ({
  type: RESET_WITHDRAW_TOURNAMENT_PLAYER,
})
