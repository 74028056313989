import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import fileDownload from 'js-file-download'
import shortid from 'shortid'
import PropTypes from 'prop-types'

import TournamentViewButtons from '../components/TournamentViewButtons'
import {
  LoadingSpinner, SavingSpinner, addAlert, clearAlerts,
} from '../../common'
import DeletePairingsModal from './DeletePairingsModalContainer'
import DeleteTournamentModal from './DeleteTournamentModalContainer'
import CloseTournamentModal from './CloseTournamentModalContainer'
import ReopenTournamentModal from './ReopenTournamentModalContainer'
import { resetExportTournament } from '../actions'

const TournamentViewButtonsContainer = ({
  loading,
  loaded,
  tournament,
  user: { id: userId },
  exporting,
  exported,
  trf16,
  resetExportTournamentConnect,
  addAlertConnect,
  clearAlertsConnect,
}) => {
  const { createdBy, completed = false } = tournament
  const isMine = userId === createdBy

  const [isOpenDeletePairingsModal, setIsOpenDeletePairingsModal] = useState(false)
  const [isOpenDeleteTournamentModal, setIsOpenDeleteTournamentModal] = useState(false)
  const [isOpenCloseTournamentModal, setIsOpenCloseTournamentModal] = useState(false)
  const [isOpenReopenTournamentModal, setIsOpenReopenTournamentModal] = useState(false)

  useEffect(() => {
    if (exported) {
      const fileName = `fide_${shortid.generate()}.txt`
      fileDownload(trf16, fileName, 'text/plain')
      addAlertConnect(
        `The tournament has been exported to ${fileName}. Downloading should have started automatically.`,
      )
      resetExportTournamentConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [exported])

  useEffect(() => {
    if (exporting) {
      clearAlertsConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [exporting])

  return isMine && (
    <>
      {loading && (<LoadingSpinner />)}
      {exporting && (<SavingSpinner />)}
      {loaded && (
        <>
          <TournamentViewButtons
            tournament={tournament}
            toggleDeletePairingsModal={() => setIsOpenDeletePairingsModal(true)}
            toggleDeleteTournamentModal={() => setIsOpenDeleteTournamentModal(true)}
            toggleCloseTournamentModal={() => setIsOpenCloseTournamentModal(true)}
            toggleReopenTournamentModal={() => setIsOpenReopenTournamentModal(true)}
          />
          {!completed && (
            <DeletePairingsModal
              isOpen={isOpenDeletePairingsModal}
              toggle={() => setIsOpenDeletePairingsModal(false)}
            />
          )}
          <DeleteTournamentModal
            isOpen={isOpenDeleteTournamentModal}
            toggle={() => setIsOpenDeleteTournamentModal(false)}
          />
          <CloseTournamentModal
            isOpen={isOpenCloseTournamentModal}
            toggle={() => setIsOpenCloseTournamentModal(false)}
          />
          <ReopenTournamentModal
            isOpen={isOpenReopenTournamentModal}
            toggle={() => setIsOpenReopenTournamentModal(false)}
          />
        </>
      )}
    </>
  )
}

TournamentViewButtonsContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    numRoundsCreated: PropTypes.number,
    createdBy: PropTypes.string,
    completed: PropTypes.bool,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  exporting: PropTypes.bool.isRequired,
  exported: PropTypes.bool.isRequired,
  trf16: PropTypes.string.isRequired,
  addAlertConnect: PropTypes.func.isRequired,
  clearAlertsConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  tournament: {
    summary: {
      loading: loadingTournament,
      loaded: tournamentLoaded,
      data: tournament,
    },
    export: {
      exporting,
      exported,
      data: trf16,
    },
  },
  user: {
    details: {
      loading: loadingUser,
      loaded: userLoaded,
      data: user,
    },
  },
}) => {
  return {
    loading: loadingTournament || loadingUser,
    loaded: tournamentLoaded && userLoaded,
    tournament,
    user,
    exporting,
    exported,
    trf16,
  }
}

const mapDispatchToProps = {
  resetExportTournamentConnect: resetExportTournament,
  addAlertConnect: addAlert,
  clearAlertsConnect: clearAlerts,
}

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(TournamentViewButtonsContainer))
