import React from 'react'
import PropTypes from 'prop-types'

import { SimpleRow, Textarea } from '../../common'
import { formatName } from '../../../libs/formatter'

export const getForbiddenPlayers = (tournamentPlayers, forbiddenIds) => {
  return tournamentPlayers.filter((item) => {
    const { id } = item
    return forbiddenIds.includes(id)
  })
}

const TournamentPlayer = ({
  tournamentPlayer,
  isMine,
  flex,
}) => {
  const {
    firstName, lastName, contact, rating, byes, forbiddenPlayers,
  } = tournamentPlayer
  const byesText = byes.length ? `Round ${byes.join(', ')}` : 'N/a'

  return (
    <>
      <SimpleRow {...{ label: 'First name', text: firstName, flex }} />
      <SimpleRow {...{ label: 'Last name', text: lastName, flex }} />
      <SimpleRow {...{ label: 'Rating', text: rating, flex }} />
      {isMine && (
        <>
          <SimpleRow {...{
            label: 'Contact',
            text: <Textarea text={contact} />,
            flex,
          }}
          />
          <SimpleRow {...{ label: 'Byes', text: byesText, flex }} />
          <SimpleRow
            {...{
              label: 'Not paired with',
              text: forbiddenPlayers.length ? forbiddenPlayers.map((item) => {
                return formatName(item)
              }).join(', ') : 'N/a',
              flex,
            }}
          />
        </>
      )}
    </>
  )
}

TournamentPlayer.propTypes = {
  tournamentPlayer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contact: PropTypes.string,
    byes: PropTypes.arrayOf(PropTypes.number),
    forbiddenPlayers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })),
  }).isRequired,
  isMine: PropTypes.bool.isRequired,
  flex: PropTypes.bool,
}

TournamentPlayer.defaultProps = {
  flex: false,
}

export default TournamentPlayer
