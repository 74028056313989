import React from 'react'

const Logout = () => {
  return (
    <div className="mt-5 d-flex justify-content-center">
      <h3 className="font-italic">You have been logged out.</h3>
    </div>
  )
}

export default Logout
