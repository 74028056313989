import React from 'react'
import { Alert } from 'reactstrap'
import PropTypes from 'prop-types'

import AppAlert from './AppAlert'

const AppAlerts = ({ alerts }) => {
  return (
    <>
      {alerts.map((item) => {
        const { id, text, props = {} } = item
        return <AppAlert key={id} text={text} props={props} />
      })}
    </>
  )
}

AppAlerts.propTypes = {
  alerts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    props: PropTypes.shape(Alert.propTypes),
  })).isRequired,
}

export default AppAlerts
