import detailsReducer, { initialState as detailsState } from './detailsReducer'
import deleteReducer, { initialState as deleteState } from './deleteReducer'
import resultReducer, { initialState as resultState } from './resultReducer'
import editReducer, { initialState as editState } from './editReducer'

export const initialState = {
  details: detailsState,
  delete: deleteState,
  result: resultState,
  edit: editState,
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        details: detailsReducer(state, action),
        delete: deleteReducer(state, action),
        result: resultReducer(state, action),
        edit: editReducer(state, action),
      }
  }
}
