import { startAction } from '../common'
import {
  GET_PAIRING,
  ADD_PAIRING,
  UPDATE_PAIRING,
  RESET_EDIT_PAIRING,
  DELETE_PAIRING,
  RESET_DELETE_PAIRING,
  UPDATE_RESULT,
  RESET_UPDATE_RESULT,
} from './types'
import { Pairing } from '../../apis'

export const getPairing = (accessToken, tournamentId) => async (dispatch) => {
  const pairing = new Pairing(accessToken, dispatch)
  const func = async () => pairing.get(tournamentId)
  return pairing.d(func, GET_PAIRING)
}

export const addPairing = (accessToken, tournamentId, data) => async (dispatch) => {
  const pairing = new Pairing(accessToken, dispatch)
  const func = async () => pairing.add(tournamentId, data)
  return pairing.d(func, ADD_PAIRING)
}

export const updatePairing = (accessToken, tournamentId, data) => async (dispatch) => {
  const pairing = new Pairing(accessToken, dispatch)
  const func = async () => pairing.update(tournamentId, data)
  return pairing.d(func, UPDATE_PAIRING)
}

export const resetEditPairing = () => ({
  type: RESET_EDIT_PAIRING,
})

export const deletePairing = (accessToken, tournamentId) => async (dispatch) => {
  const pairing = new Pairing(accessToken, dispatch)
  const func = async () => pairing.delete(tournamentId)
  return pairing.d(func, DELETE_PAIRING)
}

export const resetDeletePairing = () => ({
  type: RESET_DELETE_PAIRING,
})

export const updateResult = (accessToken, id, data) => async (dispatch) => {
  const pairing = new Pairing(accessToken, dispatch)
  const func = async () => pairing.updateResult(id, data)
  dispatch({ type: startAction(UPDATE_RESULT), payload: id })
  return pairing.d(func, UPDATE_RESULT, { start: false, success: true, error: true })
}

export const resetUpdateResult = () => ({
  type: RESET_UPDATE_RESULT,
})
