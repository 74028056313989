import React from 'react'
import { GoInfo } from 'react-icons/go'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Info = ({ id, className }) => {
  const classes = className === '' ? [] : className.split(' ')
  return <GoInfo id={id} className={classNames('cursor-help', ...classes)} />
}

Info.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
}

Info.defaultProps = {
  id: '',
  className: '',
}

export default Info
