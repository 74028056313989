import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import TournamentPlayerListBody from '../components/TournamentPlayerListBody'
import { listTournamentPlayers, resetWithdrawTournamentPlayer } from '../actions'
import { LoadingSpinner, LoadDataByArgs as TournamentPlayersData } from '../../common'

const TournamentPlayerListBodyContainer = ({
  match: {
    params: { id },
  },
  accessToken,
  loading,
  loaded,
  tournamentPlayerDeleted,
  tournamentPlayerStatusSaved,
  listTournamentPlayersConnect,
  resetWithdrawTournamentPlayerConnect,
  ...rest
}) => {
  useEffect(() => {
    if (id && tournamentPlayerDeleted) {
      listTournamentPlayersConnect(accessToken, id)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [id, tournamentPlayerDeleted])

  useEffect(() => {
    if (tournamentPlayerStatusSaved) {
      resetWithdrawTournamentPlayerConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [tournamentPlayerStatusSaved])

  return (
    <tbody>
      <TournamentPlayersData loadData={listTournamentPlayers} args={[id]} />
      {loading && (
        <tr>
          <td>
            <LoadingSpinner />
          </td>
        </tr>
      )}
      {loaded && (
        <TournamentPlayerListBody {...rest} />
      )}
    </tbody>
  )
}

TournamentPlayerListBodyContainer.propTypes = {
  accessToken: PropTypes.string,
  listTournamentPlayersConnect: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournamentPlayerDeleted: PropTypes.bool.isRequired,
  tournamentPlayerStatusSaved: PropTypes.bool.isRequired,
  resetWithdrawTournamentPlayerConnect: PropTypes.func.isRequired,
}

TournamentPlayerListBodyContainer.defaultProps = {
  accessToken: null,
}

const mapStateToProps = ({
  auth: {
    data: { accessToken },
  },
  tournament: {
    summary: { loading: loadingTournament, loaded: tournamentLoaded, data: tournament },
    players: { loading: loadingPlayers, loaded: playersLoaded, data: docs },
  },
  tournamentPlayer: {
    delete: { deleted: tournamentPlayerDeleted },
    withdraw: { saved: tournamentPlayerStatusSaved },
  },
}) => ({
  accessToken,
  loading: loadingTournament || loadingPlayers,
  loaded: tournamentLoaded && playersLoaded,
  docs,
  tournament,
  tournamentPlayerDeleted,
  tournamentPlayerStatusSaved,
})

const mapDispatchToProps = {
  listTournamentPlayersConnect: listTournamentPlayers,
  resetWithdrawTournamentPlayerConnect: resetWithdrawTournamentPlayer,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TournamentPlayerListBodyContainer))
