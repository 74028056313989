import { failureAction, successAction, startAction } from '../common'
import { GET_WALL_CHART } from './types'

export const initialState = {
  loading: false,
  loaded: false,
  error: {},
  data: {},
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case startAction(GET_WALL_CHART):
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
        data: {},
      }
    case successAction(GET_WALL_CHART):
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {},
        data: action.payload,
      }
    case failureAction(GET_WALL_CHART):
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
        data: {},
      }
    default:
      return state
  }
}

export default reducer
