import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import {
  isValid, parseISO, formatRFC3339,
} from 'date-fns'
import _ from 'lodash'
import PropTypes from 'prop-types'

import 'react-datepicker/dist/react-datepicker.css'
import { DATE_TIME_FORMAT, DATE_FORMAT } from '../../../libs/formatter'
import DatePickerInput from './DatePickerInput'

const setDate = (date, setState, onChange) => {
  if (date === null || isValid(date)) {
    setState(date)
    onChange(date === null ? null : formatRFC3339(date))
  }
}

const DatePickerWrapper = (props) => {
  const {
    id,
    minDate,
    maxDate,
    showTime,
    // from redux-form
    input: { onChange, value: date },
  } = props

  const timeProps = showTime ? {
    showTimeSelect: true,
    timeFormat: 'HH:mm',
    timeIntervals: 15,
    timeCaption: 'time',
    dateFormat: DATE_TIME_FORMAT,
  } : { dateFormat: DATE_FORMAT }
  const additionalProps = {
    ...timeProps,
    ...(
      _.omitBy({
        minDate: minDate === null ? null : parseISO(minDate),
        maxDate: maxDate === null ? null : parseISO(maxDate),
      }, _.isNull)
    ),
  }

  const [state, setState] = useState()
  useEffect(() => {
    const newDate = date === null || date === '' ? null : parseISO(date)
    setDate(newDate, setState, onChange)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [date])

  return (
    <DatePicker
      customInput={<DatePickerInput inputId={id} />}
      selected={state}
      onChange={(value) => {
        setDate(value, setState, onChange)
      }}
      {...additionalProps}
    />
  )
}

DatePickerWrapper.propTypes = {
  id: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  showTime: PropTypes.bool,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
}

DatePickerWrapper.defaultProps = {
  id: null,
  minDate: null,
  maxDate: null,
  showTime: false,
}

export default DatePickerWrapper
