import React from 'react'
import { connect } from 'react-redux'
import { getFormValues, SubmissionError } from 'redux-form'
import PropTypes from 'prop-types'

import TournamentEdit from '../components/TournamentEdit'
import { updateTournament, resetSaveTournament, getTournament } from '../actions'
import { LoadingSpinner } from '../../common'
import TournamentData from '../../common/containers/LoadDataByMatch'

const TournamentCreateContainer = ({
  accessToken,
  loading,
  loaded,
  tournament,
  saved,
  formValues,
  updateTournamentConnect,
  resetSaveTournamentConnect,
}) => {
  const { id, numRoundsCreated } = tournament
  const onSubmit = (data) => {
    const { numRounds } = data
    if (numRounds < numRoundsCreated) {
      throw new SubmissionError({
        numRounds: 'Number of rounds is less than rounds already created.',
      })
    }
    return updateTournamentConnect(accessToken, id, data)
  }

  return (
    <>
      {loading && <LoadingSpinner />}
      <TournamentData loadData={getTournament} />
      {loaded && (
        <TournamentEdit
          {...{
            onSubmit,
            initialValues: tournament,
            saved,
            tournamentId: id,
            formValues,
            resetSaveTournamentConnect,
          }}
        />
      )}
    </>
  )
}

TournamentCreateContainer.propTypes = {
  accessToken: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    numRoundsCreated: PropTypes.number,
  }),
  saved: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({
    startOn: PropTypes.string,
    endOn: PropTypes.string,
  }).isRequired,
  updateTournamentConnect: PropTypes.func.isRequired,
  resetSaveTournamentConnect: PropTypes.func.isRequired,
}

TournamentCreateContainer.defaultProps = {
  accessToken: null,
  tournament: {},
}

const mapStateToProps = (state) => {
  const {
    auth: {
      data: { accessToken },
    },
    tournament: {
      summary: { loading, loaded, data: tournament },
      edit: { saved },
    },
  } = state

  return {
    accessToken,
    loading,
    loaded,
    tournament,
    saved,
    formValues: getFormValues('tournamentEdit')(state) || {},
  }
}

const mapDispatchToProps = {
  updateTournamentConnect: updateTournament,
  resetSaveTournamentConnect: resetSaveTournament,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentCreateContainer)
