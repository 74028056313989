import React from 'react'
import PropTypes from 'prop-types'

import ActionModal from '../../common/components/ActionModal'

const CloseTournamentModal = ({
  tournament: { name },
  isOpen,
  toggle,
  confirmUpdate,
}) => {
  const headerText = 'Mark tournament as completed?'
  const Body = () => {
    return (
      <>
        {'Are you sure you want to mark tournament '}
        <span className="font-weight-less-bold">{`${name} `}</span>
        as completed? Once marked as completed, no further changes can be made to it.
        If you wish to make changes after marking the tournament as completed, simply reopen it.
      </>
    )
  }

  return (
    <ActionModal {...{
      isOpen,
      headerText,
      Body,
      toggle,
      confirmAction: confirmUpdate,
    }}
    />
  )
}

CloseTournamentModal.propTypes = {
  tournament: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  confirmUpdate: PropTypes.func.isRequired,
}

export default CloseTournamentModal
