import React from 'react'
import {
  Container,
  Navbar,
  NavbarBrand,
} from 'reactstrap'
import { Link } from 'react-router-dom'

import LoginButton from '../containers/LoginButtonContainer'
import ProfileButton from '../containers/ProfileButtonContainer'
import PrivateComponent from '../../common/components/PrivateComponent'
import { ROUTE_HOME } from '../../app/types'
import Menu from './Menu'

const NB = () => {
  return (
    <Navbar color="light" light expand className="border-bottom">
      <NavbarBrand tag={Link} to={ROUTE_HOME}>
        <img src="/chess-tour_200.png" alt="Chess Tour" style={{ position: 'relative', left: '-2px' }} />
      </NavbarBrand>
      <Container className="d-flex justify-content-end">
        <Menu />
        <PrivateComponent component={ProfileButton} />
        <LoginButton />
      </Container>
    </Navbar>
  )
}

export default NB
