import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Logout from '../components/Logout'
import { logout } from '../actions'

const LogoutContainer = ({ logoutConnect }) => {
  useEffect(() => {
    logoutConnect()
  })

  return <Logout />
}

LogoutContainer.propTypes = {
  logoutConnect: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  logoutConnect: logout,
}

export default connect(null, mapDispatchToProps)(LogoutContainer)
