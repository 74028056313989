import { GET_BREADCRUMB, GET_MATCH } from './types'

export const initialState = {
  items: { items: [], buttons: null },
  match: {
    loading: false,
    loaded: false,
    data: {},
  },
}

const breadcrumbReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BREADCRUMB:
      return {
        ...state,
        items: action.payload.items,
      }
    case GET_MATCH:
      return {
        ...state,
        match: {
          loading: false,
          loaded: true,
          data: action.payload.match,
        },
      }
    default:
      return state
  }
}

export default breadcrumbReducer
