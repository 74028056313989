import React from 'react'
import Pagination from 'react-js-pagination'
import PropTypes from 'prop-types'

const Paginator = ({
  pagination: { count },
  activePage,
  setActivePage,
  onChange,
}) => {
  return (
    <Pagination
      itemClass="page-item"
      linkClass="page-link"
      activePage={activePage}
      itemsCountPerPage={10}
      totalItemsCount={count}
      pageRangeDisplayed={5}
      onChange={(p) => {
        setActivePage(p)
        onChange(p)
      }}
    />
  )
}

Paginator.propTypes = {
  pagination: PropTypes.shape({
    count: PropTypes.number,
  }).isRequired,
  activePage: PropTypes.number.isRequired,
  setActivePage: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Paginator
