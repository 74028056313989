import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { addAlert } from '../../common/actions'
import ErrorBoundary from '../components/ErrorBoundary'

class ErrorBoundaryContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
    const { addAlertConnect } = this.props
    addAlertConnect('Sorry, an unknown error has occured.', { color: 'danger' })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    return (
      <ErrorBoundary hasError={hasError}>
        {children}
      </ErrorBoundary>
    )
  }
}

ErrorBoundaryContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  addAlertConnect: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  addAlertConnect: addAlert,
}

export default connect(null, mapDispatchToProps)(ErrorBoundaryContainer)
