import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import Result from '../components/Result'
import { LoadingSpinner } from '../../common'
import { resetUpdateResult } from '../actions'

const ResultContainer = ({
  match: {
    params: { id: tournamentId },
  },
  id,
  result,
  accessToken,
  updating,
  updated,
  resetUpdateResultConnect,
}) => {
  useEffect(() => {
    if (accessToken && tournamentId && updated) {
      resetUpdateResultConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [accessToken, tournamentId, updated])

  return updating === id ? <LoadingSpinner /> : <Result id={id} result={result} />
}

ResultContainer.propTypes = {
  ...Result.propTypes,
  updating: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  updated: PropTypes.bool.isRequired,
  resetUpdateResultConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: {
    data: { accessToken },
  },
  tournamentPairing: {
    result: { updating, updated },
  },
}) => ({
  accessToken,
  updating,
  updated,
})

const mapDispatchToProps = {
  resetUpdateResultConnect: resetUpdateResult,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ResultContainer))
