/**
 * Given [true, false, true]
 * Return [1, 3]
 *
 * @param {*} bitArray
 */
export const bitArrayToList = (bitArray = []) => {
  const list = []
  bitArray.forEach((item, index) => {
    if (item) {
      list.push(index + 1)
    }
  })
  return list
}

/**
 * Given [1, 3], 5
 * Return [1, 0, 1, 0, 0]
 *
 * @param {*} list
 * @param {*} length
 */
export const listToBitArray = (list = [], length) => {
  const bitArray = []
  for (let i = 1; i <= length; i++) {
    bitArray.push(list.includes(i) ? 1 : 0)
  }
  return bitArray
}

export const bitObjectToList = (bitObject = {}) => {
  const list = []
  Object.entries(bitObject).forEach((item) => {
    list.push(item[0])
  })
  return list
}
