import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Alert, Button } from 'reactstrap'
import PropTypes from 'prop-types'

import PairingList from '../components/PairingList'
import { LoadingSpinner, LegendsModal } from '../../common'
import { resultMap } from '../components/Result'

const PairingListContainer = ({ loggedIn, roundNo }) => {
  const [isOpen, setIsOpen] = useState(false)
  const symbols = ['U', 'F', 'H', 'Z', '+', '-']
  const legends = []
  symbols.forEach((symbol) => {
    if (resultMap[symbol]) {
      const { result, desc } = resultMap[symbol]
      legends.push({ symbol: result, desc })
    }
  })

  return (
    <>
      {!loggedIn && (
        <Alert color="info" className="text-center">
          Please note, you are not logged in. To report your game result, please first login.
        </Alert>
      )}
      <div className="h5 d-flex flex-row">
        <div>
          {!roundNo ? <LoadingSpinner cssNames="text-left" /> : `Round ${roundNo} pairings`}
        </div>
        <div className="ml-3">
          <Button color="secondary" onClick={() => { setIsOpen(true) }}>Show legends</Button>
        </div>
      </div>
      <PairingList />
      <LegendsModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        legends={legends}
      />
    </>
  )
}

PairingListContainer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  roundNo: PropTypes.number,
}

PairingListContainer.defaultProps = {
  roundNo: null,
}

const mapStateToProps = ({
  auth: { loggedIn },
  tournamentPairing: {
    details: { data: { roundNo } },
  },
}) => ({
  loggedIn,
  roundNo,
})

export default connect(mapStateToProps)(PairingListContainer)
