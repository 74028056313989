import Http from './http'

class Pairing extends Http {
  async add(tournamentId, data) {
    const res = await this.request.post(`/tournaments/${tournamentId}/nextRound`, data)
    return res.data
  }

  async get(tournamentId) {
    const res = await this.request.get(`/tournaments/${tournamentId}/lastRound`)
    return res.data
  }

  async update(tournamentId, data) {
    const res = await this.request.put(`/tournaments/${tournamentId}/lastRound`, data)
    return res.data
  }

  async delete(tournamentId) {
    const res = await this.request.delete(`/tournaments/${tournamentId}/lastRound`)
    return res.data
  }

  async updateResult(id, data) {
    const res = await this.request.put(`/tournament-round-results/${id}`, data)
    return res.data
  }
}

export default Pairing
