import { failureAction, successAction, startAction } from '../../common'
import {
  GET_TOURNAMENT_PLAYER,
  WITHDRAW_TOURNAMENT_PLAYER,
  REENTER_TOURNAMENT_PLAYER,
  RESET_WITHDRAW_TOURNAMENT_PLAYER,
} from '../types'

export const initialState = {
  loading: false,
  loaded: false,
  error: {},
  data: {},
}

const reducer = ({ details: state }, action) => {
  const { data } = state
  let withdrawn
  switch (action.type) {
    case startAction(GET_TOURNAMENT_PLAYER):
      return {
        ...state,
        loading: true,
        loaded: false,
        error: {},
        data: {},
      }
    case successAction(WITHDRAW_TOURNAMENT_PLAYER):
    case successAction(REENTER_TOURNAMENT_PLAYER):
      ({ withdrawn } = action.payload)
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {},
        data: {
          ...data,
          withdrawn,
        },
      }
    case RESET_WITHDRAW_TOURNAMENT_PLAYER:
      return {
        ...state,
        loaded: false,
      }
    case successAction(GET_TOURNAMENT_PLAYER):
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {},
        data: action.payload,
      }
    case failureAction(GET_TOURNAMENT_PLAYER):
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload,
        data: {},
      }
    default:
      return state
  }
}

export default reducer
