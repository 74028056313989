import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import TournamentPlayer, { getForbiddenPlayers } from '../components/TournamentPlayer'
import { listTournamentPlayers, getTournamentPlayer, resetWithdrawTournamentPlayer } from '../actions'
import { getTournament } from '../../tournament/actions'
import {
  LoadDataByMatch as TournamentPlayerData,
  LoadDataByArgs as TournamentData,
  LoadDataByArgs as TournamentPlayersData,
  LoadingSpinner,
  addAlert,
  clearAlerts,
} from '../../common'
import { formatName } from '../../../libs/formatter'

const TournamentPlayerContainer = ({
  loading,
  loaded,
  additionalDataLoaded,
  saving,
  saved,
  withdrawn,
  tournament,
  tournamentPlayer,
  tournamentPlayers,
  user: { id: userId = null },
  resetWithdrawTournamentPlayerConnect,
  addAlertConnect,
  clearAlertsConnect,
}) => {
  useEffect(() => {
    return () => {
      clearAlertsConnect()
      resetWithdrawTournamentPlayerConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    if (saving) {
      clearAlertsConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saving])

  useEffect(() => {
    if (saved) {
      const text = withdrawn
        ? `You have sucessfully withdrawn ${formatName(tournamentPlayer)} from the tournament.`
        : `You have sucessfully reentered ${formatName(tournamentPlayer)} into the tournament.`
      addAlertConnect(text)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saved])

  const {
    tournamentId,
    forbiddenIds,
    createdBy: playerCreatedBy,
  } = tournamentPlayer

  const {
    createdBy: tournamentCreatedBy,
  } = tournament
  const isMyTournament = userId === tournamentCreatedBy
  const isMyPlayer = userId === playerCreatedBy
  const isMine = isMyTournament || isMyPlayer

  return (
    <>
      {loaded ? (
        <TournamentData loadData={getTournament} args={[tournamentId]} />
      ) : null}
      {loaded ? (
        <TournamentPlayersData loadData={listTournamentPlayers} args={[tournamentId]} />
      ) : null}
      <TournamentPlayerData loadData={getTournamentPlayer} />
      {loading && <LoadingSpinner />}
      {loaded && additionalDataLoaded && (
        <TournamentPlayer
          tournamentPlayer={{
            ...tournamentPlayer,
            forbiddenPlayers: getForbiddenPlayers(tournamentPlayers, forbiddenIds),
          }}
          isMine={isMine}
          flex
        />
      )}
    </>
  )
}

TournamentPlayerContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  additionalDataLoaded: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  withdrawn: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    createdBy: PropTypes.string,
  }).isRequired,
  tournamentPlayer: PropTypes.shape({
    tournamentId: PropTypes.string,
    forbiddenIds: PropTypes.arrayOf(PropTypes.string),
    createdBy: PropTypes.string,
  }).isRequired,
  tournamentPlayers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
  ).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  resetWithdrawTournamentPlayerConnect: PropTypes.func.isRequired,
  addAlertConnect: PropTypes.func.isRequired,
  clearAlertsConnect: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {
    tournament: {
      summary: {
        loading: loadingTournament,
        loaded: tournamentLoaded,
        data: tournament,
      },
      players: {
        loading: loadingTournamentPlayers,
        loaded: tournamentPlayersLoaded,
        data: tournamentPlayers,
      },
    },
    tournamentPlayer: {
      details: {
        loading: loadingTournamentPlayer,
        loaded: tournamentPlayerloaded,
        data: tournamentPlayer,
      },
      withdraw: { saving, saved, data: { withdrawn = false } },
    },
    user: {
      details: {
        data: user,
      },
    },
  } = state

  return {
    loading: loadingTournamentPlayer || loadingTournament || loadingTournamentPlayers,
    loaded: tournamentPlayerloaded,
    additionalDataLoaded: tournamentLoaded && tournamentPlayersLoaded,
    saving,
    saved,
    withdrawn,
    tournament,
    tournamentPlayers,
    tournamentPlayer,
    user,
  }
}

const mapDispatchToProps = {
  resetWithdrawTournamentPlayerConnect: resetWithdrawTournamentPlayer,
  addAlertConnect: addAlert,
  clearAlertsConnect: clearAlerts,
}

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(TournamentPlayerContainer))
