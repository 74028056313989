import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

import { Navbar, Breadcrumb, Footer } from '../../navbar'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import WithContainer from './WithContainer'
import ErrorBoundary from '../containers/ErrorBoundaryContainer'

const Routes = ({ children }) => (
  <BrowserRouter>
    <div className="content">
      <Navbar />
      <Breadcrumb />
      <Switch>
        {children.map((elem, i) => {
          if (elem.type !== Route) {
            return elem
          }
          const {
            isPublic, withContainer = true, component: Component, ...rest
          } = elem.props
          const routeProps = {
            ...rest,
            key: `route-${i}`,
            component: () => (
              <WithContainer withContainer={withContainer}>
                <ErrorBoundary>
                  <Component />
                </ErrorBoundary>
              </WithContainer>
            ),
          }
          return isPublic === true ? <PublicRoute {...routeProps} /> : <PrivateRoute {...routeProps} />
        })}
      </Switch>
    </div>
    <Footer />
  </BrowserRouter>
)

Routes.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default Routes
