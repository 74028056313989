import React from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

import PairingPlayerRow from './PairingPlayerRow'

const PairingPlayerList = ({ docs }) => {
  return (
    <Table hover className="mt-3">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Rating</th>
          <th>Points</th>
          <th>Bye</th>
          <th>Not paired with</th>
        </tr>
      </thead>
      <tbody>
        {docs.map((doc, index) => {
          const { id } = doc
          const playerNo = index + 1
          return <PairingPlayerRow key={id} doc={doc} playerNo={playerNo} />
        })}
      </tbody>
    </Table>
  )
}

PairingPlayerList.propTypes = {
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default PairingPlayerList
