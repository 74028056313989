import React from 'react'
import {
  Card, CardHeader, CardBody,
} from 'reactstrap'
import classNames from 'classnames'
import shortid from 'shortid'
import PropTypes from 'prop-types'

const AdditionalInfo = ({ tournament }) => {
  const {
    numRounds, timeControl, contact = '',
  } = tournament
  const contactList = contact === '' ? [] : contact.split('\n')
  return (
    <Card>
      <CardHeader>
        <h4>Key info</h4>
      </CardHeader>
      <CardBody>
        <div>
          <span className="font-weight-less-bold">Number of rounds:</span>
          {` ${numRounds}`}
        </div>
        <div>
          <span className="font-weight-less-bold">Time control:</span>
          {` ${timeControl}`}
        </div>
        <div className="d-flex flex-row">
          <div className="font-weight-less-bold">Contact:</div>
          <div className="flex-grow-1 ml-2">
            {contactList.length > 0 ? contactList.map((item) => {
              return <div key={shortid.generate()} className={classNames({ 'mt-3': item === '' })}>{item}</div>
            }) : (
              <div className="text-muted">
                Oops... the TD is too lazy to leave any contact info.
              </div>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

AdditionalInfo.propTypes = {
  tournament: PropTypes.shape({
    numRounds: PropTypes.number,
    timeControl: PropTypes.string,
    contact: PropTypes.string,
  }).isRequired,
}

export default AdditionalInfo
