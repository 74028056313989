import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Breadcrumb from '../components/Breadcrumb'
import { getBreadcrumb } from '../actions'
import { breadcrumbItems, mapItem } from '../breadcrumbItems'

const BreadcrumbContainer = (props) => {
  const { match, getBreadcrumbConnect } = props
  const { loaded, data } = match

  useEffect(() => {
    if (loaded) {
      let items = { items: [] }
      if (data.path in breadcrumbItems) {
        ({ items } = breadcrumbItems[data.path])
        const { buttons } = breadcrumbItems[data.path]
        items = { items: items.map(mapItem), buttons }
      }
      getBreadcrumbConnect(items)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [data.url])

  const { items } = props
  return <Breadcrumb items={items} />
}

BreadcrumbContainer.propTypes = {
  getBreadcrumbConnect: PropTypes.func.isRequired,
  match: PropTypes.shape({
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  ...Breadcrumb.propTypes,
}

const mapStateToProps = ({ breadcrumb: { items, match } }) => ({
  items,
  match,
})

const mapDispatchToProps = {
  getBreadcrumbConnect: getBreadcrumb,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BreadcrumbContainer)
