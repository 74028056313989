import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { GiCrossedSwords } from 'react-icons/gi'
import {
  UncontrolledTooltip,
} from 'reactstrap'
import PropTypes from 'prop-types'

const TournamentStatus = ({ tournament }) => {
  const {
    id,
    completed,
  } = tournament
  const infoId = `status-${id}`
  return (
    <div className="cursor-help" style={{ fontSize: '1.5rem' }}>
      {completed
        ? <FaCheckCircle id={infoId} className="text-success" />
        : <GiCrossedSwords id={infoId} className="text-primary" />}
      <UncontrolledTooltip placement="left" target={infoId}>
        {completed ? 'Completed' : 'In progress'}
      </UncontrolledTooltip>
    </div>
  )
}

TournamentStatus.propTypes = {
  tournament: PropTypes.shape({
    id: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
  }).isRequired,
}

export default TournamentStatus
