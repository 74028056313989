import React from 'react'
import {
  Nav, TabContent, TabPane,
  Alert,
} from 'reactstrap'
import { withRouter, generatePath } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'

import { NavTab } from '../../common'
import TournamentSettings from '../containers/TournamentSettingsContainer'
import TournamentSummary from '../containers/TournamentSummaryContainer'
import { PairingList } from '../../tournament-pairing'
import { StandingList } from '../../tournament-standing'
import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'
import TournamentPlayerList from '../../tournament-player/containers/TournamentPlayerListContainer'
import TournamentRegisterButton from '../containers/TournamentRegisterButtonContainer'
import { WallChart } from '../../tournament-wall-chart'

const TournamentView = ({
  match: {
    params: { id, tabId },
  },
  tournament,
  userId,
}) => {
  const {
    completed: tournamentCompleted = false,
    numRoundsCreated,
    numRounds,
    createdBy,
    settings: {
      tournament: {
        isPublic,
      },
    },
  } = tournament
  const pairingCompleted = numRoundsCreated === numRounds
  const isMine = userId === createdBy

  return (
    <div>
      {!isPublic && !isMine && (
        <Alert color="info">
          The tournament is not open for public registration. Please contact the TD if you have further questions.
        </Alert>
      )}
      {tournamentCompleted && (
        <Alert color="info">
          The tournament has completed.
          If you want to make a change to a registered player or a previously reported result,
          please contact the tournament director.
        </Alert>
      )}
      {!tournamentCompleted && pairingCompleted && (
        <Alert color="info">
          Last round pairing has been created. Registration is closed.
        </Alert>
      )}
      {isPublic && !tournamentCompleted && !pairingCompleted && <TournamentRegisterButton />}
      <Nav tabs className="mb-4">
        {isMine && (
          <NavTab
            currentTab="settings"
            activeTab={tabId}
            text="Settings"
            to={generatePath(ROUTE_TOURNAMENT_VIEW, { id, tabId: 'settings' })}
          />
        )}
        <NavTab
          currentTab="summary"
          activeTab={tabId}
          text="Summary"
          to={generatePath(ROUTE_TOURNAMENT_VIEW, { id, tabId: 'summary' })}
        />
        <NavTab
          currentTab="players"
          activeTab={tabId}
          text="Registered players"
          to={generatePath(ROUTE_TOURNAMENT_VIEW, { id, tabId: 'players' })}
        />
        <NavTab
          currentTab="pairings"
          activeTab={tabId}
          text="Pairings"
          to={generatePath(ROUTE_TOURNAMENT_VIEW, { id, tabId: 'pairings' })}
        />
        <NavTab
          currentTab="standings"
          activeTab={tabId}
          text="Standings"
          to={generatePath(ROUTE_TOURNAMENT_VIEW, { id, tabId: 'standings' })}
        />
        <NavTab
          currentTab="wallChart"
          activeTab={tabId}
          text="Wall chart"
          to={generatePath(ROUTE_TOURNAMENT_VIEW, { id, tabId: 'wallChart' })}
        />
      </Nav>
      <TabContent activeTab={tabId}>
        {isMine && (
          <TabPane tabId="settings">
            {tabId === 'settings' && <TournamentSettings />}
          </TabPane>
        )}
        <TabPane tabId="summary">
          {tabId === 'summary' && <TournamentSummary />}
        </TabPane>
        <TabPane tabId="players">
          {tabId === 'players' && <TournamentPlayerList />}
        </TabPane>
        <TabPane tabId="pairings">
          {tabId === 'pairings' && <PairingList />}
        </TabPane>
        <TabPane tabId="standings">
          {tabId === 'standings' && <StandingList />}
        </TabPane>
        <TabPane tabId="wallChart">
          {tabId === 'wallChart' && <WallChart />}
        </TabPane>
      </TabContent>
    </div>
  )
}

TournamentView.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  tournament: PropTypes.shape({
    settings: PropTypes.shape({
      tournament: PropTypes.shape({
        isPublic: PropTypes.bool,
      }),
    }),
    createdBy: PropTypes.string,
    numRounds: PropTypes.number,
    numRoundsCreated: PropTypes.number,
    completed: PropTypes.bool,
  }).isRequired,
  userId: PropTypes.string.isRequired,
}

export default withRouter(TournamentView)
