import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const PlaceholderImage = ({ name, cssNames }) => {
  const base = [
    'rounded-circle',
    'bg-primary',
    'avatar',
    'text-white',
    'text-center',
    'p-2',
  ]

  return (
    <div
      className={classNames(
        ...base,
        ...cssNames,
      )}
    >
      {name.substr(0, 1)}
    </div>
  )
}

PlaceholderImage.propTypes = {
  name: PropTypes.string.isRequired,
  cssNames: PropTypes.arrayOf(PropTypes.string),
}

PlaceholderImage.defaultProps = {
  cssNames: [],
}

export default PlaceholderImage
