import React from 'react'
import { Link } from 'react-router-dom'
import { NavItem, NavLink } from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const NavTab = ({
  currentTab, activeTab, text, to, onClick, disabled,
}) => {
  const active = currentTab === activeTab
  return (
    <NavItem className={classNames({ active })}>
      <NavLink
        tag={Link}
        to={to}
        className={classNames({ active }, 'font-weight-normal', 'h4')}
        onClick={onClick}
        disabled={disabled}
      >
        {text}
      </NavLink>
    </NavItem>
  )
}

NavTab.propTypes = {
  currentTab: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

NavTab.defaultProps = {
  to: '#',
  onClick: null,
  disabled: false,
}

export default NavTab
