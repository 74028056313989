import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentsView from '../components/TournamentsView'

const TournamentsViewContainer = ({
  loggedIn,
  pagination: { page },
}) => {
  return <TournamentsView loggedIn={loggedIn} page={page} />
}

TournamentsViewContainer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = ({
  auth: { loggedIn },
  tournaments: { data: { pagination } },
}) => ({
  loggedIn,
  pagination,
})


export default connect(mapStateToProps)(TournamentsViewContainer)
