import Auth0Lock from 'auth0-lock'

import config from '../../config'

class Auth {
  constructor() {
    this.lock = new Auth0Lock(process.env.REACT_APP_AUTH0_CLIENT_ID, process.env.REACT_APP_AUTH0_DOMAIN, {
      container: 'login-container',
      autoclose: false,
      theme: {
        logo: `${process.env.REACT_APP_DOMAIN}/logo_58.png`,
      },
      languageDictionary: {
        title: 'Log me in',
      },
      auth: {
        // set autoParseHash to false to delay processing
        // if not, on authenticated event could emit before getLoginInfoConnect() is ready
        autoParseHash: false,
        redirectUrl: config.auth.callbackUrl,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        responseType: 'token id_token',
        params: {
          scope: 'openid profile email',
        },
      },
    })
    /* this.lock.on('unrecoverable_error', (err) => {
      console.log(err)
    }) */
    this.ready = false
  }

  login = (options = {}) => {
    // Call the show method to display the widget.
    this.lock.show(options)
  }

  hide = () => {
    this.lock.hide()
  }

  logout = () => {
    this.lock.logout({
      returnTo: config.auth.logoutUrl,
    })
  }

  getUserProfile = (accessToken, callback) => {
    this.lock.getUserInfo(accessToken, callback)
  }

  handleAuthentication = (onAuth, onAuthErr) => {
    // Add a callback for Lock's `authenticated` event
    this.lock.on('authenticated', onAuth)

    // Add a callback for Lock's `authorization_error` event
    this.lock.on('authorization_error', onAuthErr)
  }

  resumeAuth = (hash) => {
    this.lock.resumeAuth(hash, () => {})
  }

  checkSession = (callback) => {
    this.lock.checkSession({}, callback)
  }
}

const auth = new Auth()
export default auth
