import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import Login from '../components/Login'
import { ROUTE_REDIRECT } from '../../app/types'
import Session from './SessionContainer'
import UnknownError from './UnknownErrorContainer'
import { addAlert } from '../../common'

const LoginContainer = ({
  lockReady,
  loggedIn,
  unknownError,
  silentLoginError,
  addAlertConnect,
}) => {
  const showLogin = !loggedIn && !unknownError
  const redirect = loggedIn && !unknownError
  return (
    <>
      <Session />
      <UnknownError />
      {redirect && <Redirect to={ROUTE_REDIRECT} />}
      {showLogin && (
        <Login
          silentLoginError={silentLoginError}
          lockReady={lockReady}
          addAlertConnect={addAlertConnect}
        />
      )}
    </>
  )
}

LoginContainer.propTypes = {
  lockReady: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  unknownError: PropTypes.bool.isRequired,
  silentLoginError: PropTypes.bool.isRequired,
  addAlertConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: { loggedIn, unknownError, silentLoginError },
  lock: {
    ready: lockReady,
  },
}) => ({
  lockReady,
  loggedIn,
  unknownError,
  silentLoginError,
})

const mapDispatchToProps = {
  addAlertConnect: addAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
