import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ExportTournamentItem from '../../components/tournament-view-buttons/ExportTournamentItem'
import { exportTournamentTrf16 } from '../../actions'

const ExportTournamentItemContainer = ({
  accessToken,
  tournamentId,
  exportTournamentTrf16Connect,
}) => {
  return (
    <ExportTournamentItem onClick={() => {
      return exportTournamentTrf16Connect(accessToken, tournamentId)
    }}
    />
  )
}

ExportTournamentItemContainer.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  exportTournamentTrf16Connect: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {
    auth: {
      data: { accessToken },
    },
  } = state

  return {
    accessToken,
  }
}

const mapDispatchToProps = {
  exportTournamentTrf16Connect: exportTournamentTrf16,
}

export default connect(
  mapStateToProps, mapDispatchToProps,
)(ExportTournamentItemContainer)
