import { failureAction, successAction, startAction } from '../../common'
import {
  EXPORT_TOURNAMENT,
  RESET_EXPORT_TOURNAMENT,
} from '../types'

export const initialState = {
  exporting: false,
  exported: false,
  data: '',
  error: {},
}

const reducer = ({ export: state }, action) => {
  switch (action.type) {
    case startAction(EXPORT_TOURNAMENT):
      return {
        ...initialState,
        exporting: true,
      }
    case successAction(EXPORT_TOURNAMENT):
      return {
        exporting: false,
        exported: true,
        data: action.payload,
        error: {},
      }
    case failureAction(EXPORT_TOURNAMENT):
      return {
        ...initialState,
        error: action.payload,
      }

    case RESET_EXPORT_TOURNAMENT:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
