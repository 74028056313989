import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import TournamentPlayerEdit from '../components/TournamentPlayerEdit'
import {
  LoadDataByMatch as TournamentPlayerData,
  LoadDataByArgs as TournamentData,
  LoadDataByArgs as TournamentPlayersData,
  LoadingSpinner,
} from '../../common'
import {
  getTournamentPlayer, updateTournamentPlayer, resetEditTournamentPlayer, listTournamentPlayers,
} from '../actions'
import { getTournament } from '../../tournament'
import { listToBitArray } from '../../../libs/convert'
import ReduxFormContext from '../../common/contexts/ReduxFormContext'
import { formatName } from '../../../libs/formatter'
import { submit } from '../tournament-player-edit'

const TournamentPlayerEditContainer = ({
  updateTournamentPlayerConnect,
  resetEditTournamentPlayerConnect,
  accessToken,
  loading,
  loaded,
  saved,
  tournamentPlayer,
  history,
  tournamentLoaded,
  tournament: { numRounds, numRoundsCreated },
  tournamentPlayers,
}) => {
  useEffect(() => {
    if (saved) {
      history.goBack()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saved])

  useEffect(() => {
    return () => {
      resetEditTournamentPlayerConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const onSubmit = (data) => {
    const { id: tournamentPlayerId } = tournamentPlayer
    return submit(
      data,
      (tournamentPlayerData) => updateTournamentPlayerConnect(accessToken, tournamentPlayerId, tournamentPlayerData),
    )
  }

  const getInitialValues = () => {
    if (!loaded || !tournamentLoaded) {
      return {}
    }
    const lookup = {}
    tournamentPlayers.forEach((item) => {
      const { id } = item
      lookup[id] = formatName(item)
    })

    const {
      firstName,
      lastName,
      rating,
      contact,
      byes,
      forbiddenIds,
    } = tournamentPlayer

    return {
      firstName,
      lastName,
      rating,
      contact,
      byes: listToBitArray(byes, numRounds),
      forbiddenList: forbiddenIds.filter((id) => {
        return lookup[id]
      }).map((id) => {
        return { value: id, label: lookup[id] }
      }),
    }
  }

  const {
    tournamentId,
    id: tournamentPlayerId,
  } = tournamentPlayer

  return (
    <>
      {loaded ? <TournamentData loadData={getTournament} args={[tournamentId]} /> : null}
      {loaded ? <TournamentPlayersData loadData={listTournamentPlayers} args={[tournamentId]} /> : null}
      <TournamentPlayerData loadData={getTournamentPlayer} />
      {loading ? <LoadingSpinner /> : null}
      {loaded && tournamentLoaded && (
        <ReduxFormContext.Provider value={{ numRoundsCreated, tournamentPlayerId, tournamentPlayers }}>
          <TournamentPlayerEdit
            {...{
              history,
              onSubmit,
              initialValues: getInitialValues(),
            }}
          />
        </ReduxFormContext.Provider>
      )}
    </>
  )
}

TournamentPlayerEditContainer.propTypes = {
  updateTournamentPlayerConnect: PropTypes.func.isRequired,
  resetEditTournamentPlayerConnect: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  saved: PropTypes.bool.isRequired,
  tournamentPlayer: PropTypes.shape({
    id: PropTypes.string,
    tournamentId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    rating: PropTypes.number,
    contact: PropTypes.string,
    byes: PropTypes.arrayOf(PropTypes.number),
    forbiddenIds: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  tournamentLoaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    numRounds: PropTypes.number,
    numRoundsCreated: PropTypes.number,
  }).isRequired,
  tournamentPlayers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  })).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
}

const mapStateToProps = ({
  auth: {
    data: { accessToken },
  },
  tournamentPlayer: {
    details: {
      loading, loaded, data: tournamentPlayer,
    },
    edit: { saved },
  },
  tournament: {
    summary: {
      loaded: tournamentSummaryLoaded,
      data: tournament,
    },
    players: {
      loaded: tournamentPlayersLoaded,
      data: tournamentPlayers,
    },
  },
}) => ({
  accessToken,
  loading,
  loaded,
  saved,
  tournamentPlayer,
  tournamentLoaded: tournamentSummaryLoaded && tournamentPlayersLoaded,
  tournament,
  tournamentPlayers,
})

const mapDispatchToProps = {
  updateTournamentPlayerConnect: updateTournamentPlayer,
  resetEditTournamentPlayerConnect: resetEditTournamentPlayer,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TournamentPlayerEditContainer))
