import React from 'react'
import { Field } from 'redux-form'
// import PropTypes from 'prop-types'

import { renderField } from '../../../../libs/form'

const TournamentDescriptionEdit = () => {
  return (
    <Field
      type="rich-editor"
      name="desc"
      component={renderField}
    />
  )
}

TournamentDescriptionEdit.propTypes = {
}

export default TournamentDescriptionEdit
