import React from 'react'
import { Spinner } from 'reactstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const LoadingSpinner = ({ cssNames }) => (
  <div className={classNames(cssNames)}>
    <Spinner type="grow" color="primary" />
  </div>
)

LoadingSpinner.propTypes = {
  cssNames: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

LoadingSpinner.defaultProps = {
  cssNames: 'text-center',
}

export default LoadingSpinner
