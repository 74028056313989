import React, { useEffect } from 'react'
import { reduxForm } from 'redux-form'
import { Container, Form, Button } from 'reactstrap'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import TournamentPlayerEditFields from '../../tournament-player/containers/TournamentPlayerEditFieldsContainer'

const TournamentRegisterEdit = ({
  history,
  handleSubmit,
  submitting,
  destroy,
  cancelled,
  cancelAddTournamentPlayerConnect,
  resetEditTournamentPlayerConnect,
}) => {
  useEffect(() => {
    if (cancelled) {
      return () => {
        destroy()
        resetEditTournamentPlayerConnect()
      }
    }

    // eslint requires consistant return
    return undefined
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [cancelled])

  return (
    <Container className="mt-3">
      <Form onSubmit={handleSubmit}>
        <TournamentPlayerEditFields />
        <div className="d-flex flex-row justify-content-center">
          <div className="p-2">
            <Button type="submit" color="primary" className="my-2" disabled={submitting}>
              Next
            </Button>
          </div>
          <div className="p-2">
            <Button
              color="secondary"
              className="my-2"
              onClick={() => {
                cancelAddTournamentPlayerConnect()
                history.goBack()
              }}
              disabled={submitting}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  )
}

TournamentRegisterEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  cancelled: PropTypes.bool.isRequired,
  destroy: PropTypes.func.isRequired,
  cancelAddTournamentPlayerConnect: PropTypes.func.isRequired,
  resetEditTournamentPlayerConnect: PropTypes.func.isRequired,
}

export default reduxForm({
  form: 'tournamentRegister',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(TournamentRegisterEdit)
