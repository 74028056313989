import React from 'react'
import {
  withRouter, generatePath,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

const ErrorBoundary = ({
  match: { path, params },
  children,
  hasError,
}) => {
  if (hasError) {
    return (
      <h3>
        Please click
        {' '}
        <a href={generatePath(path, { ...params })}>here</a>
        {' '}
to go back.
      </h3>
    )
  }

  return children
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  hasError: PropTypes.bool.isRequired,
}

export default withRouter(ErrorBoundary)
