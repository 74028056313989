import React, { useEffect } from 'react'
import { withRouter, generatePath } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import DeleteTournamentModal from '../components/DeleteTournamentModal'
import { deleteTournament, resetDeleteTournament } from '../actions'

import { ROUTE_TOURNAMENTS_VIEW } from '../../app/types'

const DeleteTournamentModalContainer = ({
  history,
  isOpen,
  toggle,
  accessToken,
  tournament,
  deleted,
  deleteTournamentConnect,
  resetDeleteTournamentConnect,
}) => {
  const { id: tournamentId, name } = tournament
  const confirmDelete = () => {
    return deleteTournamentConnect(accessToken, tournamentId)
  }

  useEffect(() => {
    if (deleted) {
      resetDeleteTournamentConnect()
      history.push(generatePath(ROUTE_TOURNAMENTS_VIEW, { tabId: 'iCreated' }))
    }
  })

  return (
    <DeleteTournamentModal
      name={name}
      isOpen={isOpen}
      toggle={toggle}
      confirmDelete={confirmDelete}
    />
  )
}

DeleteTournamentModalContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  deleted: PropTypes.bool.isRequired,
  deleteTournamentConnect: PropTypes.func.isRequired,
  resetDeleteTournamentConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: { data: { accessToken } },
  tournament: {
    summary: {
      data: tournament,
    },
  },
  tournament: { delete: { deleted } },
}) => {
  return {
    accessToken,
    tournament,
    deleted,
  }
}

const mapDispatchToProps = {
  deleteTournamentConnect: deleteTournament,
  resetDeleteTournamentConnect: resetDeleteTournament,
}

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(DeleteTournamentModalContainer))
