import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentPlayerList from '../components/TournamentPlayerList'

const TournamentPlayerListContainer = ({ loggedIn, user }) => {
  const { id: userId = null } = user
  return <TournamentPlayerList loggedIn={loggedIn} userId={userId} />
}

TournamentPlayerListContainer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
}

const mapStateToProps = ({
  auth: {
    loggedIn,
  },
  user: {
    details: {
      data: user,
    },
  },
}) => ({
  loggedIn,
  user,
})

export default connect(mapStateToProps)(TournamentPlayerListContainer)
