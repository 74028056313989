import { GET_BREADCRUMB, GET_MATCH } from './types'

export const getBreadcrumb = (items) => ({
  type: GET_BREADCRUMB,
  payload: { items },
})

export const getMatch = (match) => ({
  type: GET_MATCH,
  payload: { match },
})

export default getBreadcrumb
