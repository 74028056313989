import {
  failureAction, successAction, startAction,
} from '../../common'
import { GET_ISSUES_TO_DO } from '../types'

export const initialState = {
  loading: false,
  loaded: false,
  error: {},
  data: {
    numResults: 0,
    docs: [],
  },
}

const reducer = ({ toDo: state }, action) => {
  switch (action.type) {
    case startAction(GET_ISSUES_TO_DO):
      return {
        ...initialState,
        loading: true,
        loaded: false,
      }
    case successAction(GET_ISSUES_TO_DO):
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {},
        data: action.payload,
      }
    case failureAction(GET_ISSUES_TO_DO):
      return {
        ...initialState,
        error: action.payload,
      }

    default:
      return state
  }
}

export default reducer
