import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentPairingListBreadcrumbTitle from '../components/TournamentPairingListBreadcrumbTitle'
import { LoadingSpinner } from '../../common'

const TournamentPairingListBreadcrumbTitleContainer = ({
  loading, loaded, ...rest
}) => {
  if (loading) {
    return <LoadingSpinner />
  }

  return loaded && <TournamentPairingListBreadcrumbTitle {...rest} />
}

TournamentPairingListBreadcrumbTitleContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
}

const mapStateToProps = ({
  tournament: {
    summary: {
      loading,
      loaded,
      data: tournament,
    },
  },
}) => ({
  loading,
  loaded,
  tournament,
})

export default connect(mapStateToProps)(TournamentPairingListBreadcrumbTitleContainer)
