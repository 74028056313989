/* eslint-disable import/prefer-default-export */
import _ from 'lodash'

export const submit = (data, callback) => {
  const { byes = {}, forbiddenLists = {} } = data
  const options = {
    byes: Object.entries(byes).filter((item) => {
      const [, value] = item
      return _.isObject(value)
    }).map((item) => {
      const [tournamentPlayerId, { value: result }] = item
      return { tournamentPlayerId, result }
    }),
    forbiddenLists: Object.entries(forbiddenLists).map((item) => {
      const [tournamentPlayerId, forbiddenList = []] = item
      return {
        tournamentPlayerId,
        forbiddenIds: _.isArray(forbiddenList) ? forbiddenList.map((i) => {
          const { value: forbiddenId } = i
          return forbiddenId
        }) : [],
      }
    }),
  }
  return callback(options)
}

export const byeOptions = [
  { value: 'F', label: 'Full point bye' },
  { value: 'H', label: 'Half point bye' },
  { value: 'Z', label: 'Zero point bye' },
]
