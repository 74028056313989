import editReducer, { initialState as editState } from './editReducer'
import summaryReducer, { initialState as summaryState } from './summaryReducer'
import playersReducer, { initialState as playersState } from './playersReducer'
import deleteReducer, { initialState as deleteState } from './deleteReducer'
import exportReducer, { initialState as exportState } from './exportReducer'

export const initialState = {
  summary: summaryState,
  players: playersState,
  edit: editState,
  delete: deleteState,
  export: exportState,
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        edit: editReducer(state, action),
        summary: summaryReducer(state, action),
        players: playersReducer(state, action),
        delete: deleteReducer(state, action),
        export: exportReducer(state, action),
      }
  }
}
