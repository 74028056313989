import React from 'react'
import { Form } from 'redux-form'
import PropTypes from 'prop-types'

import SavingSpinner from './SavingSpinner'

const FormWrapper = ({ children, submitting, ...rest }) => {
  return (
    <Form {...rest}>
      {submitting && <SavingSpinner />}
      {children}
    </Form>
  )
}

FormWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default FormWrapper
