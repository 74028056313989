import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, generatePath } from 'react-router-dom'
import { getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import PairingEdit from '../components/PairingEdit'
import {
  LoadDataByMatch as TournamentData,
  LoadDataByMatch as StandingData,
  LoadingSpinner,
  Alert,
  clearAlerts,
} from '../../common'
import { getTournament } from '../../tournament/actions'
import { addPairing, resetEditPairing } from '../actions'
import { getStanding } from '../../tournament-standing/actions'
import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'
import { submit, byeOptions } from '../pairing-edit'
import { formatName } from '../../../libs/formatter'


const PairingCreateContainer = ({
  history,
  accessToken,
  loading,
  loaded,
  tournament,
  standing: { docs: players = [] },
  saved,
  error,
  addPairingConnect,
  resetEditPairingConnect,
  clearAlertsConnect,
  formValues,
}) => {
  const { id: tournamentId } = tournament
  useEffect(() => {
    if (saved) {
      history.push(generatePath(ROUTE_TOURNAMENT_VIEW, { id: tournamentId, tabId: 'pairings' }))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saved])

  useEffect(() => {
    return () => {
      resetEditPairingConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const onSubmit = (data) => {
    clearAlertsConnect()
    return submit(data, (options) => addPairingConnect(accessToken, tournamentId, options))
  }

  const getInitialValues = () => {
    if (!loaded) {
      return {}
    }

    const byes = {}
    const lookup = {}

    // remove withdrawn players
    const docs = players.filter((item) => {
      const { withdrawn } = item
      return !withdrawn
    })
    docs.forEach((item) => {
      const { id } = item
      lookup[id] = formatName(item)
    })

    const {
      settings: {
        tournament: {
          maxHalfPointByes,
        },
      },
    } = tournament

    const forbiddenLists = {}
    docs.forEach((item) => {
      const {
        id, bye = false, combinedForbiddenIds = [], numHalfPointByes,
      } = item
      const [, halfPointOption, zeroPointOption] = byeOptions
      const byeOption = numHalfPointByes >= maxHalfPointByes ? zeroPointOption : halfPointOption
      byes[id] = bye ? byeOption : null

      forbiddenLists[id] = combinedForbiddenIds.filter((forbiddenId) => {
        return forbiddenId !== id && lookup[forbiddenId]
      }).map((forbiddenId) => {
        return { value: forbiddenId, label: lookup[forbiddenId] }
      })
    })

    const initialValues = { byes, forbiddenLists }
    return initialValues
  }

  const { message = null } = error
  return (
    <>
      <TournamentData loadData={getTournament} />
      <StandingData loadData={getStanding} args={[{ forPairing: true }]} />
      <Alert message={message} color="danger" />
      {loading && <LoadingSpinner />}
      {loaded && (
      <PairingEdit
        history={history}
        onSubmit={onSubmit}
        formValues={{ ...formValues }}
        initialValues={getInitialValues()}
        docs={players}
        tournament={tournament}
      />
      )}
    </>
  )
}

PairingCreateContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  accessToken: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    settings: PropTypes.shape({
      tournament: PropTypes.shape({
        maxHalfPointByes: PropTypes.number,
      }),
    }),
  }).isRequired,
  standing: PropTypes.shape({
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        bye: PropTypes.bool,
        withdrawn: PropTypes.bool,
        numHalfPointByes: PropTypes.number,
      }),
    ),
  }).isRequired,
  saved: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  addPairingConnect: PropTypes.func.isRequired,
  clearAlertsConnect: PropTypes.func.isRequired,
  resetEditPairingConnect: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  const {
    auth: {
      data: { accessToken },
    },
    tournament: {
      summary: {
        loading: loadingTournament,
        loaded: tournamentLoaded,
        data: tournament,
      },
    },
    tournamentPairing: {
      edit: { saved, error },
    },
    tournamentStanding: {
      loading: loadingStanding,
      loaded: standingLoaded,
      data: standing,
    },
  } = state

  return {
    accessToken,
    loading: loadingTournament || loadingStanding,
    loaded: tournamentLoaded && standingLoaded,
    tournament,
    standing,
    saved,
    error,
    formValues: getFormValues('pairingEdit')(state) || {},
  }
}

const mapDispatchToProps = {
  addPairingConnect: addPairing,
  resetEditPairingConnect: resetEditPairing,
  clearAlertsConnect: clearAlerts,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PairingCreateContainer))
