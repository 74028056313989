import { ADD_ALERT, CLEAR_ALERTS } from './types'

export const initialState = []

const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ALERT:
      return [...state, action.payload]
    case CLEAR_ALERTS:
      return initialState
    default:
      return state
  }
}

export default alertReducer
