import { failureAction, successAction, startAction } from '../../common'
import {
  RESET_WITHDRAW_TOURNAMENT_PLAYER,
  WITHDRAW_TOURNAMENT_PLAYER,
  REENTER_TOURNAMENT_PLAYER,
} from '../types'

export const initialState = {
  saving: false,
  saved: false,
  error: {},
  data: {},
}

const reducer = ({ withdraw: state }, action) => {
  switch (action.type) {
    case startAction(WITHDRAW_TOURNAMENT_PLAYER):
    case startAction(REENTER_TOURNAMENT_PLAYER):
      return {
        ...state,
        saving: true,
        saved: false,
        error: {},
        data: {},
      }
    case successAction(WITHDRAW_TOURNAMENT_PLAYER):
    case successAction(REENTER_TOURNAMENT_PLAYER):
      return {
        ...state,
        saving: false,
        saved: true,
        error: {},
        data: action.payload,
      }
    case failureAction(WITHDRAW_TOURNAMENT_PLAYER):
    case failureAction(REENTER_TOURNAMENT_PLAYER):
      return {
        ...state,
        saving: false,
        saved: false,
        error: action.payload,
        data: {},
      }

    case RESET_WITHDRAW_TOURNAMENT_PLAYER:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
