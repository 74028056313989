import React from 'react'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'

import { renderField } from '../../../libs/form'
import { byeOptions } from '../pairing-edit'

const Bye = ({ id, canTakeHalfPointBye }) => {
  const options = canTakeHalfPointBye ? byeOptions : byeOptions.filter((item) => {
    const { value } = item
    return value !== 'H'
  })
  return (
    <>
      <Field
        type="react-select"
        name={`byes[${id}]`}
        component={renderField}
        options={options}
        placeholder={null}
        isClearable
      />
    </>
  )
}

Bye.propTypes = {
  id: PropTypes.string.isRequired,
  canTakeHalfPointBye: PropTypes.bool.isRequired,
}

export default Bye
