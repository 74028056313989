import React, { forwardRef } from 'react'
import {
  Input, InputGroup, InputGroupAddon, Button,
} from 'reactstrap'
import { FaRegCalendarAlt } from 'react-icons/fa'
import classNames from 'classnames'
import PropTypes from 'prop-types'

// use forwardRef to walk around stateless component warnings
// https://github.com/Hacker0x01/react-datepicker/issues/862
const DatePickerInput = forwardRef((props, ref) => {
  const {
    inputId, value, onClick, onChange,
  } = props

  const inputRef = React.createRef()
  return (
    <InputGroup>
      <InputGroupAddon addonType="prepend">
        <Button
          className="input-group-text"
          onClick={() => {
            onClick()
            inputRef.current.focus()
          }}
        >
          <FaRegCalendarAlt />
        </Button>
      </InputGroupAddon>
      <Input
        id={inputId}
        className={classNames('no-left-radius')}
        value={value}
        onClick={onClick}
        onChange={onChange}
        innerRef={inputRef}
        ref={ref}
      />
    </InputGroup>
  )
})

DatePickerInput.propTypes = {
  inputId: PropTypes.string.isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
}

DatePickerInput.defaultProps = {
  value: null,
  onClick: () => {},
  onChange: () => {},
}

export default DatePickerInput
