import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const PrivateComponent = ({ loggedIn, component: Component }) => (loggedIn ? <Component /> : null)

PrivateComponent.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
}

const mapStateToProps = ({ auth: { loggedIn } }) => ({
  loggedIn,
})

export default connect(mapStateToProps)(PrivateComponent)
