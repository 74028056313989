import React from 'react'
import {
  DropdownItem, UncontrolledTooltip,
} from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Info } from '../../../common'

const DeleteTournamentItem = ({
  // tournamentId,
  paired,
  onClick,
}) => {
  return (
    <div id="deleteTournamentButton" className={classNames('d-flex', { 'cursor-help': paired })}>
      <div className={classNames({ 'flex-grow-1': !paired })}>
        <DropdownItem
          className={classNames({ 'pr-1': paired })}
          disabled={paired}
          onClick={onClick}
        >
          Delete tournament
        </DropdownItem>
      </div>
      {paired && (
        <div className="flex-grow-1 pr-4">
          <Info className="text-info" />
          <UncontrolledTooltip placement="right" target="deleteTournamentButton">
            Cannot delete paired tournament. Please delete all pairings first.
          </UncontrolledTooltip>
        </div>
      )}
    </div>
  )
}

DeleteTournamentItem.propTypes = {
  // tournamentId: PropTypes.string.isRequired,
  paired: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default DeleteTournamentItem
