import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import CloseTournamentModal from '../components/CloseTournamentModal'
import { closeTournament } from '../actions'

const CloseTournamentModalContainer = ({
  isOpen,
  toggle,
  accessToken,
  tournament,
  closeTournamentConnect,
}) => {
  const { id: tournamentId } = tournament
  return (
    <CloseTournamentModal
      isOpen={isOpen}
      toggle={toggle}
      tournament={tournament}
      confirmUpdate={() => {
        return closeTournamentConnect(accessToken, tournamentId)
      }}
    />
  )
}

CloseTournamentModalContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  closeTournamentConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: { data: { accessToken } },
  tournament: {
    summary: {
      data: tournament,
    },
  },
}) => {
  return {
    accessToken,
    tournament,
  }
}


const mapDispatchToProps = {
  closeTournamentConnect: closeTournament,
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseTournamentModalContainer)
