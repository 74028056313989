import React from 'react'
import PropTypes from 'prop-types'
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Media,
} from 'reactstrap'
import { FaChessPawn } from 'react-icons/fa'

import PlaceholderImage from './PlaceholderImage'
import { auth } from '../../auth'

const ProfileButton = ({ profile: { name, picture } }) => {
  return (
    <UncontrolledButtonDropdown nav inNavbar>
      <DropdownToggle nav>
        <FaChessPawn size={28} />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem header>
          <Media>
            {picture ? (
              <Media
                object
                className="rounded-circle avatar mr-3"
                src={picture}
                alt={name}
              />
            ) : <PlaceholderImage cssNames={['mr-3']} name={name} />}
            <Media body className="font-weight-bold">
              {name}
            </Media>
          </Media>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          onClick={() => {
            auth.logout()
          }}
        >
          Log out
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

ProfileButton.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string,
    picture: PropTypes.string,
  }),
}

ProfileButton.defaultProps = {
  profile: {
    name: null,
    picture: null,
  },
}

export default ProfileButton
