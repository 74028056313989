import React from 'react'
import PropTypes from 'prop-types'

import ActionModal from '../../common/components/ActionModal'
import { formatName } from '../../../libs/formatter'

const ReenterModal = ({
  tournamentPlayer,
  isOpen,
  toggle,
  confirmReenter,
}) => {
  const headerText = 'Reenter player?'
  const Body = () => {
    return (
      <>
        {'Are you sure you want to reenter '}
        <span className="font-weight-less-bold">{formatName(tournamentPlayer)}</span>
        {' into the tournament? '}
        Typically you only want to do that if the player has withdrawn from the tournament by mistake.
      </>
    )
  }

  return (
    <ActionModal {...{
      isOpen,
      headerText,
      Body,
      toggle,
      confirmAction: confirmReenter,
    }}
    />
  )
}

ReenterModal.propTypes = {
  tournamentPlayer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  confirmReenter: PropTypes.func.isRequired,
}

export default ReenterModal
