import React from 'react'
import {
  Card, CardHeader, CardBody, Table,
} from 'reactstrap'
import { FaRegCalendarAlt } from 'react-icons/fa'
import PropTypes from 'prop-types'

import { formatDateTime } from '../../../../libs/formatter'

const When = ({ tournament }) => {
  const {
    startOn, endOn,
  } = tournament

  return (
    <Card>
      <CardHeader>
        <h4>When</h4>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-row mb-3">
          <div className="mr-2"><FaRegCalendarAlt size="28" /></div>
          <div>
            {formatDateTime(startOn)}
            {(startOn && endOn) && ' - '}
            {formatDateTime(endOn)}
            {(!startOn && !endOn) && 'TBD'}
          </div>
        </div>
        {false && (
          <Table hover>
            <thead>
              <tr>
                <th>Rd #</th>
                <th>Time</th>
              </tr>
            </thead>
          </Table>
        )}
      </CardBody>
    </Card>
  )
}

When.propTypes = {
  tournament: PropTypes.shape({
    startOn: PropTypes.string,
    endOn: PropTypes.string,
  }).isRequired,
}

export default When
