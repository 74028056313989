import React from 'react'
import PropTypes from 'prop-types'

import PairingRow from './PairingRow'
import {
  getForbiddenPlayers,
} from '../../tournament-player/components/TournamentPlayer'

const PairingListBody = ({
  docs,
  isMyTournament,
  tournamentCompleted,
  userId,
}) => {
  const tournamentPlayers = []
  docs.forEach((item) => {
    const { white, black = null } = item
    tournamentPlayers.push(white)
    if (black) {
      tournamentPlayers.push(black)
    }
  })
  return (
    <>
      {docs.map((doc) => {
        const {
          id, white, blackId = null, black = {},
        } = doc
        const { forbiddenIds: whiteForbiddenIds } = white
        const { forbiddenIds: blackForbiddenIds } = black
        const pairing = {
          ...doc,
          white: {
            ...white,
            forbiddenPlayers: getForbiddenPlayers(tournamentPlayers, whiteForbiddenIds),
          },
        }
        if (blackId) {
          pairing.black = {
            ...black,
            forbiddenPlayers: getForbiddenPlayers(tournamentPlayers, blackForbiddenIds),
          }
        }

        return (
          <PairingRow
            key={id}
            pairing={pairing}
            isMyTournament={isMyTournament}
            tournamentCompleted={tournamentCompleted}
            userId={userId}
          />
        )
      })}
    </>
  )
}

PairingListBody.propTypes = {
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
  isMyTournament: PropTypes.bool.isRequired,
  tournamentCompleted: PropTypes.bool.isRequired,
  userId: PropTypes.string,
}

PairingListBody.defaultProps = {
  docs: [],
  userId: null,
}

export default PairingListBody
