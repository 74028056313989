import Http from './http'

class Tournament extends Http {
  async add(data) {
    const res = await this.request.post('/tournaments', data)
    return res.data
  }

  async copy(data, options) {
    const { fromTournamentId = null } = options
    const res = await this.request.post('/tournaments', data, { params: { fromTournamentId } })
    return res.data
  }

  async update(id, data) {
    const res = await this.request.put(`/tournaments/${id}`, data)
    return res.data
  }

  async updateDescription(id, data) {
    const res = await this.request.put(`/tournaments/${id}/description`, data)
    return res.data
  }

  async updateSettings(id, data) {
    const res = await this.request.put(`/tournaments/${id}/settings`, data)
    return res.data
  }

  async reopen(id) {
    const res = await this.request.put(`/tournaments/${id}/open`, {})
    const { completed } = res.data
    return completed
  }

  async close(id) {
    const res = await this.request.put(`/tournaments/${id}/close`, {})
    const { completed } = res.data
    return completed
  }

  async list(params = {}) {
    const res = await this.request.get('/tournaments', { params })
    return res.data
  }

  async get(id) {
    const res = await this.request.get(`/tournaments/${id}`)
    return res.data
  }

  async exportTrf16(id) {
    const res = await this.request.get(`/tournaments/${id}/export/trf16`)
    return res.data
  }

  async delete(id) {
    const res = await this.request.delete(`/tournaments/${id}`)
    return res.data
  }
}

export default Tournament
