import { failureAction, successAction } from '../common'
import {
  LOGIN,
  LOGOUT,
  RESET_LOCK,
  LOCK_READY,
  HTTP_UNAUTHORIZED,
  UNKNOWN_ERROR,
  DONE_UNKNOWN_ERROR,
  SILENT_LOGIN_ERROR,
  RESET_AUTH,
  GET_AUTH,
} from './types'

export const getLoginInfo = (authResult) => ({
  type: successAction(LOGIN),
  payload: authResult,
})

export const getLoginErr = (err) => ({
  type: failureAction(LOGIN),
  payload: err,
})

export const logout = () => ({
  type: successAction(LOGOUT),
})

export const getLockReady = () => ({
  type: LOCK_READY,
})

export const resetLock = () => ({
  type: RESET_LOCK,
})

export const resetAuth = () => ({
  type: RESET_AUTH,
})

export const getUnauthorized = () => ({
  type: HTTP_UNAUTHORIZED,
})

export const getUnknownError = () => ({
  type: UNKNOWN_ERROR,
})

export const doneUnknownError = () => ({
  type: DONE_UNKNOWN_ERROR,
})

export const getSilentLoginError = () => ({
  type: SILENT_LOGIN_ERROR,
})

export const getAuth = (data) => ({
  type: GET_AUTH,
  payload: data,
})
