import React from 'react'
import { connect } from 'react-redux'
import { withRouter, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { listToBitArray } from '../../../libs/convert'
import TournamentRegisterEdit from '../components/TournamentRegisterEdit'
import {
  LoadDataByMatch as TournamentData,
  LoadDataByMatch as TournamentPlayersData,
  LoadingSpinner,
} from '../../common'
import { ROUTE_TOURNAMENT_REGISTER_CONFIRM } from '../../app/types'
import { getTournament } from '../actions'
import {
  cancelAddTournamentPlayer, resetEditTournamentPlayer, listTournamentPlayers,
} from '../../tournament-player/actions'

import ReduxFormContext from '../../common/contexts/ReduxFormContext'

const TournamentRegisterEditContainer = ({
  loading,
  loaded,
  cancelled,
  tournament,
  tournamentPlayers,
  history,
  cancelAddTournamentPlayerConnect,
  resetEditTournamentPlayerConnect,
}) => {
  const { id, numRounds, numRoundsCreated } = tournament
  const onSubmit = () => {
    return history.push(generatePath(ROUTE_TOURNAMENT_REGISTER_CONFIRM, { id }))
  }

  return (
    <>
      <TournamentData loadData={getTournament} />
      <TournamentPlayersData loadData={listTournamentPlayers} />
      {loading ? <LoadingSpinner /> : null}
      {loaded ? (
        <ReduxFormContext.Provider value={{ numRoundsCreated, tournamentPlayerId: null, tournamentPlayers }}>
          <TournamentRegisterEdit
            {...{
              history,
              onSubmit,
              cancelled,
              cancelAddTournamentPlayerConnect,
              resetEditTournamentPlayerConnect,
              initialValues: {
                byes: listToBitArray([], numRounds),
              },
            }}
          />
        </ReduxFormContext.Provider>
      ) : null}
    </>
  )
}

TournamentRegisterEditContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  cancelled: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    numRounds: PropTypes.number,
    numRoundsCreated: PropTypes.number,
  }).isRequired,
  tournamentPlayers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  })).isRequired,
  cancelAddTournamentPlayerConnect: PropTypes.func.isRequired,
  resetEditTournamentPlayerConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  tournament: {
    summary: {
      loading: loadingTournament,
      loaded: tournamentLoaded,
      data: tournament,
    },
    players: {
      loading: loadingTournamentPlayers,
      loaded: tournamentPlayersLoaded,
      data: tournamentPlayers,
    },
  },
  tournamentPlayer: {
    edit: { cancelled },
  },
}) => ({
  loading: loadingTournament || loadingTournamentPlayers,
  loaded: tournamentLoaded && tournamentPlayersLoaded,
  cancelled,
  tournament,
  tournamentPlayers,
})

const mapDispatchToProps = {
  cancelAddTournamentPlayerConnect: cancelAddTournamentPlayer,
  resetEditTournamentPlayerConnect: resetEditTournamentPlayer,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TournamentRegisterEditContainer))
