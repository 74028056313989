import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import status from 'statuses'
import _ from 'lodash'
import PropTypes from 'prop-types'

import {
  auth, getLoginInfo, getLoginErr, getLockReady, resetLock, getUnauthorized, getUnknownError, getAuth,
} from '../../auth'
import LoginButton from '../components/LoginButton'
import { GET_USER_PROFILE } from '../../user'
import {
  getUserProfile, getUserProfileErr, addOrGetUser,
} from '../../user/actions'
import { startAction } from '../../common'
import { ROUTE_LOGIN, ROUTE_LOGOUT, ROUTE_CALLBACK } from '../../app/types'

const LoginButtonContainer = ({
  matchLoaded,
  match: { path },
  lockReady,
  doneGetAuth,
  silentLoginError,
  unauthorized,
  unknownError,
  userAuth,
  loadingProfile,
  profileLoaded,
  profile: {
    sub: authId,
    given_name: firstName,
    family_name: lastName,
    name,
    picture,
    email,
    email_verified: emailVerified,
  },
  loadingUserDetails,
  userDetailsLoaded,
  hasError,
  startGetUserProfile,
  getUserProfileConnect,
  getUserProfileErrConnect,
  getLoginInfoConnect,
  getLoginErrConnect,
  getLockReadyConnect,
  resetLockConnect,
  addOrGetUserConnect,
  getAuthConnect,
  getUnauthorizedConnect,
  getUnknownErrorConnect,
}) => {
  const { loggedIn, data: { accessToken } } = userAuth

  useEffect(() => {
    if (!lockReady) {
      auth.handleAuthentication(getLoginInfoConnect, getLoginErrConnect)
      getLockReadyConnect()
    }
  })

  useEffect(() => {
    return () => {
      resetLockConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    if (loggedIn && hasError) {
      getUnknownErrorConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loggedIn, hasError])

  const canLoadProfile = accessToken
    && matchLoaded
    && path !== ROUTE_LOGOUT
    && !(loadingProfile || profileLoaded) && !hasError
  useEffect(() => {
    if (canLoadProfile) {
      const callback = (error, data) => {
        if (error) {
          if (error.statusCode === status('unauthorized') && !unauthorized) {
            getUnauthorizedConnect()
          }
          getUserProfileErrConnect(error)
        } else {
          getUserProfileConnect(data)
        }
      }

      startGetUserProfile()
      auth.getUserProfile(accessToken, callback)
    }
  })

  const canGetUser = accessToken
    && matchLoaded
    && path !== ROUTE_LOGOUT
    && profileLoaded
    && !(loadingUserDetails || userDetailsLoaded) && !hasError

  useEffect(() => {
    if (canGetUser) {
      addOrGetUserConnect(accessToken, {
        authId,
        firstName,
        lastName,
        name,
        picture,
        email,
        emailVerified,
      })
    }
  })

  const canGetAuth = matchLoaded
    && path !== ROUTE_LOGOUT
    && userDetailsLoaded
    && !doneGetAuth
  useEffect(() => {
    if (canGetAuth) {
      const { data } = userAuth
      getAuthConnect(data)
    }
  })

  // Redirect to login to recover from any authentication errors and unknown errors (such as network error)
  if (unauthorized || unknownError || silentLoginError) {
    auth.hide()
    return <Redirect to={ROUTE_LOGIN} />
  }

  const showLogin = !loggedIn && path && path !== ROUTE_LOGIN && path !== ROUTE_CALLBACK
  return showLogin ? <LoginButton /> : <></>
}

LoginButtonContainer.propTypes = {
  matchLoaded: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string,
  }).isRequired,
  loadingProfile: PropTypes.bool.isRequired,
  profileLoaded: PropTypes.bool.isRequired,
  profile: PropTypes.shape({
    sub: PropTypes.string,
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    name: PropTypes.string,
    picture: PropTypes.string,
    email: PropTypes.string,
    email_verified: PropTypes.bool,
  }).isRequired,
  loadingUserDetails: PropTypes.bool.isRequired,
  userDetailsLoaded: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  userAuth: PropTypes.shape({
    loggedIn: PropTypes.bool.isRequired,
    accessToken: PropTypes.string,
    data: PropTypes.shape({
      accessToken: PropTypes.string,
    }),
  }).isRequired,
  lockReady: PropTypes.bool.isRequired,
  doneGetAuth: PropTypes.bool.isRequired,
  silentLoginError: PropTypes.bool.isRequired,
  unauthorized: PropTypes.bool.isRequired,
  unknownError: PropTypes.bool.isRequired,
  startGetUserProfile: PropTypes.func.isRequired,
  getUserProfileConnect: PropTypes.func.isRequired,
  getUserProfileErrConnect: PropTypes.func.isRequired,
  getLoginInfoConnect: PropTypes.func.isRequired,
  getLoginErrConnect: PropTypes.func.isRequired,
  getLockReadyConnect: PropTypes.func.isRequired,
  resetLockConnect: PropTypes.func.isRequired,
  addOrGetUserConnect: PropTypes.func.isRequired,
  getAuthConnect: PropTypes.func.isRequired,
  getUnauthorizedConnect: PropTypes.func.isRequired,
  getUnknownErrorConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  lock: {
    ready: lockReady,
  },
  auth: {
    silentLoginError,
    unauthorized,
    unknownError,
    loggedIn: doneGetAuth,
  },
  user: {
    auth: userAuth,
    profile: {
      loading: loadingProfile, loaded: profileLoaded, data: profile, error: profileError,
    },
    details: { loading: loadingUserDetails, loaded: userDetailsLoaded, error: userDetailsError },
  },
  breadcrumb: { match: { loaded: matchLoaded, data: match } },
}) => ({
  lockReady,
  doneGetAuth,
  silentLoginError,
  unauthorized,
  unknownError,
  userAuth,
  matchLoaded,
  match,
  loadingProfile,
  profileLoaded,
  profile,
  loadingUserDetails,
  userDetailsLoaded,
  hasError: !_.isEmpty(profileError) || !_.isEmpty(userDetailsError),
})

const mapDispatchToProps = {
  startGetUserProfile: () => ({
    type: startAction(GET_USER_PROFILE),
  }),
  getUserProfileConnect: getUserProfile,
  getUserProfileErrConnect: getUserProfileErr,
  getLoginInfoConnect: getLoginInfo,
  getLoginErrConnect: getLoginErr,
  getLockReadyConnect: getLockReady,
  resetLockConnect: resetLock,
  addOrGetUserConnect: addOrGetUser,
  getAuthConnect: getAuth,
  getUnauthorizedConnect: getUnauthorized,
  getUnknownErrorConnect: getUnknownError,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginButtonContainer)
