import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ReopenTournamentModal from '../components/ReopenTournamentModal'
import { reopenTournament } from '../actions'

const ReopenTournamentModalContainer = ({
  isOpen,
  toggle,
  accessToken,
  tournament,
  reopenTournamentConnect,
}) => {
  const { id: tournamentId } = tournament
  return (
    <ReopenTournamentModal
      isOpen={isOpen}
      toggle={toggle}
      tournament={tournament}
      confirmUpdate={() => {
        return reopenTournamentConnect(accessToken, tournamentId)
      }}
    />
  )
}

ReopenTournamentModalContainer.propTypes = {
  accessToken: PropTypes.string.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  reopenTournamentConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: { data: { accessToken } },
  tournament: {
    summary: {
      data: tournament,
    },
  },
}) => {
  return {
    accessToken,
    tournament,
  }
}


const mapDispatchToProps = {
  reopenTournamentConnect: reopenTournament,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReopenTournamentModalContainer)
