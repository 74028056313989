import { failureAction, successAction, startAction } from '../../common'
import {
  ADD_PAIRING,
  UPDATE_PAIRING,
  RESET_EDIT_PAIRING,
} from '../types'

export const initialState = {
  saving: false,
  saved: false,
  cancelled: false,
  error: {},
  data: {},
}

const reducer = ({ edit: state }, action) => {
  switch (action.type) {
    case startAction(ADD_PAIRING):
    case startAction(UPDATE_PAIRING):
      return {
        ...state,
        saving: true,
        saved: false,
        error: {},
        data: {},
      }
    case successAction(UPDATE_PAIRING):
    case successAction(ADD_PAIRING):
      return {
        ...state,
        saving: false,
        saved: true,
        error: {},
        data: action.payload,
      }
    case failureAction(UPDATE_PAIRING):
    case failureAction(ADD_PAIRING):
      return {
        ...state,
        saving: false,
        saved: false,
        error: action.payload,
        data: {},
      }

    case RESET_EDIT_PAIRING:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default reducer
