import React from 'react'
import { Container } from 'reactstrap'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

import { formatDate } from '../../../libs/formatter'

const Release = ({
  name,
  body,
  published_at: publishedAt,
}) => {
  return (
    <>
      <Container className="release-notes">
        <h1 className="header">{name}</h1>
        <p className="text-secondary mb-4">{formatDate(publishedAt)}</p>
        <div>
          <ReactMarkdown source={body} />
        </div>
      </Container>
      <hr />
    </>
  )
}

Release.propTypes = {
  name: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  published_at: PropTypes.string.isRequired,
}

export default Release
