import React from 'react'
import {
  Card, CardTitle, CardBody, CardText,
} from 'reactstrap'
import PropTypes from 'prop-types'

import { formatDateTime } from '../../../libs/formatter'

const Issue = ({
  doc: {
    title,
    body,
    state,
    created_at: createdAt,
    updated_at: updatedAt,
    closed_at: closedAt,
  },
}) => {
  return (
    <Card className="mx-0 mb-3">
      <CardBody>
        <CardTitle className="font-weight-less-bold">{title}</CardTitle>
        <CardText>
          {body}
        </CardText>
        <CardText className="mb-0">
          <small className="text-muted">
            Created at
            {' '}
            {formatDateTime(createdAt)}
          </small>
        </CardText>
        <CardText className="mb-0">
          <small className="text-muted">
            {state === 'open'
              ? `Last updated at ${formatDateTime(updatedAt)}`
              : `Completed at ${formatDateTime(closedAt)}`}
          </small>
        </CardText>
      </CardBody>
    </Card>
  )
}

Issue.propTypes = {
  doc: PropTypes.shape({
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    state: PropTypes.oneOf(['open', 'closed']).isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    closed_at: PropTypes.string,
  }).isRequired,
}

export default Issue
