/* eslint-disable max-len */
import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import LoadingSpinner from '../../common/components/LoadingSpinner'
import { ROUTE_REDIRECT } from '../../app/types'

const Callback = ({ loggedIn }) => (loggedIn ? (
  <Redirect to={ROUTE_REDIRECT} />
) : (
  <>
    <div className="mt-5 d-flex justify-content-center">
      <div className="mx-3">
        <LoadingSpinner />
      </div>
      <h3 className="font-italic">Please wait while we log you in...</h3>
    </div>
  </>
))

Callback.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
}

export default Callback
