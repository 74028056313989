/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Table } from 'reactstrap'
import { Field, fieldArrayFieldsPropTypes } from 'redux-form'
import PropTypes from 'prop-types'

import ReduxFormContext from '../../common/contexts/ReduxFormContext'

const Byes = ({ fields }) => {
  return (
    <Table size="sm">
      <thead>
        <tr>
          {fields.map((item, index) => {
            return (<th key={index}>{`Rd ${index + 1}`}</th>)
          })}
        </tr>
      </thead>
      <tbody>
        <tr>
          <ReduxFormContext.Consumer>
            {({ numRoundsCreated }) => {
              return fields.map((item, index) => {
                const canBye = index + 1 > numRoundsCreated
                return (
                  <td key={index}>
                    {canBye ? (
                      <Field
                        type="checkbox"
                        id={item}
                        name={item}
                        component="input"
                      />
                    ) : 'N/a'}
                  </td>
                )
              })
            }}
          </ReduxFormContext.Consumer>
        </tr>
      </tbody>
    </Table>
  )
}

Byes.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
}

export default Byes
