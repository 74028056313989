import React from 'react'
import {
  UncontrolledTooltip,
} from 'reactstrap'
import PropTypes from 'prop-types'

import { Info } from '../../common'

const ByeInfo = ({ id, name }) => {
  const infoId = `bye-info-${id}`
  return (
    <div>
      <Info id={infoId} className="text-info" />
      <UncontrolledTooltip placement="right" target={infoId}>
        {`${name} has requested a bye for this round.`}
      </UncontrolledTooltip>
    </div>
  )
}

ByeInfo.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default ByeInfo
