import React from 'react'
import {
  DropdownItem, UncontrolledTooltip,
} from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'


import { Info } from '../../../common'

const WithdrawItem = ({
  disabled,
  onClick,
  tournamentPlayerId,
}) => {
  const target = `withdraw-item-${tournamentPlayerId}`
  return (
    <div id={target} className={classNames('d-flex', { 'cursor-help': disabled })}>
      <div className={classNames({ 'flex-grow-1': !disabled })}>
        <DropdownItem
          onClick={onClick}
          disabled={disabled}
        >
          Withdraw
        </DropdownItem>
      </div>
      {disabled && (
        <div className="flex-grow-1 pr-4">
          <Info className="text-info" />
          <UncontrolledTooltip placement="right" target={target}>
            Cannot withdraw because last round pairings have been created.
          </UncontrolledTooltip>
        </div>
      )}
    </div>
  )
}

WithdrawItem.propTypes = {
  tournamentPlayerId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default WithdrawItem
