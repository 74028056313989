import React from 'react'
import { Link, generatePath } from 'react-router-dom'
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu,
} from 'reactstrap'
import PropTypes from 'prop-types'

import ReopenTournamentButton from './tournament-view-buttons/ReopenTournamentButton'
import CreatePairingsButton from './tournament-view-buttons/CreatePairingsButton'
import RedoPairingsButton from './tournament-view-buttons/RedoPairingsButton'
import DeleteTournamentItem from './tournament-view-buttons/DeleteTournamentItem'
import RegisterPlayerItem from './tournament-view-buttons/RegisterPlayerItem'
import CloseTournamentItem from './tournament-view-buttons/CloseTournamentItem'
import ExportTournamentItem from '../containers/tournament-view-buttons/ExportTournamentItemContainer'
import ConfigTournamentButton from './tournament-view-buttons/ConfigTournamentButton'
import { ROUTE_TOURNAMENT_EDIT, ROUTE_TOURNAMENT_COPY } from '../../app/types'

const TournamentViewButtons = ({
  tournament,
  toggleDeletePairingsModal,
  toggleDeleteTournamentModal,
  toggleCloseTournamentModal,
  toggleReopenTournamentModal,
}) => {
  const {
    id: tournamentId,
    numRounds,
    numRoundsCreated,
    resultsReported,
    numPlayers,
    completed = false,
  } = tournament

  const paired = numRoundsCreated > 0
  const roundNo = numRoundsCreated
  const nextRoundNo = roundNo + 1
  const pairingCompleted = numRoundsCreated === numRounds
  const canCreatePairings = (!paired && numPlayers > 0) || (resultsReported && !pairingCompleted)
  const tournamentCompleted = resultsReported && pairingCompleted
  let cannotPairText = ''
  if (!canCreatePairings) {
    if (numPlayers === 0) {
      cannotPairText = `Round ${nextRoundNo} pairings cannot be created until there are registered players.`
    } else if (!resultsReported) {
      cannotPairText = `Round ${nextRoundNo} pairings cannot be created until all results are reported.`
    } else if (tournamentCompleted) {
      cannotPairText = 'Tounament has completed.'
    }
  }

  return (
    <>
      <li key="tournament-action-menu" className="mx-3">
        <UncontrolledButtonDropdown>
          {completed && (
            <ReopenTournamentButton
              onClick={toggleReopenTournamentModal}
            />
          )}
          {!completed && pairingCompleted && (
            <RedoPairingsButton {...{
              tournamentId,
              roundNo,
              isButton: true,
            }}
            />
          )}
          {!completed && !pairingCompleted && (
            <CreatePairingsButton {...{
              tournamentId,
              canCreatePairings,
              nextRoundNo,
              tournamentCompleted,
              cannotPairText,
            }}
            />
          )}
          <DropdownToggle caret color="primary" />
          <DropdownMenu right>
            {!completed && paired && (
              <>
                {!pairingCompleted && (
                  <RedoPairingsButton {...{
                    tournamentId,
                    roundNo,
                    isButton: false,
                  }}
                  />
                )}
                <DropdownItem
                  onClick={toggleDeletePairingsModal}
                >
                  {`Delete round ${roundNo} pairings`}
                </DropdownItem>
              </>
            )}
            {!completed && (
              <>
                <DropdownItem divider />
                <DropdownItem
                  tag={Link}
                  to={generatePath(ROUTE_TOURNAMENT_EDIT, { id: tournamentId })}
                >
                  Modify tournament
                </DropdownItem>
              </>
            )}
            <DeleteTournamentItem
              onClick={toggleDeleteTournamentModal}
              tournamentId={tournamentId}
              paired={paired}
            />
            <DropdownItem
              tag={Link}
              to={generatePath(ROUTE_TOURNAMENT_COPY, { id: tournamentId })}
            >
              Copy tournament
            </DropdownItem>
            {!completed && (
              <>
                <CloseTournamentItem
                  onClick={toggleCloseTournamentModal}
                />
              </>
            )}
            <ExportTournamentItem tournamentId={tournamentId} />
            {!completed && (
              <>
                <DropdownItem divider />
                <RegisterPlayerItem
                  tournamentId={tournamentId}
                  pairingCompleted={pairingCompleted}
                />
              </>
            )}
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </li>
      <li>
        <ConfigTournamentButton tournamentId={tournamentId} />
      </li>
    </>
  )
}

TournamentViewButtons.propTypes = {
  tournament: PropTypes.shape({
    id: PropTypes.string.isRequired,
    numRounds: PropTypes.number,
    resultsReported: PropTypes.bool.isRequired,
    numRoundsCreated: PropTypes.number.isRequired,
    numPlayers: PropTypes.number.isRequired,
    completed: PropTypes.bool,
  }).isRequired,
  toggleDeletePairingsModal: PropTypes.func.isRequired,
  toggleDeleteTournamentModal: PropTypes.func.isRequired,
  toggleCloseTournamentModal: PropTypes.func.isRequired,
  toggleReopenTournamentModal: PropTypes.func.isRequired,
}

export default TournamentViewButtons
