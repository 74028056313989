import React from 'react'
import { reduxForm } from 'redux-form'
import { Container, Button, Alert } from 'reactstrap'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Form } from '../../common'
import PairingPlayerList from './PairingPlayerList'
import ReduxFormContext from '../../common/contexts/ReduxFormContext'
import { formatName } from '../../../libs/formatter'

const getZeroPointByePlayers = (docs, maxHalfPointByes) => {
  const zeroPointByePlayers = []
  docs.forEach((item) => {
    const { numHalfPointByes, bye } = item
    if (bye && numHalfPointByes >= maxHalfPointByes) {
      zeroPointByePlayers.push(item)
    }
  })
  return zeroPointByePlayers
}

const getPlayerList = (docs) => {
  return docs.map((item, index) => {
    const { id } = item
    const showDivider = index < docs.length - 1
    return (
      <div key={`player-${id}`} className="ml-1">
        <span className="font-weight-less-bold">
          {formatName(item)}
        </span>
        { showDivider && ','}
      </div>
    )
  })
}

const PairingEdit = (props) => {
  const {
    formValues,
    history,
    handleSubmit,
    submitting,
    change,
    docs: players,
    tournament,
  } = props

  // withdrawn players will not be paired
  const docs = []
  const withdrawnPlayers = []
  players.forEach((item) => {
    const { withdrawn } = item
    if (!withdrawn) {
      docs.push(item)
    } else {
      withdrawnPlayers.push(item)
    }
  })
  const lookups = {}
  docs.forEach((item) => {
    const { id } = item
    lookups[id] = formatName(item)
  })
  const hasBye = docs.some((item) => {
    const { bye } = item
    return bye
  })
  const hasForbiddenList = docs.some((item) => {
    const { combinedForbiddenIds } = item
    return combinedForbiddenIds.length > 0
  })

  const {
    settings: {
      tournament: {
        maxHalfPointByes,
      },
    },
  } = tournament
  const zeroPointByePlayers = getZeroPointByePlayers(docs, maxHalfPointByes)

  return (
    <>
      {zeroPointByePlayers.length > 0 && (
        <Alert color="info" className="text-center">
          <div className="d-flex flex-row">
            {`The following player(s) have taken the maximum allowed half point byes (${maxHalfPointByes}), `
            + 'so zero point byes are enforced:'}
            {getPlayerList(zeroPointByePlayers)}
            .
          </div>
        </Alert>
      )}
      {withdrawnPlayers.length > 0 && (
      <Alert color="info" className="text-center">
        <div className="d-flex flex-row">
          {'The following players have withdrawn from the tournament so will not be paired:'}
          {getPlayerList(withdrawnPlayers)}
          .
        </div>
      </Alert>
      )}
      <Container className="mt-3">
        <Form onSubmit={handleSubmit} submitting={submitting}>
          <ReduxFormContext.Provider
            value={{
              change, formValues, docs, lookups, hasBye, hasForbiddenList, maxHalfPointByes,
            }}
          >
            <PairingPlayerList docs={docs} />
          </ReduxFormContext.Provider>
          <div className="d-flex flex-row justify-content-center">
            <div className="p-2">
              <Button type="submit" color="primary" className="my-2" disabled={submitting}>
                Save
              </Button>
            </div>
            <div className="p-2">
              <Button
                color="secondary"
                className="my-2"
                onClick={() => {
                  history.goBack()
                }}
                disabled={submitting}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      </Container>
    </>
  )
}

PairingEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}).isRequired,
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  tournament: PropTypes.shape({
    settings: PropTypes.shape({
      tournament: PropTypes.shape({
        maxHalfPointByes: PropTypes.number,
      }),
    }),
  }).isRequired,
}

export default reduxForm({
  form: 'pairingEdit',
})(PairingEdit)
