import React from 'react'
import { Table } from 'reactstrap'

import PairingListBody from '../containers/PairingListBodyContainer'

const PairingList = () => (
  <Table hover className="mt-3">
    <thead>
      <tr>
        <th>Board</th>
        <th>White</th>
        <th>Rating</th>
        <th>Points</th>
        <th>Black</th>
        <th>Rating</th>
        <th>Points</th>
        <th>Result</th>
        <th>{' '}</th>
      </tr>
    </thead>
    <PairingListBody />
  </Table>
)

export default PairingList
