import React from 'react'
import { Button } from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const TournamentDescriptionHeader = ({
  isEdit,
  toggle,
}) => {
  return (
    <div className={classNames(
      { 'd-none': isEdit },
    )}
    >
      <Button
        color="secondary"
        onClick={() => { toggle(true) }}
      >
        Modify
      </Button>
    </div>
  )
}

TournamentDescriptionHeader.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
}

export default TournamentDescriptionHeader
