import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import auth from '../auth'

const Login = ({ silentLoginError, lockReady, addAlertConnect }) => {
  useEffect(() => {
    const text = 'Please note, LOG IN WITH GOOGLE does not work with embedded browsers such as wechat.'
    addAlertConnect(text, { color: 'info' })

    return () => {
      // needs to hide after destruct, otherwise lock won't display properly when back.
      auth.hide()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    if (lockReady) {
      const options = { rememberLastLogin: !silentLoginError }
      auth.login(options)
    }
  })

  return (
    <div className="mt-3 d-flex flex-row justify-content-center">
      <div id="login-container" className="border rounded" />
    </div>
  )
}

Login.propTypes = {
  silentLoginError: PropTypes.bool.isRequired,
  lockReady: PropTypes.bool.isRequired,
  addAlertConnect: PropTypes.func.isRequired,
}

export default Login
