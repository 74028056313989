import React, { useState, useEffect } from 'react'
import { reduxForm } from 'redux-form'
import {
  Card, CardHeader, CardBody,
} from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import TournamentDescriptionEdit from './TournamentDescriptionEdit'
import TournamentDescriptionHeader from './TournamentDescriptionHeader'
import TournamentDescriptionBody from './TournamentDescriptionBody'
import TournamentDescriptionEditButtons from './TournamentDescriptionEditButtons'
import { Form } from '../../../common'

const TournamentDescription = ({
  handleSubmit,
  submitting,
  tournament,
  userId,
  saved,
  resetSaveTournamentConnect,
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const { createdBy, completed = false } = tournament
  const isMine = userId === createdBy
  const canEdit = isMine && !completed

  useEffect(() => {
    if (saved) {
      setIsEdit(false)
      resetSaveTournamentConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saved])

  const { desc = '' } = tournament
  return (
    <>
      <Form onSubmit={handleSubmit} submitting={submitting}>
        <Card className={classNames({ 'my-3': !isEdit, 'border-bottom-0': isEdit })}>
          <CardHeader className={classNames('d-flex', { 'border-bottom-0': isEdit })}>
            <h4 className="mr-3">More details</h4>
            {canEdit && (
              <TournamentDescriptionHeader
                isEdit={isEdit}
                toggle={setIsEdit}
              />
            )}
            {isEdit && (
              <TournamentDescriptionEditButtons
                toggle={setIsEdit}
                submitting={submitting}
              />
            )}
          </CardHeader>
          {!isEdit && (
            <CardBody>
              <TournamentDescriptionBody desc={desc} />
            </CardBody>
          )}
        </Card>
        {isEdit && canEdit && (
          <TournamentDescriptionEdit />
        )}
      </Form>
    </>
  )
}

TournamentDescription.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    createdBy: PropTypes.string.isRequired,
    completed: PropTypes.bool.isRequired,
    desc: PropTypes.string,
  }).isRequired,
  userId: PropTypes.string,
  saved: PropTypes.bool.isRequired,
  resetSaveTournamentConnect: PropTypes.func.isRequired,
}

TournamentDescription.defaultProps = {
  userId: null,
}

export default reduxForm({
  form: 'tournamentDescriptionEdit',
})(TournamentDescription)
