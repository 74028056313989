import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentPlayerViewBreadcrumbTitle from '../components/TournamentPlayerViewBreadcrumbTitle'
import { LoadingSpinner } from '../../common'

const TournamentPlayerViewBreadcrumbTitleContainer = ({
  loading, loaded, ...rest
}) => {
  if (loading) {
    return <LoadingSpinner />
  }

  return loaded ? <TournamentPlayerViewBreadcrumbTitle {...rest} /> : null
}

TournamentPlayerViewBreadcrumbTitleContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
}

const mapStateToProps = ({
  tournamentPlayer: {
    details: {
      loading,
      loaded,
      data: tournamentPlayer,
    },
  },
}) => ({
  loading,
  loaded,
  tournamentPlayer,
})

export default connect(mapStateToProps)(TournamentPlayerViewBreadcrumbTitleContainer)
