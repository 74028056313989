import React, { useEffect } from 'react'
import { withRouter, generatePath } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import DeletePairingsModal from '../components/DeletePairingsModal'
import { getPairing, deletePairing, resetDeletePairing } from '../../tournament-pairing/actions'
import { getTournament } from '../actions'
import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'

const DeletePairingsModalContainer = ({
  history,
  isOpen,
  toggle,
  accessToken,
  tournament,
  deleted,
  deletePairingConnect,
  resetDeletePairingConnect,
  getTournamentConnect,
  getPairingConnect,
}) => {
  const { id: tournamentId, numRoundsCreated } = tournament
  const confirmDelete = () => {
    return deletePairingConnect(accessToken, tournamentId)
  }

  useEffect(() => {
    if (deleted) {
      resetDeletePairingConnect()
      getTournamentConnect(accessToken, tournamentId)
      getPairingConnect(accessToken, tournamentId)
      history.push(generatePath(ROUTE_TOURNAMENT_VIEW, { id: tournamentId, tabId: 'pairings' }))
    }
  })

  return (
    <DeletePairingsModal
      roundNo={numRoundsCreated}
      isOpen={isOpen}
      toggle={toggle}
      confirmDelete={confirmDelete}
    />
  )
}

DeletePairingsModalContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    numRoundsCreated: PropTypes.number,
  }).isRequired,
  deleted: PropTypes.bool.isRequired,
  deletePairingConnect: PropTypes.func.isRequired,
  resetDeletePairingConnect: PropTypes.func.isRequired,
  getTournamentConnect: PropTypes.func.isRequired,
  getPairingConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: { data: { accessToken } },
  tournament: {
    summary: {
      data: tournament,
    },
  },
  tournamentPairing: { delete: { deleted } },
}) => {
  return {
    accessToken,
    tournament,
    deleted,
  }
}

const mapDispatchToProps = {
  deletePairingConnect: deletePairing,
  resetDeletePairingConnect: resetDeletePairing,
  getPairingConnect: getPairing,
  getTournamentConnect: getTournament,
}

export default connect(
  mapStateToProps, mapDispatchToProps,
)(withRouter(DeletePairingsModalContainer))
