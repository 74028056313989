import { GET_RELEASES } from './types'
import Release from '../../apis/release'

const getReleases = () => async (dispatch) => {
  const release = new Release(null, dispatch)
  const func = async () => release.list()
  return release.d(func, GET_RELEASES)
}

export default getReleases
