import {
  combineReducers, createStore, applyMiddleware, compose,
} from 'redux'
import thunk from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

// Imported reducers
import { releasesReducer, releasesState } from './modules/releases'
import { issuesReducer, issuesState } from './modules/issues'
import {
  authReducer, authState, lockReducer, lockState,
} from './modules/auth'
import { userReducer, userState } from './modules/user'
import { tournamentsReducer, tournamentsState } from './modules/tournaments'
import { tournamentReducer, tournamentState } from './modules/tournament'
import { tournamentPlayerReducer, tournamentPlayerState } from './modules/tournament-player'
import { pairingReducer, pairingState } from './modules/tournament-pairing'
import { standingReducer, standingState } from './modules/tournament-standing'
import { wallChartReducer, wallChartState } from './modules/tournament-wall-chart'
import { breadcrumbReducer, breadcrumbState } from './modules/navbar'
import { successAction, alertsReducer, alertsState } from './modules/common'
import {
  LOGOUT, HTTP_UNAUTHORIZED, SILENT_LOGIN_ERROR, UNKNOWN_ERROR,
} from './modules/auth/types'

const initialState = {
  breadcrumb: breadcrumbState,
  alerts: alertsState,
  releases: releasesState,
  issues: issuesState,
  auth: authState,
  lock: lockState,
  user: userState,
  tournaments: tournamentsState,
  tournament: tournamentState,
  tournamentPlayer: tournamentPlayerState,
  tournamentPairing: pairingState,
  tournamentStanding: standingState,
  tournamentWallChart: wallChartState,
  form: {},
}

const appReducer = combineReducers({
  breadcrumb: breadcrumbReducer,
  alerts: alertsReducer,
  releases: releasesReducer,
  issues: issuesReducer,
  auth: authReducer,
  lock: lockReducer,
  user: userReducer,
  tournaments: tournamentsReducer,
  tournament: tournamentReducer,
  tournamentPlayer: tournamentPlayerReducer,
  tournamentPairing: pairingReducer,
  tournamentStanding: standingReducer,
  tournamentWallChart: wallChartReducer,
  form: formReducer,
})

const rootReducer = (state, action) => {
  const resetStates = [successAction(LOGOUT), HTTP_UNAUTHORIZED, SILENT_LOGIN_ERROR, UNKNOWN_ERROR]
  const newState = resetStates.includes(action.type) ? undefined : state

  return appReducer(newState, action)
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'user'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

// Add devtools in dev
let composeEnhancers = compose
if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_NODE_ENV === 'staging') {
  // eslint-disable-next-line no-console
  console.warn('DEBUG BUILD: Redux tools enabled')

  /* eslint-disable no-underscore-dangle */
  composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose
  /* eslint-enable no-underscore-dangle */
}
const enhancer = composeEnhancers(applyMiddleware(thunk))

export const store = createStore(persistedReducer, initialState, enhancer)
export const persistor = persistStore(store)
