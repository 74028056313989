import React, { useState } from 'react'
import {
  Button, UncontrolledButtonDropdown, DropdownToggle, DropdownItem, DropdownMenu,
} from 'reactstrap'
import { Link, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ROUTE_TOURNAMENT_PLAYER_EDIT } from '../../app/types'
import DeleteConfirmContext from '../../tournament/contexts/DeleteConfirmContext'
import ReenterModal from '../containers/ReenterModalContainer'
import WithdrawModal from '../containers/WithdrawModalContainer'
import WithdrawItem from './tournament-player-list-row/WithdrawItem'
import ReenterItem from './tournament-player-list-row/ReenterItem'

export const getTournamentPlayer = ({ tournament, tournamentPlayer, userId }) => {
  const {
    id,
    firstName,
    lastName,
    withdrawn,
    createdBy: playerCreatedBy,
  } = tournamentPlayer

  const {
    createdBy: tournamentCreatedBy,
    completed: tournamentCompleted = false,
    numRounds,
    numRoundsCreated,
  } = tournament
  const isMyTournament = userId === tournamentCreatedBy
  const isMyPlayer = userId === playerCreatedBy
  const paired = numRoundsCreated > 0
  const isLastRound = paired && numRounds === numRoundsCreated
  return {
    id,
    firstName,
    lastName,
    paired,
    isLastRound,
    withdrawn,
    isMyTournament,
    isMyPlayer,
    tournamentCompleted,
  }
}

export const getShowMenu = ({
  isMyTournament,
  isMyPlayer,
  withdrawn,
  tournamentCompleted,
}) => {
  return (isMyTournament || (isMyPlayer && !withdrawn)) && !tournamentCompleted
}

const TournamentPlayerMenu = ({ tournamentPlayer, color }) => {
  const {
    id, firstName, lastName, paired, isLastRound, isMyTournament, withdrawn,
  } = tournamentPlayer

  const [isOpenReenterModal, setIsOpenReenterModal] = useState(false)
  const [isOpenWithdrawModal, setIsOpenWithdrawModal] = useState(false)

  const showDelete = !paired
  const showWithdraw = paired && !withdrawn
  const disableWithdraw = isLastRound
  const showReenter = withdrawn && isMyTournament
  const disableReenter = isLastRound

  return (
    <>
      <UncontrolledButtonDropdown>
        <Button
          color={color}
          size="md"
          tag={Link}
          to={generatePath(ROUTE_TOURNAMENT_PLAYER_EDIT, { id })}
        >
          Modify
        </Button>
        <DropdownToggle caret color={color} />
        <DropdownMenu right>
          <DeleteConfirmContext.Consumer>
            {({ setIsOpen, setPlayerToDelete }) => {
              return (
                <>
                  {showDelete && (
                    <DropdownItem
                      onClick={() => {
                        setPlayerToDelete({ id, firstName, lastName })
                        setIsOpen(true)
                      }}
                    >
                      Delete
                    </DropdownItem>
                  )}
                  {showWithdraw && (
                    <WithdrawItem
                      tournamentPlayerId={id}
                      disabled={disableWithdraw}
                      onClick={() => {
                        setIsOpenWithdrawModal(true)
                      }}
                    />
                  )}
                  {showReenter && (
                    <ReenterItem
                      tournamentPlayerId={id}
                      disabled={disableReenter}
                      onClick={() => {
                        setIsOpenReenterModal(true)
                      }}
                    />
                  )}
                </>
              )
            }}
          </DeleteConfirmContext.Consumer>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
      <ReenterModal
        isOpen={isOpenReenterModal}
        toggle={() => setIsOpenReenterModal(false)}
        tournamentPlayer={tournamentPlayer}
      />
      <WithdrawModal
        isOpen={isOpenWithdrawModal}
        toggle={() => setIsOpenWithdrawModal(false)}
        tournamentPlayer={tournamentPlayer}
      />
    </>
  )
}

TournamentPlayerMenu.propTypes = {
  tournamentPlayer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    paired: PropTypes.bool.isRequired,
    isLastRound: PropTypes.bool.isRequired,
    isMyTournament: PropTypes.bool.isRequired,
    withdrawn: PropTypes.bool.isRequired,
  }).isRequired,
  color: PropTypes.string,
}

TournamentPlayerMenu.defaultProps = {
  color: 'secondary',
}

export default TournamentPlayerMenu
