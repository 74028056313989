import React from 'react'
import PropTypes from 'prop-types'
import { formatName } from '../../../libs/formatter'
import DeleteModal from '../../common/components/DeleteModal'

const TournamentPlayerDeleteModal = ({
  isOpen, toggle, tournamentPlayer, tournamentName, confirmDelete,
}) => {
  const headerText = 'Delete player?'
  const Body = () => {
    return (
      <>
        Are you sure you want to remove
        {' '}
        <span className="font-weight-less-bold">{formatName(tournamentPlayer)}</span>
        {' '}
        from
        {' '}
        <span className="font-italic">{tournamentName}</span>
        ?
      </>
    )
  }

  return (
    <DeleteModal {...{
      isOpen,
      headerText,
      Body,
      toggle,
      confirmDelete,
    }}
    />
  )
}

TournamentPlayerDeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  tournamentPlayer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
  tournamentName: PropTypes.string.isRequired,
  confirmDelete: PropTypes.func.isRequired,
}

export default TournamentPlayerDeleteModal
