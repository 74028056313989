import React from 'react'
import { reduxForm } from 'redux-form'
import { Container, Button } from 'reactstrap'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import { Form } from '../../common'
import TournamentPlayerEditFields from '../containers/TournamentPlayerEditFieldsContainer'

const TournamentPlayerEdit = (props) => {
  const {
    history,
    handleSubmit,
    submitting,
  } = props
  return (
    <Container className="mt-3">
      <Form onSubmit={handleSubmit} submitting={submitting}>
        <TournamentPlayerEditFields />
        <div className="d-flex flex-row justify-content-center">
          <div className="p-2">
            <Button type="submit" color="primary" className="my-2" disabled={submitting}>
              Save
            </Button>
          </div>
          <div className="p-2">
            <Button
              color="secondary"
              className="my-2"
              onClick={() => {
                history.goBack()
              }}
              disabled={submitting}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </Container>
  )
}

TournamentPlayerEdit.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: 'tournamentPlayerEdit',
})(TournamentPlayerEdit)
