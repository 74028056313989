import React from 'react'
import {
  InputGroup, InputGroupAddon, Input, ButtonGroup,
} from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import NumRoundsInputButton from './NumRoundsInputButton'

const NumRoundsInput = (props) => {
  const { onChange, invalid } = props
  const onClick = (v) => {
    onChange(v)
  }

  return (
    <InputGroup className={classNames('field-wrapper', { 'is-invalid': invalid })}>
      <Input id="numRounds" type="number" {...props} />
      <InputGroupAddon addonType="append">
        <ButtonGroup>
          <NumRoundsInputButton onClick={onClick} value={5} className="no-left-radius" />
          <NumRoundsInputButton onClick={onClick} value={7} />
          <NumRoundsInputButton onClick={onClick} value={9} />
        </ButtonGroup>
      </InputGroupAddon>
    </InputGroup>
  )
}

NumRoundsInput.propTypes = {
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

NumRoundsInput.defaultProps = {
  invalid: null,
}

export default NumRoundsInput
