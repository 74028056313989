import React from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import PropTypes from 'prop-types'

import TournamentEdit from '../components/TournamentEdit'
import { addTournament, resetSaveTournament } from '../actions'
import { LoadingSpinner } from '../../common'

const TournamentCreateContainer = ({
  accessToken,
  saved,
  tournamentId,
  formValues,
  addTournamentConnect,
  resetSaveTournamentConnect,
}) => {
  const onSubmit = (data) => {
    return addTournamentConnect(accessToken, data)
  }

  return accessToken ? (
    <TournamentEdit
      {...{
        onSubmit,
        saved,
        tournamentId,
        formValues,
        resetSaveTournamentConnect,
      }}
    />
  ) : <LoadingSpinner />
}

TournamentCreateContainer.propTypes = {
  accessToken: PropTypes.string,
  saved: PropTypes.bool.isRequired,
  tournamentId: PropTypes.string,
  formValues: PropTypes.shape({
    startOn: PropTypes.string,
    endOn: PropTypes.string,
  }).isRequired,
  addTournamentConnect: PropTypes.func.isRequired,
  resetSaveTournamentConnect: PropTypes.func.isRequired,
}

TournamentCreateContainer.defaultProps = {
  accessToken: null,
  tournamentId: null,
}

const mapStateToProps = (state) => {
  const {
    auth: {
      data: { accessToken },
    },
    tournament: {
      edit: { saved, data: { id: tournamentId = null } },
    },
  } = state

  return {
    accessToken,
    saved,
    tournamentId,
    formValues: getFormValues('tournamentEdit')(state) || {},
  }
}

const mapDispatchToProps = {
  addTournamentConnect: addTournament,
  resetSaveTournamentConnect: resetSaveTournament,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentCreateContainer)
