import editReducer, { initialState as editState } from './editReducer'
import detailsReducer, { initialState as detailsState } from './detailsReducer'
import deleteReducer, { initialState as deleteState } from './deleteReducer'
import withdrawReducer, { initialState as withdrawState } from './withdrawReducer'

export const initialState = {
  details: detailsState,
  edit: editState,
  delete: deleteState,
  withdraw: withdrawState,
}

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
        details: detailsReducer(state, action),
        edit: editReducer(state, action),
        delete: deleteReducer(state, action),
        withdraw: withdrawReducer(state, action),
      }
  }
}
