import React from 'react'
import { Row, Col, Alert } from 'reactstrap'
import PropTypes from 'prop-types'

const AppAlert = ({ text, props }) => {
  return (
    <Row>
      <Col>
        <Alert color="primary" className="text-center" {...props}>
          {text}
        </Alert>
      </Col>
    </Row>
  )
}

AppAlert.propTypes = {
  text: PropTypes.string.isRequired,
  props: PropTypes.shape(Alert.propTypes).isRequired,
}

export default AppAlert
