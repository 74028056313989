import React from 'react'
import {
  Card, CardTitle, CardBody,
} from 'reactstrap'
import PropTypes from 'prop-types'

import Issue from './Issue'

const IssuesColumn = ({
  title,
  numResults,
  docs,
}) => {
  return (
    <Card className="issues-column">
      <CardBody>
        <CardTitle className="h5">
          {`${title} (${numResults})`}
        </CardTitle>
        {docs.map((doc) => {
          const { id } = doc
          return <Issue key={id} doc={doc} />
        })}
      </CardBody>
    </Card>
  )
}

IssuesColumn.propTypes = {
  title: PropTypes.string.isRequired,
  numResults: PropTypes.number.isRequired,
  docs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  })).isRequired,
}

export default IssuesColumn
