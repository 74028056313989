import { GET_ISSUES_TO_DO, GET_ISSUES_IN_PROGRESS, GET_ISSUES_DONE } from './types'
import Issue from '../../apis/issue'

export const listToDo = (accessToken) => async (dispatch) => {
  const issue = new Issue(accessToken, dispatch)
  const func = async () => issue.listToDo()
  return issue.d(func, GET_ISSUES_TO_DO)
}

export const listInProgress = (accessToken) => async (dispatch) => {
  const issue = new Issue(accessToken, dispatch)
  const func = async () => issue.listInProgress()
  return issue.d(func, GET_ISSUES_IN_PROGRESS)
}

export const listDone = (accessToken) => async (dispatch) => {
  const issue = new Issue(accessToken, dispatch)
  const func = async () => issue.listDone()
  return issue.d(func, GET_ISSUES_DONE)
}
