import { Link } from 'react-router-dom'
import {
  ROUTE_RELEASES,
  ROUTE_ISSUES,
  ROUTE_TERMS,
  ROUTE_PRIVACY,
  ROUTE_TOURNAMENTS,
  ROUTE_TOURNAMENTS_VIEW_ALL,
  ROUTE_TOURNAMENTS_VIEW,
  ROUTE_TOURNAMENT_CREATE,
  ROUTE_TOURNAMENT_VIEW,
  ROUTE_TOURNAMENT_EDIT,
  ROUTE_TOURNAMENT_COPY,
  ROUTE_TOURNAMENT_CONFIG_EDIT,
  ROUTE_TOURNAMENT_REGISTER,
  ROUTE_TOURNAMENT_REGISTER_CONFIRM,
  ROUTE_TOURNAMENT_PLAYER_VIEW,
  ROUTE_TOURNAMENT_PLAYER_EDIT,
  ROUTE_TOURNAMENT_PAIRING_CREATE,
  ROUTE_TOURNAMENT_PAIRING_EDIT,
} from '../app/types'

import {
  TournamentViewBreadcrumbTitle,
  TournamentPlayerViewBreadcrumbTitle,
  TournamentPlayerListBreadcrumbTitle,
  TournamentPairingListBreadcrumbTitle,
  TournamentViewButtons,
} from '../tournament'
import { TournamentListButtons } from '../tournaments'
import { TournamentPlayerMenu } from '../tournament-player'
import { PairingEditBreadcrumbTitle } from '../tournament-pairing'

export const breadcrumbItems = {
  [ROUTE_RELEASES]: {
    items: [
      {
        key: 'releases',
        children: 'Releases',
      },
    ],
  },
  [ROUTE_ISSUES]: {
    items: [
      {
        key: 'issues',
        children: 'Todos',
      },
    ],
  },
  [ROUTE_TERMS]: {
    items: [
      {
        key: 'terms',
        children: 'Terms and conditions',
      },
    ],
  },
  [ROUTE_PRIVACY]: {
    items: [
      {
        key: 'privacy',
        children: 'Privacy policy',
      },
    ],
  },
  [ROUTE_TOURNAMENTS_VIEW_ALL]: {
    items: [
      {
        key: 'tournaments',
        children: 'Tournaments',
      },
    ],
    buttons: TournamentListButtons,
  },
  [ROUTE_TOURNAMENTS_VIEW]: {
    items: [
      {
        key: 'tournaments',
        children: 'Tournaments',
      },
    ],
    buttons: TournamentListButtons,
  },
  [ROUTE_TOURNAMENT_CREATE]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'create',
        children: 'Create tournament',
      },
    ],
  },
  [ROUTE_TOURNAMENT_COPY]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'copy',
        children: 'Create tournament',
      },
    ],
  },
  [ROUTE_TOURNAMENT_CONFIG_EDIT]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'view',
        component: TournamentViewBreadcrumbTitle,
      },
      {
        key: 'settings',
        children: 'Settings',
      },
    ],
  },
  [ROUTE_TOURNAMENT_VIEW]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'view',
        component: TournamentViewBreadcrumbTitle,
      },
    ],
    buttons: TournamentViewButtons,
  },
  [ROUTE_TOURNAMENT_EDIT]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'view',
        component: TournamentViewBreadcrumbTitle,
      },
      {
        key: 'edit',
        children: 'Modify',
      },
    ],
  },
  [ROUTE_TOURNAMENT_REGISTER]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'view',
        component: TournamentViewBreadcrumbTitle,
      },
      {
        key: 'register',
        children: 'Register',
      },
    ],
  },
  [ROUTE_TOURNAMENT_REGISTER_CONFIRM]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'view',
        component: TournamentViewBreadcrumbTitle,
      },
      {
        key: 'register',
        children: 'Register',
      },
    ],
  },
  [ROUTE_TOURNAMENT_PLAYER_VIEW]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'tournament',
        component: TournamentPlayerListBreadcrumbTitle,
      },
      {
        key: 'playerView',
        component: TournamentPlayerViewBreadcrumbTitle,
      },
    ],
    buttons: TournamentPlayerMenu,
  },
  [ROUTE_TOURNAMENT_PLAYER_EDIT]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'tournament',
        component: TournamentPlayerListBreadcrumbTitle,
      },
      {
        key: 'playerView',
        component: TournamentPlayerViewBreadcrumbTitle,
      },
      {
        key: 'edit',
        children: 'Modify',
      },
    ],
  },
  [ROUTE_TOURNAMENT_PAIRING_CREATE]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'view',
        component: TournamentPairingListBreadcrumbTitle,
      },
      {
        key: 'pairingCreate',
        component: PairingEditBreadcrumbTitle,
      },
    ],
  },
  [ROUTE_TOURNAMENT_PAIRING_EDIT]: {
    items: [
      {
        to: ROUTE_TOURNAMENTS,
        children: 'Tournaments',
      },
      {
        key: 'view',
        component: TournamentPairingListBreadcrumbTitle,
      },
      {
        key: 'pairingEdit',
        component: PairingEditBreadcrumbTitle,
      },
    ],
  },
}

export const mapItem = (item, index, arr) => {
  const newItem = {
    ...item,
    // auto create key from to
    key: item.key ? item.key : item.to,
    // last item is active
    active: index === arr.length - 1,
  }
  if (item.to) {
    newItem.tag = Link
  }

  return newItem
}
