import React, { useEffect } from 'react'
import { Route, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactRouterPropTypes from 'react-router-prop-types'

import Wrapper from './Wrapper'
import { ROUTE_LOGIN } from '../types'

const PrivateRoute = ({
  history, loggedIn, component, path,
}) => {
  useEffect(() => {
    if (!loggedIn) {
      history.push(ROUTE_LOGIN)
    }
  }, [loggedIn, history])

  const render = (props) => {
    return <Wrapper component={component} {...props} isPublic={false} />
  }
  return loggedIn && <Route path={path} render={render} />
}

PrivateRoute.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  path: PropTypes.string.isRequired,
}

const mapStateToProps = ({ auth: { loggedIn } }) => ({
  loggedIn,
})

export default connect(mapStateToProps)(withRouter(PrivateRoute))
