import React from 'react'
import PropTypes from 'prop-types'

import ActionModal from '../../common/components/ActionModal'

const ReopenTournamentModal = ({
  tournament: { name },
  isOpen,
  toggle,
  confirmUpdate,
}) => {
  const headerText = 'Reopen tournament?'
  const Body = () => {
    return (
      <>
        {'Are you sure you want to reopen tournament '}
        <span className="font-weight-less-bold">{`${name}`}</span>
        ? Once reopened, you will be able to make changes to it again.
      </>
    )
  }

  return (
    <ActionModal {...{
      isOpen,
      headerText,
      Body,
      toggle,
      confirmAction: confirmUpdate,
    }}
    />
  )
}

ReopenTournamentModal.propTypes = {
  tournament: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  confirmUpdate: PropTypes.func.isRequired,
}

export default ReopenTournamentModal
