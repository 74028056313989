import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentPlayerEditFields from '../components/TournamentPlayerEditFields'

const TournamentPlayerEditFieldsContainer = ({
  loaded,
  tournament: {
    settings: {
      tournamentPlayer: {
        requiredFields,
      },
    },
  },
}) => {
  return (
    <>
      {loaded && (
        <TournamentPlayerEditFields requiredFields={requiredFields} />
      )}
    </>
  )
}

TournamentPlayerEditFieldsContainer.propTypes = {
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    settings: PropTypes.shape({
      tournamentPlayer: PropTypes.shape({
        requiredFields: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }).isRequired,
}

const mapStateToProps = (state) => {
  const {
    tournament: {
      summary: { loading, loaded, data: tournament },
    },
  } = state

  return {
    loading,
    loaded,
    tournament,
  }
}

export default connect(mapStateToProps)(TournamentPlayerEditFieldsContainer)
