import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, generatePath } from 'react-router-dom'
import { getFormValues } from 'redux-form'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'

import PairingEdit from '../components/PairingEdit'
import {
  LoadDataByMatch as TournamentData,
  LoadDataByMatch as PairingData,
  LoadDataByMatch as StandingData,
  LoadingSpinner,
  Alert,
  clearAlerts,
} from '../../common'
import { getTournament } from '../../tournament/actions'
import { getPairing, updatePairing, resetEditPairing } from '../actions'
import { getStanding } from '../../tournament-standing/actions'
import { ROUTE_TOURNAMENT_VIEW } from '../../app/types'
import { submit, byeOptions } from '../pairing-edit'
import { formatName } from '../../../libs/formatter'

const PairingEditContainer = ({
  history,
  accessToken,
  loading,
  loaded,
  tournament,
  standing: { docs: players = [] },
  pairingOptions,
  saved,
  error,
  updatePairingConnect,
  resetEditPairingConnect,
  clearAlertsConnect,
  formValues,
}) => {
  const { id: tournamentId } = tournament
  useEffect(() => {
    if (saved) {
      history.push(generatePath(ROUTE_TOURNAMENT_VIEW, { id: tournamentId, tabId: 'pairings' }))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saved])

  useEffect(() => {
    return () => {
      resetEditPairingConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  const onSubmit = (data) => {
    clearAlertsConnect()
    return submit(data, (options) => updatePairingConnect(accessToken, tournamentId, options))
  }

  const getInitialValues = () => {
    if (!loaded) {
      return {}
    }

    const { byes: optionByes = [], forbiddenLists: optionForbiddenLists = [] } = pairingOptions
    const forbiddenLists = {}
    const playerLookup = {}
    const byes = {}

    // remove withdrawn players
    const docs = players.filter((item) => {
      const { withdrawn } = item
      return !withdrawn
    })
    docs.forEach((item) => {
      const { id } = item
      playerLookup[id] = item
      byes[id] = null
      forbiddenLists[id] = []
    })

    const byeOptionLookup = {}
    byeOptions.forEach((item) => {
      const { value } = item
      byeOptionLookup[value] = item
    })
    const {
      settings: {
        tournament: {
          maxHalfPointByes,
        },
      },
    } = tournament

    optionByes.forEach((item) => {
      const { tournamentPlayerId, result } = item
      const { numHalfPointByes } = playerLookup[tournamentPlayerId]
      const byeCode = result !== 'H' || numHalfPointByes < maxHalfPointByes ? result : 'Z'
      byes[tournamentPlayerId] = byeOptionLookup[byeCode]
    })

    optionForbiddenLists.forEach((item) => {
      const { tournamentPlayerId, forbiddenIds } = item
      forbiddenIds.forEach((id) => {
        const label = formatName(playerLookup[id])
        const forbiddenList = forbiddenLists[tournamentPlayerId]
        if (label && forbiddenList) {
          forbiddenList.push({ value: id, label })
        }
      })
    })

    const initialValues = { byes, forbiddenLists }
    return initialValues
  }

  const { message = null } = error
  const warning = 'Please note, if you choose to redo pairings, all reported results of the last round will be lost.'
  return (
    <>
      <TournamentData loadData={getTournament} />
      <PairingData loadData={getPairing} />
      <StandingData loadData={getStanding} args={[{ forPairing: true }]} />
      <Alert message={warning} color="warning" />
      <Alert message={message} color="danger" />
      {loading && <LoadingSpinner />}
      {loaded && (
        <PairingEdit
          history={history}
          onSubmit={onSubmit}
          formValues={{ ...formValues }}
          initialValues={getInitialValues()}
          docs={players}
          tournament={tournament}
        />
      )}
    </>
  )
}

PairingEditContainer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  accessToken: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    id: PropTypes.string,
    settings: PropTypes.shape({
      tournament: PropTypes.shape({
        maxHalfPointByes: PropTypes.number,
      }),
    }),
  }).isRequired,
  standing: PropTypes.shape({
    docs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  pairingOptions: PropTypes.shape({
    byes: PropTypes.arrayOf(PropTypes.shape({
      tournamentPlayerId: PropTypes.string.isRequired,
      result: PropTypes.string.isRequired,
    })),
    forbiddenLists: PropTypes.arrayOf(PropTypes.shape({
      tournamentPlayerId: PropTypes.string.isRequired,
      forbiddenIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    })),
  }),
  saved: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  updatePairingConnect: PropTypes.func.isRequired,
  resetEditPairingConnect: PropTypes.func.isRequired,
  clearAlertsConnect: PropTypes.func.isRequired,
}

PairingEditContainer.defaultProps = {
  pairingOptions: {},
}

const mapStateToProps = (state) => {
  const {
    auth: {
      data: { accessToken },
    },
    tournament: {
      summary: {
        loading: loadingTournament,
        loaded: tournamentLoaded,
        data: tournament,
      },
    },
    tournamentPairing: {
      details: {
        loading: loadingPairings,
        loaded: pairingsLoaded,
        data: { options: pairingOptions },
      },
      edit: { saved, error },
    },
    tournamentStanding: {
      loading: loadingStanding,
      loaded: standingLoaded,
      data: standing,
    },
  } = state

  return {
    accessToken,
    loading: loadingTournament || loadingPairings || loadingStanding,
    loaded: tournamentLoaded && pairingsLoaded && standingLoaded,
    tournament,
    standing,
    pairingOptions,
    saved,
    error,
    formValues: getFormValues('pairingEdit')(state) || {},
  }
}

const mapDispatchToProps = {
  updatePairingConnect: updatePairing,
  resetEditPairingConnect: resetEditPairing,
  clearAlertsConnect: clearAlerts,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(PairingEditContainer))
