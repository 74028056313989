import React from 'react'

const TournamentListHeader = () => {
  return (
    <thead>
      <tr>
        <th>Name</th>
        <th>Start on</th>
        <th>End on</th>
        <th>Rds</th>
        <th>Created at</th>
        <th>Created by</th>
        <th>Status</th>
      </tr>
    </thead>
  )
}

export default TournamentListHeader
