import React from 'react'
import {
  Button, DropdownItem,
} from 'reactstrap'
import { Link, generatePath } from 'react-router-dom'
import PropTypes from 'prop-types'

import { ROUTE_TOURNAMENT_PAIRING_EDIT } from '../../../app/types'

const RedoPairingsButton = ({
  tournamentId,
  roundNo,
  isButton,
}) => {
  const to = generatePath(ROUTE_TOURNAMENT_PAIRING_EDIT, { id: tournamentId })
  const text = `Redo round ${roundNo} pairings`

  return isButton ? (
    <Button
      className="no-right-radius"
      color="primary"
      size="md"
      tag={Link}
      to={to}
    >
      <span className="d-inline-flex">
        {text}
      </span>
    </Button>
  ) : (
    <DropdownItem
      tag={Link}
      to={to}
    >
      {text}
    </DropdownItem>
  )
}

RedoPairingsButton.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  roundNo: PropTypes.number.isRequired,
  isButton: PropTypes.bool.isRequired,
}

export default RedoPairingsButton
