import React from 'react'
import shortid from 'shortid'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { formatName, formatPoints } from '../../../libs/formatter'

const WallChartRow = ({
  doc: {
    id, playerNo, firstName, lastName, rating = null, rounds, byes,
  },
  numRounds,
}) => {
  const restRoundNos = []
  for (let i = rounds.length + 1; i <= numRounds; i++) {
    restRoundNos.push(i)
  }
  return (
    <tr key={id}>
      <td>{playerNo}</td>
      <td>{formatName({ firstName, lastName })}</td>
      <td>{rating}</td>
      {rounds.map(({
        id: roundId, opponentNo, color, result, totalPoints,
      }) => {
        const pairing = _.isNil(opponentNo) ? result : `${opponentNo} ${color}`
        const subTotal = totalPoints === null ? '' : formatPoints(totalPoints)
        return <td key={roundId}>{`${pairing} ${subTotal}`}</td>
      })}
      {restRoundNos.map((roundNo) => {
        return (
          <td key={shortid.generate()}>
            {byes.includes(roundNo) ? 'bye' : ''}
          </td>
        )
      })}
    </tr>
  )
}

WallChartRow.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.string.isRequired,
    playerNo: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    rating: PropTypes.number,
    totalPoints: PropTypes.number.isRequired,
    rounds: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    ).isRequired,
    byes: PropTypes.arrayOf(PropTypes.number).isRequired,
  }).isRequired,
  numRounds: PropTypes.number.isRequired,
}

export default WallChartRow
