/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Table } from 'reactstrap'
import PropTypes from 'prop-types'

import StandingRow from './StandingRow'
import { NoResults } from '../../common'

const StandingList = ({ roundNo, docs }) => {
  return (
    <Table hover className="mt-3">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Rating</th>
          {Array.from(Array(roundNo)).map((value, index) => {
            return <th key={index}>{`Rd ${index + 1}`}</th>
          })}
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {docs.length ? docs.map((doc) => {
          const { id } = doc
          return <StandingRow key={id} doc={doc} />
        }) : (
          <NoResults
            colSpan={5}
            text="Standings will be updated once round 1 results are reported."
          />
        )}
      </tbody>
    </Table>
  )
}

StandingList.propTypes = {
  roundNo: PropTypes.number.isRequired,
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default StandingList
