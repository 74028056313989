import {
  failureAction, successAction, startAction,
} from '../../common'
import {
  GET_TOURNAMENT_PLAYERS, WITHDRAW_TOURNAMENT_PLAYER, REENTER_TOURNAMENT_PLAYER,
} from '../../tournament-player/types'

export const initialState = {
  loading: false,
  loaded: false,
  error: {},
  data: [],
}

let docs
let id
let withdrawn

const reducer = ({ players: state }, action) => {
  switch (action.type) {
    case startAction(GET_TOURNAMENT_PLAYERS):
      return {
        ...initialState,
        loading: true,
      }
    case successAction(GET_TOURNAMENT_PLAYERS):
      return {
        ...state,
        loading: false,
        loaded: true,
        error: {},
        data: action.payload,
      }
    case failureAction(GET_TOURNAMENT_PLAYERS):
      return {
        ...initialState,
        error: action.payload,
      }
    case successAction(WITHDRAW_TOURNAMENT_PLAYER):
    case successAction(REENTER_TOURNAMENT_PLAYER):
      ({ data: docs } = state);
      ({ id, withdrawn } = action.payload)
      return {
        ...state,
        data: docs.map((item) => {
          const { id: tournamentPlayerId } = item
          return tournamentPlayerId === id ? {
            ...item,
            withdrawn,
          } : item
        }),
      }

    default:
      return state
  }
}

export default reducer
