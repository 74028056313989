import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import PairingEditBreadcrumbTitle from '../components/PairingEditBreadcrumbTitle'
import { LoadingSpinner } from '../../common'

const PairingEditBreadcrumbTitleContainer = ({
  loading, loaded, ...rest
}) => {
  if (loading) {
    return <LoadingSpinner />
  }

  return loaded && <PairingEditBreadcrumbTitle {...rest} />
}

PairingEditBreadcrumbTitleContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
}

const mapStateToProps = ({
  breadcrumb: {
    match: {
      loading: loadingMatch,
      loaded: matchLoaded,
      data: match,
    },
  },
  tournament: {
    summary: {
      loading,
      loaded,
      data: tournament,
    },
  },
}) => ({
  loading: loading || loadingMatch,
  loaded: loaded && matchLoaded,
  tournament,
  match,
})

export default connect(mapStateToProps)(PairingEditBreadcrumbTitleContainer)
