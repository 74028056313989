import React from 'react'
import PropTypes from 'prop-types'
import { BreadcrumbItem } from 'reactstrap'
import { formatName } from '../../../libs/formatter'

const Title = ({ active, tournamentPlayer }) => {
  return (
    <BreadcrumbItem active={active}>
      {formatName(tournamentPlayer)}
    </BreadcrumbItem>
  )
}

Title.propTypes = {
  active: PropTypes.bool.isRequired,
  tournamentPlayer: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
}

export default Title
