import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { addAlert } from '../actions'

const AlertContainer = ({
  message,
  color,
  addAlertConnect,
}) => {
  useEffect(() => {
    if (message) {
      addAlertConnect(message, { color })
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  })

  return <></>
}

AlertContainer.propTypes = {
  message: PropTypes.string,
  color: PropTypes.string,
  addAlertConnect: PropTypes.func.isRequired,
}

AlertContainer.defaultProps = {
  message: null,
  color: 'info',
}

const mapDispatchToProps = {
  addAlertConnect: addAlert,
}

export default connect(null, mapDispatchToProps)(AlertContainer)
