import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { resetAuth } from '../actions'
import { addAlert } from '../../common'

const SessionContainer = ({
  unauthorized,
  silentLoginError,
  resetAuthConnect,
  addAlertConnect,
}) => {
  useEffect(() => {
    if (unauthorized || silentLoginError) {
      const text = 'You have been logged out because your session has expired. Please log in again.'
      addAlertConnect(text, { color: 'warning' })
      resetAuthConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [unauthorized, silentLoginError])

  return <></>
}

SessionContainer.propTypes = {
  unauthorized: PropTypes.bool.isRequired,
  silentLoginError: PropTypes.bool.isRequired,
  resetAuthConnect: PropTypes.func.isRequired,
  addAlertConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: { unauthorized, silentLoginError },
}) => ({
  unauthorized,
  silentLoginError,
})

const mapDispatchToProps = {
  resetAuthConnect: resetAuth,
  addAlertConnect: addAlert,
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionContainer)
