export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const RESET_LOCK = 'RESET_LOCK'
export const LOCK_READY = 'LOCK_READY'
export const HTTP_UNAUTHORIZED = 'HTTP_UNAUTHORIZED'
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR'
export const DONE_UNKNOWN_ERROR = 'DONE_UNKNOWN_ERROR'
export const SILENT_LOGIN_ERROR = 'SILENT_LOGIN_ERROR'
export const RESET_AUTH = 'RESET_AUTH'
export const GET_AUTH = 'GET_AUTH'
