import React from 'react'
import { Redirect, generatePath } from 'react-router-dom'

import { ROUTE_TOURNAMENTS_VIEW } from '../../app/types'

const Tournaments = () => {
  return <Redirect to={generatePath(ROUTE_TOURNAMENTS_VIEW, { tabId: 'all' })} />
}

export default Tournaments
