import React, { useState } from 'react'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'
import PropTypes from 'prop-types'

import SavingSpinner from './SavingSpinner'

const DeleteModal = ({
  isOpen,
  headerText,
  Body,
  toggle,
  confirmDelete,
}) => {
  const [deleting, setDeleting] = useState(false)
  const onClick = async () => {
    setDeleting(true)
    await confirmDelete()
    setDeleting(false)
    toggle()
  }

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
        <ModalBody>
          <Body />
        </ModalBody>
        <ModalFooter>
          <div className="p-2">
            <Button color="danger" onClick={onClick}>Delete</Button>
          </div>
          <div className="p-2">
            <Button color="secondary" onClick={toggle}>Cancel</Button>
          </div>
        </ModalFooter>
      </Modal>
      { deleting && <SavingSpinner /> }
    </>
  )
}

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  Body: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
}

export default DeleteModal
