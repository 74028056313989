import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

import TournamentPlayerMenu, { getTournamentPlayer, getShowMenu } from './TournamentPlayerMenu'
import { formatDateTime } from '../../../libs/formatter'
import RowNo from './RowNo'
import TournamentPlayerModal from './tournament-player-list-row/TournamentPlayerModal'

const TournamentPlayerListRow = ({
  playerNo,
  doc,
  userId,
  tournament,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const {
    rating = null,
    createdAt,
    creator: { name: creatorName },
  } = doc

  const {
    id,
    firstName,
    lastName,
    paired,
    isLastRound,
    withdrawn,
    isMyTournament,
    isMyPlayer,
    tournamentCompleted,
  } = getTournamentPlayer({ tournament, tournamentPlayer: doc, userId })

  const {
    contact,
    byes,
    forbiddenPlayers,
  } = doc
  const isMine = isMyTournament || isMyPlayer
  const showMenu = getShowMenu({
    isMyTournament, isMyPlayer, withdrawn, tournamentCompleted,
  })

  return (
    <tr>
      <RowNo rowNo={playerNo} withdrawn={withdrawn} />
      <td>
        <Button
          color="link"
          onClick={() => { setIsOpen(true) }}
          className="p-0 border-0 align-top"
        >
          {firstName}
        </Button>
        <TournamentPlayerModal
          tournamentPlayer={{
            id, firstName, lastName, contact, rating, byes, forbiddenPlayers,
          }}
          isMine={isMine}
          isOpen={isOpen}
          toggle={() => { setIsOpen(false) }}
        />
      </td>
      <td>{lastName}</td>
      <td>{rating}</td>
      <td>{formatDateTime(createdAt)}</td>
      <td>{creatorName}</td>
      <td className="w-5 px-0">
        {showMenu && (
          <TournamentPlayerMenu
            tournamentPlayer={{
              id,
              firstName,
              lastName,
              paired,
              isLastRound,
              withdrawn,
              isMyTournament,
            }}
          />
        )}
      </td>
    </tr>
  )
}

TournamentPlayerListRow.propTypes = {
  playerNo: PropTypes.number.isRequired,
  doc: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    rating: PropTypes.number,
    withdrawn: PropTypes.bool,
    createdAt: PropTypes.string.isRequired,
    createdBy: PropTypes.string.isRequired,
    creator: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    contact: PropTypes.string,
    byes: PropTypes.arrayOf(PropTypes.number),
    forbiddenPlayers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })),
  }).isRequired,
  userId: PropTypes.string,
  tournament: PropTypes.shape({
    createdBy: PropTypes.string.isRequired,
    completed: PropTypes.bool,
    numRounds: PropTypes.number.isRequired,
    numRoundsCreated: PropTypes.number.isRequired,
  }).isRequired,
}

TournamentPlayerListRow.defaultProps = {
  userId: null,
}

export default TournamentPlayerListRow
