import _ from 'lodash'
import { format, parseISO } from 'date-fns'

export const DATE_FORMAT = 'd MMM yyyy'
export const DATE_TIME_FORMAT = 'd MMM yyyy h:mm a'

export const formatDate = (value) => {
  if (!value) {
    return value
  }
  const date = parseISO(value)
  return format(date, DATE_FORMAT)
}

export const formatDateTime = (value) => {
  if (!value) {
    return value
  }
  const date = parseISO(value)
  return format(date, DATE_TIME_FORMAT)
}

export const formatName = ({ firstName, lastName }) => {
  // disable capitalize temporarily until network ID is properly implemented
  // return `${_.capitalize(firstName)} ${_.capitalize(lastName)}`
  return `${firstName} ${lastName}`
}

export const formatPoints = (points) => {
  return (_.isNumber(points) || _.isString(points)) ? points.toFixed(1) : '0.0'
}
