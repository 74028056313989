import { failureAction, successAction } from '../common'
import { GET_USER_PROFILE, ADD_OR_GET_USER } from './types'
import { User } from '../../apis'

export const getUserProfile = (profile) => ({
  type: successAction(GET_USER_PROFILE),
  payload: profile,
})

export const getUserProfileErr = (err) => ({
  type: failureAction(GET_USER_PROFILE),
  payload: err,
})

export const addOrGetUser = (accessToken, data) => async (dispatch) => {
  const user = new User(accessToken, dispatch)
  const func = async () => user.addOrGetUser(data)
  return user.d(func, ADD_OR_GET_USER)
}
