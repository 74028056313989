import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { formatName, formatPoints } from '../../../libs/formatter'
import Bye from './Bye'
import ByeInfo from './ByeInfo'
import ForbiddenListInfo from './ForbiddenListInfo'
import ForbiddenList from './ForbiddenList'
import ReduxFormContext from '../../common/contexts/ReduxFormContext'

const PairingPlayerRow = ({
  doc: {
    id,
    firstName,
    lastName,
    rating,
    totalPoints,
    bye = false,
    forbiddenIds,
    combinedForbiddenIds,
    numHalfPointByes,
  },
  playerNo,
}) => {
  const name = formatName({ firstName, lastName })
  return (
    <tr key={id}>
      <td>
        {playerNo}
      </td>
      <td>
        {name}
      </td>
      <td>{rating}</td>
      <td>{formatPoints(totalPoints)}</td>
      <td className="pt-0">
        <div className="d-flex flex-row">
          <div className="pt-2 mt-1 flex-grow-1">
            <ReduxFormContext.Consumer>
              {({ maxHalfPointByes }) => {
                const canTakeHalfPointBye = numHalfPointByes < maxHalfPointByes
                return <Bye id={id} canTakeHalfPointBye={canTakeHalfPointBye} />
              }}
            </ReduxFormContext.Consumer>
          </div>
          <ReduxFormContext.Consumer>
            {({ hasBye }) => {
              return (
                <div className={classNames({ 'ml-2': hasBye, 'mt-1': true, 'w-5': hasBye })}>
                  {bye && <ByeInfo id={id} name={name} />}
                </div>
              )
            }}
          </ReduxFormContext.Consumer>
        </div>
      </td>
      <td className="pt-0">
        <div className="d-flex flex-row">
          <div className="pt-2 mt-1 flex-grow-1">
            <ForbiddenList id={id} name={name} />
          </div>
          <ReduxFormContext.Consumer>
            {({ hasForbiddenList }) => {
              return (
                <div className={classNames({ 'ml-2': hasForbiddenList, 'mt-1': true, 'w-5': hasForbiddenList })}>
                  {combinedForbiddenIds.length > 0 && (
                  <ForbiddenListInfo
                    id={id}
                    name={name}
                    forbiddenIds={forbiddenIds}
                    combinedForbiddenIds={combinedForbiddenIds}
                  />
                  )}
                </div>
              )
            }}
          </ReduxFormContext.Consumer>
        </div>
      </td>
    </tr>
  )
}

PairingPlayerRow.propTypes = {
  doc: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    rating: PropTypes.number,
    totalPoints: PropTypes.number.isRequired,
    bye: PropTypes.bool,
    numHalfPointByes: PropTypes.number,
    forbiddenIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    combinedForbiddenIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  playerNo: PropTypes.number.isRequired,
}

export default PairingPlayerRow
