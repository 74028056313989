import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import TournamentPlayerDeleteModal from '../components/TournamentPlayerDeleteModal'
import { deleteTournamentPlayer, resetDeleteTournamentPlayer } from '../actions'

const TournamentPlayerDeleteModalContainer = ({
  tournamentPlayer,
  isOpen,
  toggle,
  accessToken,
  loadedTournament,
  tournament: { name: tournamentName },
  tournamentPlayerDeleted,
  deleteTournamentPlayerConnect,
  resetDeleteTournamentPlayerConnect,
}) => {
  useEffect(() => {
    if (tournamentPlayerDeleted) {
      toggle()
      resetDeleteTournamentPlayerConnect()
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [tournamentPlayerDeleted])

  const { id: tournamentPlayerId } = tournamentPlayer
  const confirmDelete = () => {
    return deleteTournamentPlayerConnect(accessToken, tournamentPlayerId)
  }

  const ready = loadedTournament && accessToken && tournamentPlayerId
  return ready ? (
    <TournamentPlayerDeleteModal
      isOpen={isOpen}
      toggle={toggle}
      tournamentName={tournamentName}
      tournamentPlayer={tournamentPlayer}
      confirmDelete={confirmDelete}
    />
  ) : <div />
}

TournamentPlayerDeleteModalContainer.propTypes = {
  tournamentPlayer: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  accessToken: PropTypes.string.isRequired,
  loadedTournament: PropTypes.bool.isRequired,
  tournament: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  tournamentPlayerDeleted: PropTypes.bool.isRequired,
  deleteTournamentPlayerConnect: PropTypes.func.isRequired,
  resetDeleteTournamentPlayerConnect: PropTypes.func.isRequired,
}

const mapStateToProps = ({
  auth: {
    data: { accessToken },
  },
  tournament: {
    summary: { loaded: loadedTournament, data: tournament },
  },
  tournamentPlayer: {
    delete: { deleted: tournamentPlayerDeleted },
  },
}) => ({
  accessToken,
  loadedTournament,
  tournament,
  tournamentPlayerDeleted,
})

const mapDispatchToProps = {
  deleteTournamentPlayerConnect: deleteTournamentPlayer,
  resetDeleteTournamentPlayerConnect: resetDeleteTournamentPlayer,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TournamentPlayerDeleteModalContainer)
