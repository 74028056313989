import React from 'react'
import { Row, Col } from 'reactstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const defaultLabelClasses = {
  'd-flex': true, 'justify-content-end': true, 'pr-1': true, 'font-weight-less-bold': true,
}
const defaultTextClasses = {
  'd-flex': true, 'justify-content-start': true, 'pl-1': true,
}

const RowComponent = ({ children, flex }) => {
  return flex ? (
    <div className="d-flex flex-row">
      {children}
    </div>
  ) : (
    <Row>
      {children}
    </Row>
  )
}

RowComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  flex: PropTypes.bool.isRequired,
}

const ColComponent = ({
  children, flex, cssClasses,
}) => {
  return flex ? (
    <div className={cssClasses}>
      {children}
    </div>
  ) : (
    <Col className={cssClasses}>
      {children}
    </Col>
  )
}

ColComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.func,
  ]),
  flex: PropTypes.bool.isRequired,
  cssClasses: PropTypes.string.isRequired,
}

ColComponent.defaultProps = {
  children: null,
}

const SimpleRow = ({
  label, labelClasses, text, textClasses, flex,
}) => {
  return (
    <RowComponent flex={flex}>
      <ColComponent flex={flex} cssClasses={classNames({ ...defaultLabelClasses, ...labelClasses })}>
        {`${label}:`}
      </ColComponent>
      <ColComponent flex={flex} cssClasses={classNames({ ...defaultTextClasses, ...textClasses })}>
        {text}
      </ColComponent>
    </RowComponent>
  )
}

SimpleRow.propTypes = {
  label: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.func,
  ]),
  labelClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  flex: PropTypes.bool,
}

SimpleRow.defaultProps = {
  label: null,
  text: null,
  labelClasses: {},
  textClasses: {},
  flex: false,
}

export default SimpleRow
