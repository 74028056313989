import React from 'react'
import PropTypes from 'prop-types'

import TournamentPlayerListRow from './TournamentPlayerListRow'
import { NoResults } from '../../common'
import DeleteConfirmContext from '../../tournament/contexts/DeleteConfirmContext'
import { getForbiddenPlayers } from './TournamentPlayer'

const TournamentPlayerListBody = ({
  docs,
  tournament,
}) => {
  return (
    <DeleteConfirmContext.Consumer>
      {({ userId }) => {
        return docs.length ? docs.map((doc, index) => {
          const { id, forbiddenIds } = doc
          return (
            <TournamentPlayerListRow
              key={id}
              doc={{ ...doc, forbiddenPlayers: getForbiddenPlayers(docs, forbiddenIds) }}
              userId={userId}
              playerNo={index + 1}
              tournament={tournament}
            />
          )
        }) : <NoResults colSpan={6} />
      }}
    </DeleteConfirmContext.Consumer>
  )
}

TournamentPlayerListBody.propTypes = {
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      rating: PropTypes.number,
      createdAt: PropTypes.string.isRequired,
      createdBy: PropTypes.string.isRequired,
    }),
  ).isRequired,
  tournament: PropTypes.shape({
    createdBy: PropTypes.string.isRequired,
    completed: PropTypes.bool,
  }).isRequired,
}

export default TournamentPlayerListBody
