import React from 'react'
import { Button } from 'reactstrap'
import PropTypes from 'prop-types'

const ReopenTournamentButton = ({ onClick }) => {
  return (
    <Button
      className="no-right-radius"
      color="primary"
      size="md"
      onClick={onClick}
    >
      Reopen tournament
    </Button>
  )
}

ReopenTournamentButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default ReopenTournamentButton
