import React from 'react'
import {
  DropdownItem, UncontrolledTooltip,
} from 'reactstrap'
import { Link, generatePath } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { ROUTE_TOURNAMENT_REGISTER } from '../../../app/types'
import { Info } from '../../../common'

const RegisterPlayerItem = ({
  tournamentId,
  pairingCompleted: disabled,
}) => {
  return (
    <div id="registerPlayerItem" className={classNames('d-flex', { 'cursor-help': disabled })}>
      <div className={classNames({ 'flex-grow-1': !disabled })}>
        <DropdownItem
          className={classNames({ 'pr-1': disabled })}
          tag={Link}
          to={generatePath(ROUTE_TOURNAMENT_REGISTER, { id: tournamentId })}
          disabled={disabled}
        >
          Register player
        </DropdownItem>
      </div>
      {disabled && (
        <div className="flex-grow-1 pr-4">
          <Info className="text-info" />
          <UncontrolledTooltip placement="right" target="registerPlayerItem">
            Registration is closed once pairings are created for the last round.
          </UncontrolledTooltip>
        </div>
      )}
    </div>
  )
}

RegisterPlayerItem.propTypes = {
  tournamentId: PropTypes.string.isRequired,
  pairingCompleted: PropTypes.bool.isRequired,
}

export default RegisterPlayerItem
