import React, { useState } from 'react'
import {
  Container, Nav, TabContent, TabPane,
} from 'reactstrap'
import { withRouter, generatePath } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types'
import PropTypes from 'prop-types'

import { NavTab } from '../../common'

import TournamentList from '../containers/TournamentListContainer'
import { ROUTE_TOURNAMENTS_VIEW } from '../../app/types'
import Paginator from '../containers/PaginatorContainer'

const Tournaments = ({
  match: {
    params: { tabId = 'all' },
  },
  loggedIn,
  page,
}) => {
  const [activePage, setActivePage] = useState(page)

  return (
    <Container>
      <Nav tabs className="mb-4">
        <NavTab
          currentTab="all"
          activeTab={tabId}
          text="All tournaments"
          to={generatePath(ROUTE_TOURNAMENTS_VIEW, { tabId: 'all' })}
        />
        <NavTab
          currentTab="iCreated"
          activeTab={tabId}
          text="I created"
          to={generatePath(ROUTE_TOURNAMENTS_VIEW, { tabId: 'iCreated' })}
          disabled={!loggedIn}
        />
        <NavTab
          currentTab="iRegistered"
          activeTab={tabId}
          text="I registered"
          to={generatePath(ROUTE_TOURNAMENTS_VIEW, { tabId: 'iRegistered' })}
          disabled={!loggedIn}
        />
        <div className="d-flex justify-content-end flex-grow-1">
          <div>
            <Paginator activePage={activePage} setActivePage={setActivePage} />
          </div>
        </div>
      </Nav>
      <TabContent activeTab={tabId}>
        <TabPane tabId="all">
          {tabId === 'all' && <TournamentList tabId="all" />}
        </TabPane>
        <TabPane tabId="iCreated">
          {tabId === 'iCreated' && <TournamentList tabId="iCreated" />}
        </TabPane>
        <TabPane tabId="iRegistered">
          {tabId === 'iRegistered' && <TournamentList tabId="iRegistered" />}
        </TabPane>
      </TabContent>
      <hr />
      <div className="d-flex justify-content-end flex-grow-1">
        <div>
          <Paginator activePage={activePage} setActivePage={setActivePage} />
        </div>
      </div>
    </Container>
  )
}

Tournaments.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
}

export default withRouter(Tournaments)
